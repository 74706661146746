import React, { useState, useEffect } from 'react';
import {
  CContainer,
  CRow,
  CCol,
  CButton,
  CForm,
  CFormSelect,
  CFormLabel,
  CCardBody,
  CImage,
  CSpinner
} from '@coreui/react';
import { postApiWithHeader, GetApiwithHeader } from 'src/Api/Api';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { leftArrow, rightArrow } from 'src/components/Images/Images'

const AddCode = (props) => {
  AddCode.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.object,
    clearFormData: PropTypes.bool,
  };

  const { editValue } = props;
  const [targetItems, setTargetItems] = useState([]);
  const [selectedSourceItems, setSelectedSourceItems] = useState([]);
  const [selectedTargetItems, setSelectedTargetItems] = useState([]);
  const [sourceItems, setSourceItems] = useState([]);
  const [errors, setErrors] = useState({});
  const [industry, setIndustry] = useState([]);
  const accessToken = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [formData, setFormData] = useState({
    industry_name: '',
    codeId: [],
  });

  const fetchData = async () => {
    const apiUrl = '/api/industry';
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      setIndustry(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const codeName = async () => {
    const apiUrl = `api/code${editValue ? `?industryId=${editValue.id}` : ''}`;
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      const responseData = res?.data?.data;
      if (Array.isArray(responseData)) {
        setSourceItems(responseData);
      } else {
        console.error('Invalid response format for codeName:', responseData);
      }
    } catch (error) {
      console.error('Error fetching codeName:', error);
    }
  };

  useEffect(() => {
    if (props.clearFormData) {
      resetForm();
      props.handleClose();
    }
  }, [props.clearFormData])


  useEffect(() => {
    fetchData();
    codeName();
    if (editValue) {
      setFormData({
        ...formData,
        industry_name: editValue.industry,
      });
      setTargetItems(editValue?.codes || []); // Ensure targetItems is an array
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editValue]);

  const handleCreateIndustry = async (e) => {
    e.preventDefault();
    const apiUrl = `api/CodingTemplate/create/`;
    const postData = {
      industryName: formData.industry_name,
      codes: targetItems?.map((code) => code.id),
    };

    const validationErrors = {};
    if (!formData.industry_name.trim()) {
      validationErrors.industry_name = 'Industry name is required'
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsLoading(true);
    try {
      const response = await postApiWithHeader(apiUrl, postData, headers);
       console.log('response',response)
      if (response.data && response.data.message == "Success") {
        // toast.success("Code Created Successfully");
        setIsLoading(false)
        resetForm()
        props.apiCall();
        props.handleClose();
        // setFormData({
        //   industry_name: '',
        //   codeId: [],
        // });
        toast.success(`${editValue ? 'Updated' : 'Added'} successfully`,{ autoClose: 1000 });
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error in POST request:', error);
    }finally {
      setIsLoading(false);
    }
  };

  const handleSourceCheckboxChange = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedSourceItems([...selectedSourceItems, item]);
    } else {
      setSelectedSourceItems(selectedSourceItems.filter((selected) => selected.id !== item.id));
    }
  };

  const handleTargetCheckboxChange = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedTargetItems([...selectedTargetItems, item]);
    } else {
      setSelectedTargetItems(selectedTargetItems.filter((selected) => selected.id !== item.id));
    }
  };

  const handleSelectAllSource = (event) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedSourceItems([...sourceItems]);

    } else {
      setSelectedSourceItems([]);
    }
  };

  const handleSelectAllTarget = (event) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedTargetItems([...targetItems]);
    } else {
      setSelectedTargetItems([]);
    }
  };

  const moveItemsToTarget = (event) => {
    event.preventDefault();
    setTargetItems([...targetItems, ...selectedSourceItems]);
    setSourceItems(sourceItems.filter((item) => !selectedSourceItems.includes(item)));
    setSelectedSourceItems([]);
  };

  const moveItemsToSource = (event) => {
    event.preventDefault();
    setSourceItems([...sourceItems, ...selectedTargetItems]);
    setTargetItems(targetItems.filter((item) => !selectedTargetItems.includes(item)));
    setSelectedTargetItems([]);
  };

  const resetForm = () => {
    setFormData({
      industry_name: editValue ? editValue.industry : '',
      codeId: editValue ? editValue?.codes : [],
    })
    setTargetItems(editValue ? editValue?.codes : []);
    setSelectedSourceItems([]);
    setSelectedTargetItems([]);
    setErrors({});
  }

  // console.log('targetItems', targetItems)

  return (
    <>
      <CCardBody className="">
        <CContainer>
          <CForm>
            {/* Industry Select */}
            <CRow className="mb-4">
              <CCol>
                <CFormSelect
                  label={
                    <>
                        Industry Name <span className="text-danger">*</span>
                    </>
                }
                  placeholder="Select Industry"
                  value={formData.industry_name}
                  onChange={(e) => {
                    // Clear the error when a selection is made
                    setErrors({ ...errors, industry_name: '' });
                    setFormData({ ...formData, industry_name: e.target.value });
                  }}

                  className={errors.industry_name ? 'is-invalid' : ''}
                >
                  <option value="">Select Industry name</option>
                  {industry?.map((role) => (
                    <option key={role.id} value={role.title}>
                      {role.title}
                    </option>
                  ))}
                </CFormSelect>
                {errors.industry_name && <div className="invalid-feedback">{errors.industry_name}</div>}
              </CCol>
            </CRow>

            {/* Code Lists */}
            <CRow>
              {/* Source Code List */}
              <CCol md={6}>
                <div className='leftOuterBox'>

                  <h5>List of codes</h5>
                  <div className='checkBoxHeader'>
                    <input
                      type="checkbox"
                      checked={selectedSourceItems?.length === sourceItems?.length}
                      onChange={handleSelectAllSource}
                    />
                    <label>Select All</label>
                  </div>
                  <div className='leftInputBox'>
                    {sourceItems && sourceItems?.map((item) => (
                      <div key={item?.id} className='checkBoxRow'>
                        <input
                          type="checkbox"
                          checked={selectedSourceItems?.some((selected) => selected?.id === item?.id)}
                          onChange={(event) => handleSourceCheckboxChange(event, item)}
                        />
                        {item?.codeName}
                      </div>
                    ))}

                  </div>
                  <div className='arrowMoveRgiht'>

                    <button onClick={moveItemsToTarget}>
                      <CImage src={rightArrow}

                      />
                    </button>
                  </div>
                </div>
              </CCol>

              {/* Target Code List */}
              <CCol md={6}>
                <div className='rightOuterBox'>

                  <h5>Codes included in industry</h5>
                  <div className='checkBoxHeader'>
                    <input
                      type="checkbox"
                      checked={selectedTargetItems?.length === targetItems?.length}
                      onChange={handleSelectAllTarget}
                    />
                    <label>Select All</label>
                  </div>
                  <div className='rightInputBox'>
                    {targetItems && targetItems?.map((item) => (
                      <div key={item?.id} className='checkBoxRow'>
                        <input
                          type="checkbox"
                          checked={selectedTargetItems?.some((selected) => selected?.id === item?.id)}
                          onChange={(event) => handleTargetCheckboxChange(event, item)}
                        />
                        {item?.codeName}
                      </div>
                    ))}

                  </div>
                  <div className='arrowMoveLeft'>
                    {/* <CTooltip content="Left"> */}
                    <button>
                      <CImage src={leftArrow} onClick={moveItemsToSource}

                      />
                    </button>
                    {/* </CTooltip> */}
                    {/* <button onClick={moveItemsToSource}>
                  leftArrow
                  </button> */}
                  </div>
                </div>
              </CCol>
            </CRow>

            {/* Save and Cancel Buttons */}
            <CRow className="mt-4 justify-content-end">
              <CCol xs="auto">
                <CButton color="light" onClick={() => {
                  resetForm()
                  props.handleClose()
                }}>
                  Cancel
                </CButton>
              </CCol>
              <CCol xs="auto">
                <CButton color="primary" onClick={(e)=>handleCreateIndustry(e)} style={{ textTransform: 'none' }}>
                {isLoading ? <CSpinner size="sm" /> : 'Save'}
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CContainer>
      </CCardBody>
    </>
  );
};

export default AddCode;
