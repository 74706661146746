/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react'
import {
  CForm,
  CCardBody,
  CCard,
  CFormLabel,
  CFormInput,
  CButton,
  CFormSelect,
  CRow,
  CCol,
} from '@coreui/react'
import { ChromePicker } from 'react-color'
import FileUpload from '../../../components/share/import'
import PropTypes from 'prop-types'
import { postApiWithHeader } from 'src/Api/Api';
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'

const Branding = (props) => {

  const location = useLocation();
  const { editValue } = location.state || {};

  Branding.propTypes = {
    editValue: PropTypes.object,
  }

  // const [visible, setVisible] = useState(false)
  const [importFile, setFileImport] = useState()
  const [csvData, setCsvData] = useState()
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [currentField, setCurrentField] = useState(null)
  const accessToken = localStorage.getItem('token')
  const navigate = useNavigate()
  const [selectedColors, setSelectedColors] = useState({
    brandColor: '#ffffff',
    naviColor: '#ffffff',
    headColor: '#ffffff',
    footColor: '#ffffff',
  })

  const [formData, setFormData] = useState({
    companyName: '',
  })


  useEffect(() => {
    if (editValue) {
      setFormData({
        companyName: editValue.companyName || '',
      })
    }
  }, [editValue])
  console.log('editValue', editValue)
  const callBackFile = (data) => {
    setFileImport(data)
  }

  const callBack = (data) => {
    setCsvData(data)
  }

  const handleColorChange = (newColor) => {
    setSelectedColors((prevState) => ({
      ...prevState,
      [currentField]: newColor.hex,
    }))
  }

  const handleClick = (field) => {
    setCurrentField(field)
    setDisplayColorPicker(true)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken],
  )

  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    }),
    [accessToken],
  )

  useEffect(() => {}, [headers])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('Logo', importFile)
    formData.append('Configuration', JSON.stringify(selectedColors))
    formData.append('UserId', editValue ? editValue?.id : '')
    try {
      const response = await postApiWithHeader('api/PromoterBranding/create', formData, headersFile)
      if (response?.data) {
        toast.success('Added successfully',{ autoClose: 1000 })
      }
    } catch (error) {
      console.error('Error submitting form data:', error)
    }
  }

  const handleCancel = () =>{
    navigate('/promoters')
  }

  return (
    <CCard
      style={{
        width: '-webkit-fill-available',
        border: '1px solid #D9D9D9',
        borderRadius: '25px',
        opacity: 1,
      }}
    >
      <CCardBody className="m-3">
        <h3>Set your branding</h3>
        <CForm onSubmit={handleSubmit}>
          <div className="cardHeader">
            <div className="mb-6">
              <div className="mb-3">
                <CFormLabel>Company Name</CFormLabel>
                <CFormInput
                  type="text"
                  name="companyName"
                  disabled
                  value={formData.companyName} />
                  
              </div>
              <div className="mb-3">
                <CFormLabel>Branding Color</CFormLabel>
                <CFormInput
                  type="text"
                  name="brandColor"
                  value={selectedColors.brandColor}
                  onClick={() => handleClick('brandColor')}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Navigation Color</CFormLabel>
                <CFormInput
                  type="text"
                  name="naviColor"
                  value={selectedColors.naviColor}
                  onClick={() => handleClick('naviColor')}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Header Color</CFormLabel>
                <CFormInput
                  type="text"
                  name="headColor"
                  value={selectedColors.headColor}
                  onClick={() => handleClick('headColor')}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <CFormLabel>Footer Color</CFormLabel>
                <CFormInput
                  type="text"
                  name="footColor"
                  value={selectedColors.footColor}
                  onClick={() => handleClick('footColor')}
                  readOnly
                />
              </div>
              {displayColorPicker && (
                <div style={{ position: 'absolute', zIndex: '2' }}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={handleClose}
                  />
                  <ChromePicker color={selectedColors[currentField]} onChange={handleColorChange} />
                </div>
              )}
            </div>
            <div className="mb-6">
              <FileUpload
                // handleCloseClick={handleCloseClick}
                callBackFile={callBackFile}
                callBack={callBack}
              // handleUpload={handleUploads}
              />
            </div>
          </div>
          <CRow className="mt-4 justify-content-end">
            <CCol xs="auto">
              <CButton color="light" onClick={handleCancel}>Cancel</CButton>
            </CCol>
            <CCol xs="auto">
              <CButton type="submit" color="primary">
                Save
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  )
}

export default Branding
