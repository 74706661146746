import React, { useState, useEffect, useMemo } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCol, CRow, CSpinner } from '@coreui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import { postApiWithHeader, UpdateUserwithHeader } from 'src/Api/Api';
import { toast } from 'react-toastify';

const AddInvitation = (props) => {
  const accessToken = localStorage.getItem('token');
  const editValue = props.editValue
  const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    companyId:0,
    receiverName: '',
    receiverMobile: '',
    role: 'BusinessOwner',
    deviceType : 'web'
  });
 


  AddInvitation.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.object,
    clearFormData: PropTypes.bool,
  };


  useEffect(() => {
    if (editValue) {
      setFormData({
        receiverName: props.editValue.receiverName || '',
        receiverMobile: props.editValue.receiverMobile || '',
        role: 'BusinessOwner',
        DeviceType : 'web'
      });
    }
  }, [editValue]);


  useEffect(() => {
    if (props.clearFormData) {
      resetForm();
      props.handleClose();
    }
  }, [props.clearFormData]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  // const handleOnChange = (value, country) => {
  //   const formattedPhoneNumber = country.format ? `+${value}` : value;
  //   setFormData({
  //     ...formData,
  //     receiverMobile: formattedPhoneNumber
  //   });
  //   setErrors({
  //     ...errors,
  //     receiverMobile: ''
  //   });
  // };

  const handleOnChange = (value, country) => {
    // Remove leading zeros from the phone number
    const sanitizedPhoneNumber = value.replace(/^0+/, '');
  
    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
  
    // Update formData with the formatted phone number
    setFormData({
      ...formData,
      receiverMobile: formattedPhoneNumber
    });
    setErrors({
      ...errors,
      receiverMobile: ''
    });
  };


  const resetForm = () => {
      setFormData({
        receiverName: editValue ? editValue.receiverName : '',
        receiverMobile: editValue? editValue.receiverMobile : '',
        role: 'BusinessOwner',
        DeviceType : 'web'
      });
    setErrors({})
  }


  const handleAdd = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const validationErrors = {};
    if (!formData.receiverName.trim()) {
      validationErrors.receiverName = 'User name is required';
    }
    if (!formData.receiverMobile.trim()) {
      validationErrors.receiverMobile = 'Mobile number is required';
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      let response;
      if (!editValue) {
        response = await postApiWithHeader('/api/invitation/create', formData, headers);
        console.log('-------',response)
        if (response.data.message === "Success") {
          setIsLoading(false)
          props.apiCall();
          props.handleClose();
          toast.success('Added Successfully', { autoClose: 1000 });
         }else if (response.data.statusCode === 102) {
          // Show toast for user already registered without closing the modal
          toast.error(response.data.message,{ autoClose: 1000 })
        }
         else {
          console.error('Unexpected response format:', response);
        }
      }
      else {
        response = await UpdateUserwithHeader(`/api/invitation/update/${editValue.id}`, formData, headers);
        if (response.message === 'Success') {
          props.apiCall();
          props.handleClose();
          toast.success('Updated Successfully', { autoClose: 1000 })
        } else if (response.statusCode === 102) {
          // Show toast for user already registered without closing the modal
          toast.error(response.message,{ autoClose: 1000 })
        }
           else {
          console.error('Unexpected response format:', response);
        }
      }
    } catch (error) {
      console.error('Error in POST request:', error);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <CForm onSubmit={handleAdd}>
      <div className="mb-3">
        <CFormLabel>User Name<span className="text-danger">*</span></CFormLabel>
        <CFormInput
          type="text"
          name="receiverName"
          placeholder='Enter user name'
          value={formData.receiverName}
          onChange={handleInputChange}
          className={errors.receiverName ? 'is-invalid' : ''}
        />
        {errors.receiverName && <div className="invalid-feedback">{errors.receiverName}</div>}
      </div>
      <div className='mb-3'>
        <CFormLabel>
          Mobile Number <span className="text-danger">*</span>
        </CFormLabel>
        <PhoneInput
          name="receiverMobile"
          placeholder='Enter mobile number'
          country={'au'}
          // defaultCountry="AU" 
          onChange={(value, country) => handleOnChange(value, country)}
          value={formData.receiverMobile}
          inputStyle={{ width: '100%' }}
          className={errors.receiverMobile ? 'is-invalid' : ''}
        />
        {errors.receiverMobile && <div className="invalid-feedback">{errors.receiverMobile}</div>}
      </div>
      <CRow className="mt-4 justify-content-end">
        <CCol xs="auto">
          <CButton color="light" onClick={() => {
            resetForm()
            props.handleClose()
          }}
          >
            Cancel
          </CButton>
        </CCol>
        <CCol xs="auto">
        <CButton type="submit" color="primary" className='mb-2' style={{ textTransform: 'none' }}>
            {isLoading ? <CSpinner size="sm" /> : 'Save'}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
}

export default AddInvitation;
