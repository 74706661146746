import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
    CFormLabel,
    CDropdownToggle,
    CDropdown,
    CDropdownMenu,
    CDropdownItem,
    CFormCheck
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { cilLockLocked, cilUser } from '@coreui/icons'

const AddCode = () => {

    // Initialize state for form fields
    const [formData, setFormData] = useState({
        title: '',
        categoryTypeA: 'In',
        entryType: 'Capital',
        copyParameter: '',
        categoryTypeB: 'Revenue',
        value: 'Debitor',
        gst: 'Normal',
    });

    // Handler function to update the state when the input changes
    const handleChange = (event, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: event.target.value,
        });
    };

    // Handler function for form submission
    const handleSave = () => {
        // Log the form data to the console
        console.log(formData);

        // Add your logic to save the form data to the backend or perform other actions
    };

    return (
        <div>
            <h4 className='mb-3'>Add Code</h4>
            <CContainer>
                <CRow>
                    <CCol md={6}>
                        <CCardGroup>
                            <CForm>
                                <h6>Code Information</h6>
                                <CFormInput
                                    type="text"
                                    label="Add New Code"
                                    placeholder="Sales 43"
                                    aria-describedby="exampleFormControlInputHelpInline"
                                    value={formData.title}
                                    onChange={(event) => handleChange(event, 'title')}
                                />
                                <div>
                                    <h6 className="mt-4">Category Type A</h6>
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions6"
                                        id="inlineCheckbox2"
                                        value="In"
                                        label="In"
                                        defaultChecked={formData.categoryTypeA === 'In'}  // Use defaultChecked for initial state
                                        onChange={(event) => handleChange(event, 'categoryTypeA')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions6"
                                        id="inlineCheckbox2"
                                        value="Out"
                                        label="Out"
                                        checked={formData.categoryTypeA === 'Out'}
                                        onChange={(event) => handleChange(event, 'categoryTypeA')}
                                    />
                                </div>
                                <div>
                                    <h6 className="mt-4">Entry Type</h6>
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOption"
                                        id="inlineCheckbox3"
                                        value="Capital"
                                        label="Capital"
                                        checked={formData.entryType === 'Capital'}
                                        onChange={(event) => handleChange(event, 'entryType')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOption"
                                        id="inlineCheckbox4"
                                        value="Non Capital"
                                        label="Non Capital"
                                        checked={formData.entryType === 'Non Capital'}
                                        onChange={(event) => handleChange(event, 'entryType')}
                                    />
                                </div>
                                <div>
                                    <h6 className='mt-4'>Copy parameter</h6>
                                    <CRow>
                                        <CCol md="5" style={{ marginBottom: '15px' }}>
                                            <CDropdown>
                                                <CDropdownToggle
                                                    style={{ width: '150px' }}
                                                    placeholder='Select Industry'
                                                >
                                                    {formData.copyParameter || 'Select Industry'}
                                                </CDropdownToggle>
                                                <CDropdownMenu>
                                                    <CDropdownItem
                                                        onClick={() => handleChange({ target: { value: 'Transport' } }, 'copyParameter')}
                                                    >
                                                        Transport
                                                    </CDropdownItem>
                                                    <CDropdownItem
                                                        onClick={() => handleChange({ target: { value: 'Garage' } }, 'copyParameter')}
                                                    >
                                                        Garage
                                                    </CDropdownItem>
                                                    <CDropdownItem
                                                        onClick={() => handleChange({ target: { value: 'Salon' } }, 'copyParameter')}
                                                    >
                                                        Salon
                                                    </CDropdownItem>
                                                </CDropdownMenu>
                                            </CDropdown>
                                        </CCol>
                                    </CRow>
                                </div>
                                <div>
                                    <h6 className="mt-4">Category Type B</h6>
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions3"
                                        id="inlineCheckbox3"
                                        value="Revenue"
                                        label="Revenue"
                                        checked={formData.categoryTypeB === 'Revenue'}
                                        onChange={(event) => handleChange(event, 'categoryTypeB')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions3"
                                        id="inlineCheckbox5"
                                        value="Expense"
                                        label="Expense"
                                        checked={formData.categoryTypeB === 'Expense'}
                                        onChange={(event) => handleChange(event, 'categoryTypeB')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions3"
                                        id="inlineCheckbox3"
                                        value="Asset"
                                        label="Asset"
                                        checked={formData.categoryTypeB === 'Asset'}
                                        onChange={(event) => handleChange(event, 'categoryTypeB')}
                                    />
                                </div>

                                <div>
                                    <h6 className="mt-4">Value</h6>
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions4"
                                        id="inlineCheckbox4"
                                        value="Debitor"
                                        label="Debitor"
                                        checked={formData.value === 'Debitor'}
                                        onChange={(event) => handleChange(event, 'value')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions4"
                                        id="inlineCheckbox2"
                                        value="Creditor"
                                        label="Creditor"
                                        checked={formData.value === 'Creditor'}
                                        onChange={(event) => handleChange(event, 'value')}
                                    />
                                </div>

                                <div>
                                    <h6 className="mt-4">GST</h6>
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions5"
                                        id="inlineCheckbox5"
                                        value="Normal"
                                        label="Normal"
                                        checked={formData.gst === 'Normal'}
                                        onChange={(event) => handleChange(event, 'gst')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions5"
                                        id="inlineCheckbox2"
                                        value="Exempted"
                                        label="Exempted"
                                        checked={formData.gst === 'Exempted'}
                                        onChange={(event) => handleChange(event, 'gst')}
                                    />
                                    <CFormCheck
                                        inline
                                        type="radio"
                                        name="inlineRadioOptions5"
                                        id="inlineCheckbox3"
                                        value="Non Standard"
                                        label="Non Standard"
                                        checked={formData.gst === 'Non Standard'}
                                        onChange={(event) => handleChange(event, 'gst')}
                                    />
                                </div>

                                <CRow className="d-flex justify-content-between ">
                                    <CCol>
                                        <CButton color="primary" className="px-4"
                                        // onClick={handleButtonClick}
                                        >
                                            Cancel
                                        </CButton>
                                    </CCol>
                                    <CCol>
                                        <CButton color='primary' className='px-4' onClick={handleSave}>
                                            Save
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default AddCode