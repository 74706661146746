import React, { useEffect, useMemo, useState } from 'react'
import {
  CRow,
  CCol,
  CWidgetStatsA,
} from '@coreui/react'
import { GetApiwithHeader } from 'src/Api/Api'

const WidgetsDropdown = () => {

  const [items, setItems] = useState([])
  const accessToken = localStorage.getItem('token')
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetApiwithHeader('/api/dashboard/getRolesCount', headers)
        setItems(res?.data?.data)
        console.log('------', res.data.data)

      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, [])
  return (
    <>
      <CRow>
        {items.map((data, index) => (
          <CCol key={index} md={3} >
            <CWidgetStatsA
             className="mb-4"
              // color="primary"
              value={<>
              <h4>{data.count}</h4></>}
              title={data.name}
              style={{height:'120px',borderRadius:'20px'}}
            />
            
          </CCol>
        ))}
      </CRow>
    </>
  )
}

export default WidgetsDropdown
