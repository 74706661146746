import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import logo  from 'src/assets/brand/logo.png'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import {promoter , admin} from '../_nav'
// import promoNavi from '../_nav2'

const AppSidebar = () => {
  const Role = localStorage.getItem('userInfo')
  const role = JSON.parse(Role)?.role
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (     
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
      style={{background:'#353540'}}
    >
      {/* <CSidebarBrand className="d-none d-md-flex" to="/" style={{backgroundColor:'#FFFFFF'}}>
      <CImage src={logo} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand> */}
      <CSidebarNav>
        <SimpleBar>
          {
            role === 'SuperAdmin' ? 
            <AppSidebarNav items={admin} />
            :
            <AppSidebarNav items={promoter} />
          }
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
      {/* <button >Logout</button> */}
    </CSidebar>
   )
}

export default React.memo(AppSidebar)
