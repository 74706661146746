import React, { useEffect, useMemo, useState } from 'react';
import { CImage, CCard, CCardBody, CButton, CModalBody, CModalHeader, CModalFooter, CModal, CTooltip } from '@coreui/react';
import { debitor, deleteIcon, edit, page1, out } from 'src/components/Images/Images';
import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api';
import OffCanvas from '../../../components/share/canvas';
import Add from './Add';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license';

const CodeManagement = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const [items, setItems] = useState([]);
  const [editValue, setEditValue] = useState();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getCategory, setGetCategory] = useState();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewValue, setViewValue] = useState(false);
  const [loadingState, setLoadingState] = useState(true);

  const accessToken = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const filteredServices = useMemo(() => {
    return items.filter(
      (service) =>
        service.codeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.entryType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.categoryA.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.categoryB.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.gst.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [items, searchQuery]);

  const fetchData = async () => {
    const apiUrl = '/api/code';
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      setItems(res?.data?.data || []);
      setLoadingState(false);
    } catch (error) {
      console.log(error);
      setLoadingState(false);
    }
  };

  const getCategoryFun = async () => {
    try {
      const response = await GetApiwithHeader('api/CodeCategory/Get', headers);
      const filteredRoles = response?.data?.data.filter((item) => item?.categoryName || []);
      setGetCategory(filteredRoles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    getCategoryFun();
  }, []);

  const handleAddClick = () => {
    setEditValue();
    setVisible(true);
  };

  const handleCloseClick = () => {
    setVisible(false);
    setClearFormDataFlag((prev) => !prev);
    setEditValue();
  };

  const handleEditClick = (i) => {
    setEditValue(i);
    setVisible(true);
  };

  const handleDelete = (i) => {
    setEditValue(i);
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (id) => {
    const apiUrl = `/api/code/delete/${id}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === 'Success') {
        setIsModalVisible(false);
        const updatedIndustry = items.filter((entry) => entry.id !== id);
        setItems(updatedIndustry);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Delete API Request Error:', error);
    }
  };
  

  const columns = [
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      // align: "center",
      // headerAlign: "center",
      headerClassName: 'dataGridHeader'
    },
    { field: 'codeName', headerName: 'Code Name', width: 200, headerClassName: 'dataGridHeader', sortable: true },
    {
      field: 'entryType',
      headerName: 'Entry Type',
      sortable: true,
      width: 150,
      headerClassName: 'dataGridHeader',
      renderCell: (params) => (
        params.value === 'In'
          ? <><span>{params.value}</span>
          <span className="icon-moneyin"></span>
           {/* <CImage src={page1} width="14px" height="14px" /> */}
           </>
          : <><span>{params.value}</span>
          <span className="icon-moneyout"></span>
           {/* <CImage src={out} width="15px" height="16px" /> */}
           </>
      )
    },
    { field: 'categoryA', headerName: 'Category A', width: 150, headerClassName: 'dataGridHeader',sortable: true },
    { field: 'categoryB', headerName: 'Category B', width: 150, headerClassName: 'dataGridHeader' ,sortable: true},
    {
      field: 'value',
      headerName: 'Category C',
      width: 150,
      headerClassName: 'dataGridHeader',
      sortable: true,
      // renderCell: (params) => (
      //   params.value === 'Debitor'
      //     ? <><CImage src={debitor} width="14px" height="14px" /> {params.value}</>
      //     : params.value
      // )
    },
    { field: 'gst', headerName: 'GST', width: 150, headerClassName: 'dataGridHeader' },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 70,
      // sortable: false,
      headerClassName: 'dataGridHeader',
      type: "actions",
      renderCell: (params) => (
        <div className="rowIcon">
          <CTooltip content="Edit">
            <button className="icon-editIcon" onClick={() => handleEditClick(params.row)}>
              {/* <CImage src={edit} /> */}
            </button>
          </CTooltip>
        </div>
      )
    }
  ];

  return (
    <div className="cardBody">
      <ToastContainer position="top-right" autoClose={1000} />
      <CCard>
        <CCardBody className="m-2">
          <div className="cardHeader">
            <h3>Code Management</h3>
            <div className="mr-1">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <CButton onClick={handleAddClick}>Add Code</CButton>
            </div>
          </div>
          <div className="datatableGrid">
            <DataGridPro
              rows={filteredServices}
              columns={columns}
              pageSizeOptions={[5, 10, 15]}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 }
                }
              }}
              density="compact"
              disableRowSelectionOnClick
              loading={loadingState}
            />
          </div>
        </CCardBody>
      </CCard>
      <OffCanvas
        Open={visible}
        title={editValue ? 'Edit Code' : 'Add Code'}
        handleCloseClick={handleCloseClick}
        component={
          <Add
            apiCall={fetchData}
            handleClose={handleCloseClick}
            editValue={editValue}
            items={items}
            getCategory={getCategory}
            clearFormData={clearFormDataFlag}
          />
        }
      />
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto'
        }}
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>Are you sure want to delete {editValue?.codeName}...?</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setIsModalVisible(false)}>No</CButton>
          <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>Yes</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default CodeManagement;


// import React, { useEffect,useMemo, useState } from 'react';
// import {
//   CTable,
//   CTableBody,
//   CTableDataCell,
//   CTableHeaderCell,
//   CTableHead,
//   CTableRow,
//   CImage,
//   CCard,
//   CCardBody,
//   CButton,
//   CModalBody,
//   CModalHeader,
//   CModalFooter,
//   CModal,
//   CTooltip
// } from '@coreui/react';
// import { debitor, deleteIcon, edit, normal, page1, out } from 'src/components/Images/Images';
// import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api';
// import { capital, revenue } from 'src/components/Images/Images';
// import OffCanvas from '../../../components/share/canvas';
// import Add from './Add';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";


// const CodeManagement = () => {
//   const [items, setItems] = useState([]);
//   const [editValue, setEditValue] = useState();
//   const [visible, setVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [getCategory, setGetCategory] = useState();
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(15); // Number of items per page
//   const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('')
//   const [viewValue, setViewValue] = useState(false);

//   const accessToken = localStorage.getItem('token');
//   const headers = {
//     Authorization: `Bearer ${accessToken}`,
//   };

//   const filteredServices = useMemo(() => {
//     return items.filter(
//       (service) =>
//         service.codeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         // service.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.entryType.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.categoryA.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.categoryB.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.gst.toLowerCase().includes(searchQuery.toLowerCase())        )
//   }, [items, searchQuery])

//   const fetchData = async () => {
//     const apiUrl = '/api/code';
//     try {
//       const res = await GetApiwithHeader(apiUrl, headers);
//       setItems(res?.data?.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getCategoryFun = async () => {
//     try {
//       const response = await GetApiwithHeader(`api/CodeCategory/Get`, headers);
//       const filteredRoles = response?.data?.data.filter((item) => item?.categoryName);
//       setGetCategory(filteredRoles);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//     getCategoryFun();
//   }, []);

//   const handleAddClick = () => {
//     setEditValue();
//     setVisible(true);
//   };

//   const handleCloseClick = () => {
//     setVisible(false);
//     setClearFormDataFlag((prev) => !prev);
//     setEditValue()
//   };

//   // const handleEditClick = (i) => {
//   //   setEditValue(i);
//   //   setVisible(true);
//   // };

//   const handleEditClick = (i) => {
//     // setViewValue(true);
//     setEditValue(i);
//     setVisible(true);
//   };

//   const handleDelete = (i) => {
//     setEditValue(i);
//     setIsModalVisible(true);
//   };

//   const handleDeleteClick = async (id) => {
//     const apiUrl = `/api/code/delete/${id}`;
//     try {
//       const response = await DeleteApiwithHeader(apiUrl, headers);
//       if (response.message === 'Success') {
//         // toast.success("Deleted Successfully");
//         setIsModalVisible(false);
//         const updatedIndustry = items.filter((entry) => entry.id !== id);
//         setItems(updatedIndustry);
//       } else {
//         console.error('Unexpected response format:', response);
//       }
//     } catch (error) {
//       console.error('Delete API Request Error:', error);
//     }
//   };

//   const handleSort = (key) => {
//     let direction = 'ascending';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };

//   const sortedItems = () => {
//     const sortableItems = [...items];
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sortableItems;
//   };

//   const handlePrevPage = () => {
//     setCurrentPage(currentPage - 1);
//   };

//   // Function to handle next page
//   const handleNextPage = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   const totalPages = Math.ceil(items?.length / itemsPerPage);
//   // Function to get the current page's data
//   const getCurrentPageData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     return filteredServices?.slice(startIndex, endIndex) 
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   return (
//     <div className="cardBody">
//         <ToastContainer position="top-right" autoClose={1000} />
//       <CCard>
//         <CCardBody className="m-2">
//           <div className="cardHeader">
//             <h3>Code Management</h3>
//             <div className="mr-1">
//                 <input
//                   type="text"
//                   placeholder="Search..."
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
//                   // Adding Bootstrap class for styling
//                 />
//             <CButton onClick={() => handleAddClick()}>
//               Add Code
//             </CButton>
//           </div>
//           </div>
//           <div className="cardTable">
//           <CTable>
//             <CTableHead>
//               <CTableRow>
//               <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">
//                   S.No
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('codeName')}
//                 >
//                   Code Name
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('id')}
//                 >
//                   Code No
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('entryType')}
//                 >
//                   Entry Type
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('categoryA')}
//                 >
//                   Category A
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('categoryB')}
//                 >
//                   Category B
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('value')}
//                 >
//                   Value
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('gst')}
//                 >
//                   GST
//                 </CTableHeaderCell>
//                 <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">
//                   Action
//                 </CTableHeaderCell>
//               </CTableRow>
//             </CTableHead>
//             <CTableBody>
//               {getCurrentPageData()?.map((i, index) => (
//                 <CTableRow key={index}>
//                    <CTableDataCell >
//                     {(currentPage - 1) * itemsPerPage + index + 1}
//                   </CTableDataCell>
//                   <CTableDataCell>{i.codeName}</CTableDataCell>
//                   <CTableDataCell>{i.id}</CTableDataCell>
//                   <CTableDataCell>
//                     {i.entryType === 'In' ? (
//                       <>
//                         {' '}
//                         {i.entryType} <CImage src={page1} width="14px" height="14px" />{' '}
//                       </>
//                     ) : (
//                       <>
//                         {i.entryType} <CImage src={out} width="15px" height="16px" />
//                       </>
//                     )}
//                   </CTableDataCell>
//                   <CTableDataCell>{i.categoryA}</CTableDataCell>
//                   <CTableDataCell>{i.categoryB}</CTableDataCell>
//                   <CTableDataCell>
//                     {i.value === 'Debitor' ? (
//                       <>
//                         {' '}
//                         <CImage src={debitor} width="14px" height="14px" /> {i.value}{' '}
//                       </>
//                     ) : (
//                       i.value
//                     )}
//                   </CTableDataCell>
//                   <CTableDataCell>{i.gst}</CTableDataCell>
//                   <CTableDataCell className="ml-2">
//                   <CTooltip content="Edit">
//                   <button className="editIcon">
//                     <CImage
//                       src={edit}
//                       onClick={() => handleEditClick(i)}
//                     />
//                     </button>
//                     </CTooltip>
//                     {/* <CTooltip content="Delete">
//                     <button className="deleteIcon">
//                     <CImage
//                       src={deleteIcon}
//                       onClick={() => handleDelete(i)}
//                     />
//                     </button>
//                     </CTooltip> */}
//                   </CTableDataCell>
//                 </CTableRow>
//               ))}
//             </CTableBody>
//           </CTable>
//           </div>
//           <nav>
//                     <ul className="pagination">
//                       <li
//                         className={`page-item ${
//                           currentPage === 1 ? "disabled" : ""
//                         }`}
//                       >
//                         <button className="page-link" onClick={handlePrevPage}>
//                           Previous
//                         </button>
//                       </li>
//                       {Array.from({ length: totalPages }, (_, index) => (
//                         <li
//                           key={index}
//                           className={`page-item ${
//                             currentPage === index + 1 ? "active" : ""
//                           }`}
//                         >
//                           <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                             {index + 1}
//                           </button>
//                         </li>
//                       ))}
//                       <li
//                         className={`page-item ${
//                           currentPage === totalPages ? "disabled" : ""
//                         }`}
//                       >
//                         <button className="page-link" onClick={handleNextPage}>
//                           Next
//                         </button>
//                       </li>
//                     </ul>
//                   </nav>
//         </CCardBody>
//       </CCard>
//       <OffCanvas
//         Open={visible}
//         title={editValue ? 'Edit Code' : 'Add Code'}
//         handleCloseClick={handleCloseClick}
//         component={
//           <Add
//             apiCall={fetchData}
//             handleClose={handleCloseClick}
//             editValue={editValue}
//             items={items}
//             // viewValue={handleEditClick}
//             getCategory={getCategory}
//             clearFormData={clearFormDataFlag}
//           />
//         }
//       />
//       <CModal
//         visible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//         aria-labelledby="ScrollingLongContentExampleLabel"
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           overflow: 'auto',
//         }}
//       >
//         <CModalHeader>
//           <h3>Delete</h3>
//         </CModalHeader>
//         <CModalBody>Are you sure want to delete {editValue?.codeName}...?</CModalBody>
//         <CModalFooter>
//           <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
//             No
//           </CButton>
//           <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
//             Yes
//           </CButton>
//         </CModalFooter>
//       </CModal>
//     </div>
//   );
// };

// export default CodeManagement;

// import React, { useEffect, useMemo, useState } from 'react';
// import { CImage, CCard, CCardBody, CButton, CModalBody, CModalHeader, CModalFooter, CModal, CTooltip } from '@coreui/react';
// import { debitor, deleteIcon, edit, normal, page1, out } from 'src/components/Images/Images';
// import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api';
// import { capital, revenue } from 'src/components/Images/Images';
// import OffCanvas from '../../../components/share/canvas';
// import Add from './Add';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { DataGridPro } from '@mui/x-data-grid-pro';
// import { LicenseInfo } from '@mui/x-license';
// // import { useData } from "../../components/dataContext";

// const CodeManagement = () => {
//   LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
//   // const { data, bankDataLocal } = useData();
//   const [items, setItems] = useState([]);
//   const [editValue, setEditValue] = useState();
//   const [visible, setVisible] = useState(false);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [getCategory, setGetCategory] = useState();
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(15); // Number of items per page
//   const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('')
//   const [viewValue, setViewValue] = useState(false);
//   const [loadingState, setLoadingState] = useState(true)

//   const accessToken = localStorage.getItem('token');
//   const headers = {
//     Authorization: `Bearer ${accessToken}`,
//   };

//   const filteredServices = useMemo(() => {
//     return items.filter(
//       (service) =>
//         service.codeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.entryType.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.categoryA.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.categoryB.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.gst.toLowerCase().includes(searchQuery.toLowerCase())
//     );
//   }, [items, searchQuery]);

//   const fetchData = async () => {
//     const apiUrl = '/api/code';
//     try {
//       const res = await GetApiwithHeader(apiUrl, headers);
//       setItems(res?.data?.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getCategoryFun = async () => {
//     try {
//       const response = await GetApiwithHeader(`api/CodeCategory/Get`, headers);
//       const filteredRoles = response?.data?.data.filter((item) => item?.categoryName);
//       setGetCategory(filteredRoles);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//     getCategoryFun();
//   }, []);

//   const handleAddClick = () => {
//     setEditValue();
//     setVisible(true);
//   };

//   const handleCloseClick = () => {
//     setVisible(false);
//     setClearFormDataFlag((prev) => !prev);
//     setEditValue();
//   };

//   const handleEditClick = (i) => {
//     setEditValue(i);
//     setVisible(true);
//   };

//   const handleDelete = (i) => {
//     setEditValue(i);
//     setIsModalVisible(true);
//   };

//   const handleDeleteClick = async (id) => {
//     const apiUrl = `/api/code/delete/${id}`;
//     try {
//       const response = await DeleteApiwithHeader(apiUrl, headers);
//       if (response.message === 'Success') {
//         setIsModalVisible(false);
//         const updatedIndustry = items.filter((entry) => entry.id !== id);
//         setItems(updatedIndustry);
//       } else {
//         console.error('Unexpected response format:', response);
//       }
//     } catch (error) {
//       console.error('Delete API Request Error:', error);
//     }
//   };

//   const handleSort = (key) => {
//     let direction = 'ascending';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };

//   const columns = [
//     // { field: 'id', headerName: 'S.No', width: 70 },
//     {
//       field: " ",
//       headerName: "",
//       width: 5,
//       resizable: false,
//       sortable: false,
//       // align: "center",
//       // headerAlign: "center",
//       headerClassName: 'dataGridHeader'
//     },
//     { field: 'codeName', headerName: 'Code Name', width: 200, headerClassName: 'dataGridHeader' },
//     { field: 'entryType', headerName: 'Entry Type', width: 150, renderCell: (value,row) => (value === 'In' ? <><span>{value}</span> <CImage src={page1} width="14px" height="14px" /></> : <><span>{value}</span> <CImage src={out} width="15px" height="16px" /></>) },
//     { field: 'categoryA', headerName: 'Category A', width: 150, headerClassName: 'dataGridHeader' },
//     { field: 'categoryB', headerName: 'Category B', width: 150, headerClassName: 'dataGridHeader'},
//     { field: 'value', headerName: 'Category C', width: 150,  headerClassName: 'dataGridHeader'},
//     { field: 'gst', headerName: 'GST', width: 150, headerClassName: 'dataGridHeader' },
//     {
//       field: 'actions',
//       headerName: 'Actions',
//       width: 100,
//       sortable: false,
//       renderCell: (row) => (
//         <div className="rowIcon">
//           <CTooltip content="Edit">
//             <button className="icon-editIcon" onClick={() => handleEditClick(row)}><CImage src={edit} /></button>
//           </CTooltip>
//           {/* <CTooltip content="Delete">
//             <button className="icon-deleteIcon" onClick={() => handleDelete(params.row)}><CImage src={deleteIcon} /></button>
//           </CTooltip> */}
//         </div>
//       ),
//     },
//   ];

//   return (
//     <div className="cardBody">
//       <ToastContainer position="top-right" autoClose={1000} />
//       <CCard>
//         <CCardBody className="m-2">
//           <div className="cardHeader">
//             <h3>Code Management</h3>
//             <div className="mr-1">
//               <input
//                 type="text"
//                 placeholder="Search..."
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//               <CButton onClick={handleAddClick}>
//                 Add Code
//               </CButton>
//             </div>
//           </div>
//           <div className="cardTable">
//             <DataGridPro
//               rows={filteredServices}
//               columns={columns}
//               // pageSize={itemsPerPage}
//               // rowsPerPageOptions={[5, 10, 15]}
//               // pagination
//               // onPageChange={(params) => setCurrentPage(params.page)}
//               // paginationMode="server"
//               // sortingOrder={['asc', 'desc']}
//               // sortModel={[
//               //   {
//               //     field: sortConfig.key,
//               //     sort: sortConfig.direction,
//               //   },
//               // ]}
//               // onSortModelChange={(model) => {
//               //   if (model.length > 0) {
//               //     const { field, sort } = model[0];
//               //     handleSort(field);
//               //     setSortConfig({ key: field, direction: sort });
//               //   }
//               // }}
//               // autoHeight
//               initialState={{
//                 pagination: {
//                   paginationModel: { page: 0, pageSize: 15 },
//                 },
//               }}
//               pageSizeOptions={[5, 10]}
//               // onRowClick={(row) => handleView(row.row)}
//               density="compact"
//               disableRowSelectionOnClick
//               loading={loadingState}
//             />
//           </div>
//         </CCardBody>
//       </CCard>
//       <OffCanvas
//         Open={visible}
//         title={editValue ? 'Edit Code' : 'Add Code'}
//         handleCloseClick={handleCloseClick}
//         component={
//           <Add
//             apiCall={fetchData}
//             handleClose={handleCloseClick}
//             editValue={editValue}
//             items={items}
//             getCategory={getCategory}
//             clearFormData={clearFormDataFlag}
//           />
//         }
//       />
//       <CModal
//         visible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//         aria-labelledby="ScrollingLongContentExampleLabel"
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           overflow: 'auto',
//         }}
//       >
//         <CModalHeader>
//           <h3>Delete</h3>
//         </CModalHeader>
//         <CModalBody>Are you sure want to delete {editValue?.codeName}...?</CModalBody>
//         <CModalFooter>
//           <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
//             No
//           </CButton>
//           <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
//             Yes
//           </CButton>
//         </CModalFooter>
//       </CModal>
//     </div>
//   );
// };

// export default CodeManagement;

