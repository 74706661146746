import React, { useEffect, useMemo, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CForm,
    CFormInput,
    CRow,
} from '@coreui/react'
import { GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
import { ToastContainer, toast } from 'react-toastify';

const PrivacyPolicy = () => {
    const accessToken = localStorage.getItem('token');
    const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken]);
    const [errors, setErrors] = useState({})
    const [privacyPolicy, setPrivacyPolicy] = useState([])
    const [policy, setPolicy] = useState('')
    const [editValue, setEditValue] = useState()

    const [formData, setFormData] = useState({
        typeOfInformation: '',
        howInformationUsed: '',
        contactInformation: '',
        useOfCookiesLogFiles: '',
        optOutPolicyClause: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const fetchData = async () => {
        try {
            const res = await GetApiwithHeader(`/api/privacy-policy`, headers)
            setPrivacyPolicy(res?.data)
            // setEditValue(res?.data?.data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [headers])
   

    useEffect(() => {
        if (privacyPolicy && privacyPolicy.data && privacyPolicy.data.length > 0) {
            const info = privacyPolicy.data[0];
            setFormData({
                typeOfInformation: info.typeOfInformation,
                howInformationUsed: info.howInformationUsed,
                contactInformation: info.contactInformation,
                useOfCookiesLogFiles: info.useOfCookiesLogFiles,
                optOutPolicyClause: info.optOutPolicyClause,
            });
        }
    }, [privacyPolicy]);

    const handleSubmit = async (e) => {
        console.log('Form submitted:', formData)
        e.preventDefault();

        const validationErrors = {};
        if (!formData.typeOfInformation.trim()) {
            validationErrors.typeOfInformation = 'Type of information is required';
        }
        if (!formData.howInformationUsed.trim()) {
            validationErrors.howInformationUsed = 'How information used is required';
        }
        if (!formData.contactInformation.trim()) {
            validationErrors.contactInformation = 'Contact information is required';
        }
        if (!formData.useOfCookiesLogFiles.trim()) {
            validationErrors.useOfCookiesLogFiles = 'Use of cookies log file is required';
        }
        if (!formData.optOutPolicyClause.trim()) {
            validationErrors.optOutPolicyClause = 'Optout policy clause is required';
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const apiUrl = editValue ? `/api/privacy-policy/update/${editValue.id}` : '/api/privacy-policy/create-update'
            const response = await (editValue
                ? UpdateUserwithHeader(apiUrl, formData, headers)
                : postApiWithHeader(apiUrl, formData, headers))

            if (editValue ? response.message == "Success" : response.data.message == "Success") {
                // resetForm()
                toast.success(`${editValue ? 'Updated' : 'Added'} successfully`, { autoClose: 1000 });
            } else {
                console.error('Unexpected response format:', response)
            }
        } catch {
            console.log('error')
        }
    }

    const resetForm = () => {
        setFormData({
            typeOfInformation: '',
            howInformationUsed: '',
            contactInformation: '',
            useOfCookiesLogFiles: '',
            optOutPolicyClause: '',
        });
        setErrors({})
    }

    return (
        <>
            <ToastContainer position="top-right" />
            <CCard
                style={{
                    width: '-webkit-fill-available',
                    border: '1px solid #D9D9D9',
                    borderRadius: '25px',
                    opacity: 1,
                }}
            >
                <CCardBody className="m-3">
                    <div>
                        <h4>Privacy policy</h4>
                        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content</p>
                        <CCol md={12}>
                            <CCardGroup>
                                <CForm onSubmit={handleSubmit}>
                                    <CFormInput
                                        type="text"
                                        name="typeOfInformation"
                                        label={
                                            <>
                                                Types of Information <span className="text-danger">*</span>
                                            </>
                                        }
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={formData.typeOfInformation}
                                        onChange={handleChange}
                                        placeholder="Enter types of information"
                                        className={`mb-2 ${errors.typeOfInformation ? 'is-invalid' : ''}`}
                                    />
                                    {errors.typeOfInformation && <div className="invalid-feedback">{errors.typeOfInformation}</div>}
                                    <CFormInput
                                        type="text"
                                        name="howInformationUsed"
                                        label={
                                            <>
                                                How the information is used <span className="text-danger">*</span>
                                            </>
                                        }
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={formData.howInformationUsed}
                                        onChange={handleChange}
                                        placeholder="Type here ...  "
                                        className={`mb-2 ${errors.useOfCookiesLogFiles ? 'is-invalid' : ''}`}
                                    />
                                    {errors.useOfCookiesLogFiles && <div className="invalid-feedback">{errors.useOfCookiesLogFiles}</div>}
                                    <CFormInput
                                        type="text"
                                        name="contactInformation"
                                        label={
                                            <>
                                                Contact Information <span className="text-danger">*</span>
                                            </>
                                        }
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={formData.contactInformation}
                                        onChange={handleChange}
                                        placeholder="Enter contact information"
                                        className={`mb-2 ${errors.contactInformation ? 'is-invalid' : ''}`}
                                    />
                                    {errors.contactInformation && <div className="invalid-feedback">{errors.contactInformation}</div>}
                                    <CFormInput
                                        type="text"
                                        name="useOfCookiesLogFiles"
                                        label={
                                            <>
                                                Use of Cookies, Log Files <span className="text-danger">*</span>
                                            </>
                                        }
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={formData.useOfCookiesLogFiles}
                                        onChange={handleChange}
                                        placeholder="Enter use of cookies, log files"
                                        className={`mb-2 ${errors.useOfCookiesLogFiles ? 'is-invalid' : ''}`}
                                    />
                                    {errors.useOfCookiesLogFiles && <div className="invalid-feedback">{errors.useOfCookiesLogFiles}</div>}
                                    <CFormInput
                                        type="text"
                                        name="optOutPolicyClause"
                                        label={
                                            <>
                                                Opt-Out Policy Clause<span className="text-danger">*</span>
                                            </>
                                        }
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={formData.optOutPolicyClause}
                                        onChange={handleChange}
                                        placeholder="Enter opt-out policy clause"
                                        className={`mb-2 ${errors.optOutPolicyClause ? 'is-invalid' : ''}`}
                                    />
                                    {errors.optOutPolicyClause && <div className="invalid-feedback">{errors.optOutPolicyClause}</div>}
                                </CForm>
                            </CCardGroup>
                            <CRow className=" mt-3 d-flex justify-content-between">
                                <CCol className="text-end">
                                    <CButton color="primary" className="px-4 " onClick={handleSubmit}>
                                        Save
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </div>
                </CCardBody>
            </CCard>
        </>
    )
}

export default PrivacyPolicy