import axios from 'axios';

// Base URL for your backend API
// const baseURL = process.env.REACT_APP_BACKEND_URL || 'https://m3.cdnsolutionsgroup.com/OverZero';
const baseURL = process.env.REACT_APP_BACKEND_URL || 'https://api.overzero.com.au';

/**
 * Function to handle successful API responses
 * @param {Object} response - Axios response object
 * @returns {Object} - Parsed data from the response
 */
const handleResponse = (response) => {
  if (response.data && response.data.status === 200) {
    return response;
  } else {
    throw new Error(response.data ? response.data.message : 'Unknown error');
  }
};

/**
 * Function to handle API errors
 * @param {Object} error - Axios error object
 * @throws {Error} - Throws an error with the appropriate message
 */
const handleError = (error) => {
  if (error.response) {
    throw new Error(error.response.data ? error.response.data.message : 'Unknown error');
  } else if (error.request) {
    throw new Error('No response received from the server');
  } else {
    throw new Error('Request failed before sending');
  }
};

// Create an instance of Axios with a base URL
const axiosInstance = axios.create({
  baseURL,
});

/**
 * Login API for admin
 * @param {string} url - API endpoint for login
 * @param {string} email - Admin email
 * @param {string} password - Admin password
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const LoginAdmin = async (url, email, password) => {
  return await axiosInstance.post(url, { email, password })
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
};

/**
 * API function for making a POST request
 * @param {string} url - API endpoint for the POST request
 * @param {Object} body - Request body
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const postApi = async (url, body) => {
  return await axiosInstance.post(url, body)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
};


/**
 * API function for making a PUT request with a request body
 * @param {string} url - API endpoint for the PUT request
 * @param {Object} body - Request body
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const updateApi = async (url, body) => {
  return await axiosInstance.put(url, body)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};

/**
 * API function for making a GET request
 * @param {string} url - API endpoint for the GET request
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const GetUsers = async (url) => {
  return await axiosInstance.get(url)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
}

/**
 * API function for updating a user with a PUT request and an ID
 * @param {string} url - API endpoint for the PUT request
 * @param {number} id - User ID
 * @param {Object} body - Request body
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const UpdateUser = async (url, id, body) => {
  return await axiosInstance.put(`${url}/${id}`, body)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};

/**
 * API function for making a PUT request without a specific parameter
 * @param {string} url - API endpoint for the PUT request
 * @param {Object} body - Request body
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const UpdateApi = async (url, body) => {
  return await axiosInstance.put(url, body)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};

/**
 * API function for making a GET request with query parameters
 * @param {string} url - API endpoint for the GET request
 * @param {Object} body - Query parameters
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const GetApiParam = async (url, body) => {
  return await axiosInstance.get(url, { params: body })
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};

/**
 * API function for making a DELETE request with query parameters
 * @param {string} url - API endpoint for the DELETE request
 * @param {Object} body - Query parameters
 * @returns {Object} - Parsed data from the response
 * @throws {Error} - Throws an error if the API request fails
 */
export const DeleteApi = async (url, body) => {
  return await axiosInstance.delete(url, { params: body })
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};

// Get Api without Query Param
export const GetApi = async (url) => {
  return axiosInstance.get(`${url}`).then((response) => {
    console.log('data',response.data)
    return response.data
  }).catch((error) => {
    return error
  })
}

/**
 * Fetch Google user information using the provided access token.
 * @param {string} url - The API endpoint for fetching Google user information.
 * @param {string} access_token - The Google access token for authentication.
 * @param {Object} headers - Additional headers to include in the request.
 * @returns {Promise<AxiosResponse|Error>} - A Promise that resolves with the API response or rejects with an error.
 */
export const GetApiwithHeader = async (url, headers) => {
  return  axiosInstance.get(`${url}`, { headers })
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}

export const postApiWithHeader = async (url, body,headers) => {
  return await axiosInstance.post(url, body, { headers })
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
};

export const DeleteApiwithHeader = async (url, headers) => {
  return await axiosInstance.delete(url, {headers})
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};

export const UpdateUserwithHeader = async (url, body, headers) => {
  return await axiosInstance.put(url, body, {headers})
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error;
    })
};





