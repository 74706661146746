// import React from 'react';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: true,
//       text: 'Over view graph',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [500, 300, 600, 200, 800, 400, 700],
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },

//   ],
// };

// function Graph() {
//   return <Bar options={options} data={data} />;
// }

// export default Graph;

import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CCol, CRow } from '@coreui/react';
import { height } from '@mui/system';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const commonOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },

};


const labels = ['Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat', 'Sun'];

const data1 = {
  labels,
  datasets: [
    {
        label: 'Dataset 1',
      data: [500, 300, 600, 200, 800, 400, 700],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

const data2 = {
  labels,
  datasets: [
    {
      label: 'Dataset 2',
      data: [200, 400, 700, 300, 500, 800, 600],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function Graph() {
  return (<>
   
    {/* <h4>Over view graph</h4> */}
    <CRow >
     <CCol>
      <Bar options={{ ...commonOptions, title: { display: true, text: 'Graph 1' } }} data={data1} />
      </CCol>
      <CCol>
      <Bar options={{ ...commonOptions, title: { display: true, text: 'Graph 2' } }} data={data2} />
      </CCol>
      </CRow>
      </>
  );
}

export default Graph;

