import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
  CFormLabel,
  CFormGroup, CLabel, CSelect,
  CFormCheck,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'

const NewBroadcast = () => {
  const [formData, setFormData] = useState({
    broadcastName: '',
    description: '',
    messageFormat: 'Plain Text', // Default value,
    fromAddress: '',
    recipients: 'All',
    subject: '',
    occurrence: 'Once',
    selectedDate: null,
    selectedFile: null,
  });
  console.log('formData',formData)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      selectedFile: file,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedDate: date,
    }));
  };
  return (
    <div >
      <CContainer>
        <CRow>
          <CCol md={6}>
            <CCardGroup>
              <CForm>
                <h4 >New Broadcast Message</h4>
                <CRow >
                  <CFormLabel htmlFor="staticEmail" className="col-form-label">Broadcast Name</CFormLabel>
                  <CCol sm={10}>
                    <CFormInput
                      type="text"
                      name="broadcastName"
                      value={formData.broadcastName}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CFormLabel htmlFor="inputPassword" className="col-form-label">Description</CFormLabel>
                  <CCol sm={10} >
                    <CFormInput
                      type="text"
                      id="inputPassword"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <div>
                  <h6 className="mt-3">Message Format</h6>
                  <CFormCheck
                    inline
                    type="radio"
                    name="messageFormat"
                    id="inlineCheckbox1"
                    value="Plain Text"
                    label="Plain Text"
                    checked={formData.messageFormat === 'Plain Text'}
                    onChange={handleChange}
                  />
                  <CFormCheck
                    inline
                    type="radio"
                    name="messageFormat"
                    id="inlineCheckbox2"
                    value="HTML"
                    label="HTML"
                    checked={formData.messageFormat === 'HTML'}
                    onChange={handleChange}
                  />
                </div>
                <CRow className="mt-3">
                  <CFormLabel htmlFor="staticEmail">From Address:</CFormLabel>
                  <CCol sm={10}>
                    <CFormInput
                      type="text"
                      name="fromAddress"
                      placeholder="Enter email address"
                      value={formData.fromAddress}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="mt-3">
                  <CFormLabel htmlFor="staticEmail">Recipients</CFormLabel>
                  <CDropdown style={{ width: '150px' }}>
                    <CDropdownToggle>{formData.recipients}</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem onClick={() => handleChange({ target: { name: 'recipients', value: 'All' } })}>
                        All
                      </CDropdownItem>
                      <CDropdownItem onClick={() => handleChange({ target: { name: 'recipients', value: 'Transport' } })}>
                        Transport
                      </CDropdownItem>
                      <CDropdownItem onClick={() => handleChange({ target: { name: 'recipients', value: 'Garage' } })}>
                        Garage
                      </CDropdownItem>
                      <CDropdownItem onClick={() => handleChange({ target: { name: 'recipients', value: 'Salon' } })}>
                        Salon
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </CRow>
                <CRow className="mt-3">
                  <CFormLabel htmlFor="staticEmail">Subject</CFormLabel>
                  <CCol sm={10}>
                    <CFormInput
                      type="text"
                      name="subject"
                      placeholder="Enter email address"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <div>
                  <h6 className="mt-3">Occurance</h6>
                  <CFormCheck
                    inline
                    type="radio"
                    name="occurrence"
                    id="inlineCheckbox1"
                    value="Once"
                    label="Once"
                    checked={formData.occurrence === 'Once'}
                    onChange={handleChange}
                  />
                  <CFormCheck
                    inline
                    type="radio"
                    name="occurrence"
                    id="inlineCheckbox2"
                    value="Daily"
                    label="Daily"
                    checked={formData.occurrence === 'Daily'}
                    onChange={handleChange}
                  />
                  <CFormCheck
                    inline
                    type="radio"
                    name="occurrence"
                    id="inlineCheckbox3"
                    value="Week"
                    label="Week"
                    checked={formData.occurrence === 'Week'}
                    onChange={handleChange}
                  />
                  <CFormCheck
                    inline
                    type="radio"
                    name="occurrence"
                    id="inlineCheckbox4"
                    value="Monthly"
                    label="Monthly"
                    checked={formData.occurrence === 'Monthly'}
                    onChange={handleChange}
                  />
                </div>

                <CRow className="mt-3">
                  <CFormLabel htmlFor="staticEmail">Select Date & Time</CFormLabel>
                  <CCol sm={10}>
                    <DatePicker
                      selected={formData.selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="yyyy/MM/dd HH:mm:ss"
                    />
                  </CCol>
                </CRow>
                {/* Use react-datepicker component here */}
                <div className='mt-3 mb-2'>
                  <CFormInput
                    type="file"
                    id="formFile"
                    // label="Default file input example"
                    onChange={handleFileChange}
                  />
                </div>
                {/* <p>Selected File: {selectedFile ? selectedFile.name : 'None'}</p> */}
              </CForm>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default NewBroadcast