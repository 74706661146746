import React, { useState, useEffect,useRef } from 'react'
import {
  CRow,
  CCol,
  CButton,
  CFormCheck,
  CForm,
  CFormInput,
  CFormSelect,
  CFormLabel,
  CImage,
  CCard,
  CCardTitle,
  CSpinner,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { postApiWithHeader, UpdateUserwithHeader } from 'src/Api/Api'
import { toast } from "react-toastify";
// import { userAttributes } from "../../share/localData";
import { splitInsurance,disInsurance, money, out, capital, nonAsset, revenue, expense, debitor, normalGst, moneyOut, creditor } from 'src/components/Images/Images';

const Add = (props) => {
  console.log('props',props)
  const isCap = useRef(false);
  const [selectVal, setSelectVal] = useState()
  const [errors, setErrors] = useState({});
  const accessToken = localStorage.getItem('token')
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  }
  // const { cmpID } = userAttributes();
  // const companyId = cmpID;
  const [formData, setFormData] = useState({
    codeName: '',
    entryType: 'In',
    categoryA: '',
    categoryB: 'Revenue',
    copyParameter: '',
    value: 'Debtor',
    gst: 'Normal',
    isAsset: false,
    categoryId: 0,
    codeId: 0,
    fringeBenifitTax: false,
    exportIncentiveProgram: false,
    isInsurance: false,
    bankTransactionCode: 0,
    xeroCode: 0,
    itemType: 'None',
    isItem: false,
    isBascode: false,
    isFinancialInstrument:false
  })
  

  Add.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.string,
    items: PropTypes.string,
    getCategory: PropTypes.string,
    clearFormData: PropTypes.bool,
  }

  const getCategory = props.getCategory
  const items = props.items
  const editValue = props.editValue
  


  useEffect(() => {
    if (editValue) {
      const isItemChecked = (editValue.itemType === 'Fixed' || editValue.itemType === 'Movable') || editValue.isInsurance;
      setFormData({
        codeName: editValue.codeName,
        entryType: editValue.entryType,
        categoryA: editValue?.isCapital === true ? "Capital" : "NonCapital",
        copyParameter: editValue.copyParam,
        isAsset: editValue.isAsset,
        categoryId: editValue.categoryId,
        categoryB: editValue.categoryB,
        value: editValue.value,
        gst: editValue.gst,
        fringeBenifitTax: editValue.fringeBenifitTax,
        exportIncentiveProgram: editValue.exportIncentiveProgram,
        isInsurance:isItemChecked ?  editValue?.isInsurance : false,
        bankTransactionCode: 0,
        xeroCode: 0,
        itemType: isItemChecked ? editValue.itemType : 'None',
        isItem: isItemChecked,
        isBascode: editValue.isBasCode,
        isFinancialInstrument:editValue.isFinancialInstrument
      })
      isCap.current = editValue?.isCapital
      setSelectVal({
        entryType: editValue.entryType,
        categoryB: editValue.categoryB,
        value: editValue.value,
        gst: editValue.gst,
      })
    } else {
      setFormData({
        ...formData,
      })
    }
  }, [editValue])

  useEffect(() => {
    if (props.clearFormData) {
      resetForm();
      props.handleClose();
    }
  }, [props.clearFormData]);

  const postData = {
    codeName: formData.codeName,
    entryType: formData.entryType,
    isAsset: formData.isAsset,
    categoryA: isCap.current === true ? "Capital" : "NonCapital",
    categoryB: formData.categoryB,
    gst: formData.gst,
    copyParam: formData ? formData.copyParameter : '',
    value: formData.value,
    description: 'indore vijay nagar',
    categoryId: JSON.parse(formData.categoryId),
    codeId: 0,
    bankTransactionCode: null,
    isInsurance: formData.isInsurance,
    itemType: formData.itemType,
    fringeBenifitTax: formData.fringeBenifitTax,
    exportIncentiveProgram: formData.exportIncentiveProgram,
    xeroCode: 0,
    isBasCode: formData.isBascode,
    isFinancialInstrument:formData.isFinancialInstrument
  }


  const handleChange = (event, fieldName) => {
    let value
    if (fieldName === 'entryType') {
      value = event.target.value === 'In' ? 'In' : 'Out'
    }
    else if (fieldName === 'isBascode') {
      value = event.target.checked;
    }
    else if (fieldName === 'isFinancialInstrument') {
      value = event.target.checked;
    }else if (fieldName === "categoryA") {
      value = event.target.checked;
      isCap.current = value;
    }
    else if (fieldName === 'isAsset') {
      value = event.target.checked;
      formData.itemType = value ? "Fixed" : "None";
    } else if (fieldName === 'isItem') {
      value = event.target.checked;
      if (!value) {
        setFormData({
            ...formData,
            itemType:'None',
            isInsurance: false
        });
    }
    }
    else if (fieldName === 'itemType') {
      value = event.target.value === 'Fixed' ? 'Fixed' : 'Movable'
    }
    else if (fieldName === 'isBascode') {
      value = event.target.checked;
    }
    else if (fieldName === 'categoryB') {
      value = event.target.value === 'Revenue' ? 'Revenue' : 'Expense'
    } else if (fieldName === 'gst') {
      value =
        event.target.value === 'Normal'
          ? 'Normal'
          : event.target.value === 'Exempted'
            ? 'Exempted'
            : event.target.value === 'Free' ? 'Free' : event.target.value === 'FreeExport' ? 'FreeExport' :
              'InputTax'
    } else if (fieldName === 'value') {
      value = event.target.value === 'Debtor' ? 'Debtor' : 'Creditor'
    } else if (fieldName === 'fringeBenifitTax') {
      value = event.target.checked
    } else if (fieldName === 'exportIncentiveProgram') {
      value = event.target.checked
    } else if (fieldName === 'isInsurance') {
      value = event.target.checked
    }
    else {
      value = event.target.value
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
    setErrors({
      ...errors,
      [fieldName]: ''
    });
    setErrorMessage('');
  }

  const handleSelectChange = (e) => {
    const selectedOption = items?.find((role) => role?.codeName === e.target.value)
    setSelectVal(selectedOption)
    isCap.current = selectedOption?.isCapital;
    setFormData({
      ...formData,
      entryType: selectedOption?.entryType || 'In',
      copyParameter: selectedOption?.codeName || '',
      categoryA: selectedOption?.categoryA,
      value: selectedOption?.value || "Debtor",
      gst: selectedOption?.gst || 'Normal',
      categoryB: selectedOption?.categoryB || "Revenue",
      itemType: formData.isItem ? formData.itemType : '',
      isFinancialInstrument : selectedOption?.isFinancialInstrument,
      isBascode : selectedOption?.isBasCode,
      isAsset : selectedOption?.isAsset,
      isInsurance : selectedOption?.isInsurance,
      exportIncentiveProgram : selectedOption?.exportIncentiveProgram,
      fringeBenifitTax : selectedOption?.fringeBenifitTax
    })
  }

  const handleAdd = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const validationErrors = {};
    if (!formData.codeName.trim()) {
      validationErrors.codeName = 'Code Name is required';
    }
    //  else if (/\s/.test(formData.codeName)) {
    //   validationErrors.codeName = 'Code Name cannot contain spaces';
    // }
    
 
    if (!formData.categoryId) {
      // validationErrors.categoryId = 'Category is required';
      toast.error("Please select group listing",{autoClose:1000})
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    console.log('postData',postData)
    // return
    try {
      const apiUrl = `/api/code/create`
      const response = await (editValue
        ? UpdateUserwithHeader(`/api/code/update/${editValue.id}`, postData, headers)
        : postApiWithHeader(apiUrl, postData, headers))

      if (editValue ? response.message == "Success" : response.data.message == "Success") {
        setIsLoading(false)
        resetForm();
        props.apiCall()
        props.handleClose()
        toast.success(`${editValue ? 'Updated' : 'Added'} successfully`, { autoClose: 1000 });
      } else {
        console.error('Unexpected response format:', response)
      }
    } catch {
      console.log('error')
    }finally {
      setIsLoading(false);
    }
  }

  const resetForm = () => {
    setFormData({
      codeName: editValue ? editValue.codeName : '',
      entryType: editValue ? editValue.entryType : 'In',
      categoryA: editValue ? editValue.categoryA : '',
      copyParameter: editValue ? editValue.copyParam : '',
      isAsset: editValue ? editValue.isAsset : false,
      categoryId: editValue ? editValue.categoryId : 0,
      categoryB: editValue ? editValue.categoryB : 'Revenue',
      value: editValue ? editValue.value : 'Debtor',
      gst: editValue ? editValue.gst : 'Normal',
      fringeBenifitTax: editValue ? editValue.fringeBenifitTax : false,
      exportIncentiveProgram: editValue ? editValue.exportIncentiveProgram : false,
      // itemType: editValue ? editValue.itemType : 'None',
    });
    setErrors({})
    setErrorMessage('');
  }

  const handleItemClick = (itemId) => {
    // if (viewValue ? viewValue : !editValue) {
      setErrors({ ...errors, categoryId: '' });
      setFormData({ ...formData, categoryId: itemId });
    // }
  };
  return (
    <div className=''>
      <CRow className="mb-2">
        <CCol>
          <CForm>
            <CFormInput
              type="text"
              label={
                <>
                  Code <span className="text-danger">*</span>
                </>
              }
              placeholder="Enter Code"
              aria-describedby="exampleFormControlInputHelpInline"
              value={formData.codeName}
              onChange={(event) => handleChange(event, 'codeName')}
              className={`mb-2 ${errors.codeName ? 'is-invalid' : ''}`}
            />
            {errors.codeName && <div className="invalid-feedback">{errors.codeName}</div>}
            <div className="mb-3">
              <CFormSelect
                value={formData.copyParameter}
                // defaultValue={formData?.copyParameter ? formData?.copyParameter:''}
                onChange={(e) => handleSelectChange(e)}
                label="Copy Parameter"
              >
               <option value="">Select Parameter</option>
                {items
                ?.sort((a, b) => a.codeName.localeCompare(b.codeName))
                .map((role, index) => (
                  (editValue && editValue.codeName === role.codeName) ? null :
                    <option key={index} value={role?.codeName}>
                      {role?.codeName}
                    </option>
                ))}
              </CFormSelect>
            </div>
            <CFormCheck
              id="flexCheckDefault7565"
              label="Is it nowBAS?"
              checked={formData.isBascode}
              onChange={(event) => handleChange(event, 'isBascode')}
            />
              <CFormCheck
              id="flexCheckDefault7566"
              label="Is it financial instrument?"
              checked={formData?.isFinancialInstrument}
              onChange={(event) => handleChange(event, 'isFinancialInstrument')}
            />
            <div className="box">
              <CFormLabel>Entry Type </CFormLabel>
              <div className="">
                <CFormCheck
                  inline
                  type="radio"
                  name="inlineRadioOptions6"
                  id="inlineCheckbox11"
                  value="In"
                  label={<><span>In</span> <span className="icon-moneyin"></span></>}
                  checked={formData.entryType === 'In'}
                  defaultChecked={
                    selectVal && selectVal?.entryType === 'In'
                      ? selectVal?.entryType
                      : formData.entryType === 'In'
                  }
                  onChange={
                    (event) => handleChange(event, 'entryType')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="inlineRadioOptions12"
                  id="inlineCheckbox2"
                  value="Out"
                  // label="Out"
                  label={<><span>Out</span> <span className="icon-moneyout"></span></>}
                  checked={formData.entryType === 'Out'}
                  onChange={(event) => handleChange(event, 'entryType')}
                  defaultChecked={
                    selectVal && selectVal?.entryType === 'Out'
                      ? selectVal?.entryType
                      : formData.entryType === 'Out'

                  }
                />
                <hr></hr>
              </div>

              <CFormLabel>Category Type A</CFormLabel>
              {/* <CFormCheck
                // className="mb-2"
                id="flexCheckDefault"
                // label="Is it a capital?"
                label={<><span>Is it a capital?</span> <CImage src={capital} style={{width:"17px", height:"17px"}} alt="In icon" /></>}
                onChange={(event) => handleChange(event, 'isAsset')}
                checked={formData.isAsset}
              /> */}
              <CFormCheck
                id="flexCheckDefault"
                label={
                  <>
                    <span>Is it a capital?</span>{" "}
                    <CImage
                      src={capital}
                      style={{ width: "17px", height: "17px" }}
                      alt="In icon"
                    />
                  </>
                }
                onChange={(event) => handleChange(event, "categoryA")}
                // checked={ selectVal && selectVal?.isCapital
                //   ? selectVal?.isCapital
                //   : isCap.current}
                checked={isCap.current}
                // disabled={viewValue ? false : !!editValue}
              />
              {/* <CFormCheck
                // className="mb-2"
                id="flexCheckDefault097"
                // label="Is it a plant item/vehicle?"
                label={<><span>Is it a plant item/vehicle?</span> <CImage src={nonAsset} style={{width:"17px", height:"17px"}} alt="In icon" /></>}
                onChange={(event) => handleChange(event, 'isItem')}
                checked={formData.isItem}
              /> */}
               <CFormCheck
                id="flexCheckDefault097"
                label={
                  <>
                    <span>Is it a plant item/vehicle?</span>{" "}
                    <CImage
                      src={nonAsset}
                      style={{ width: "17px", height: "17px" }}
                      alt="In icon"
                    />
                  </>
                }
                onChange={(event) => handleChange(event, "isAsset")}
                checked={
                  selectVal && selectVal?.isAsset
                    ? selectVal?.isAsset
                    : formData.isAsset
                }
                // disabled={viewValue ? false : !!editValue}
              />
              {(formData.isAsset || selectVal?.isAsset) &&
                <>
                  <CFormLabel>Is the item:</CFormLabel>
                  <div className="mb-2">
                    <CFormCheck
                      inline
                      type="radio"
                      name="itemType"
                      id="inlineCheckbox111"
                      value="Fixed"
                      label="Fixed"
                      // checked={formData.itemType === 'Fixed'}
                      checked={
                        selectVal && selectVal?.itemType === "Fixed"
                          ? selectVal?.itemType
                          : formData.itemType === "Fixed"
                      }
                      defaultChecked={
                        selectVal && selectVal?.itemType === 'Fixed'
                          ? selectVal?.itemType
                          : formData.itemType === 'Fixed'
                      }
                      onChange={(event) => handleChange(event, 'itemType')}
                    // disabled={!formData.isItem}
                    />
                    <CFormCheck
                      inline
                      type="radio"
                      name="itemType"
                      id="inlineCheckbox1121"
                      value="Movable"
                      label="Movable"
                      // checked={formData.itemType === 'Movable'}
                      checked={
                        selectVal && selectVal?.itemType === "Movable"
                          ? selectVal?.itemType
                          : formData.itemType === "Movable"
                      }
                      defaultChecked={
                        selectVal && selectVal?.itemType === 'Movable'
                          ? selectVal?.itemType
                          : formData.itemType === 'Movable'
                      }
                      onChange={(event) => handleChange(event, 'itemType')}
                    // disabled={!formData.isItem}
                    />
                    {/* <CFormCheck
                      className="mb-2"
                      id="flexCheckDefault6"
                      // label="Is insurance required?"
                      label={
                        <div>
                          Is insurance required?
                          <img src={itim} alt="Insurance Icon" style={{ marginLeft: '5px' }} />
                        </div>}
                      onChange={(event) => handleChange(event, 'isInsurance')}
                      checked={formData.isInsurance}
                    /> */}
                    <CFormCheck
                      className="mb-2"
                      id="flexCheckDefault6"
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginRight: '5px' }}>Is insurance required?</span>
                          { formData.isInsurance ||
                            (selectVal && selectVal?.isInsurance) ?
                          <CImage src={splitInsurance} alt="Insurance Icon" style={{ marginLeft: '30px', width: '23px', height: '23px' }} />
                      :  <CImage src={disInsurance} alt="Insurance Icon" style={{ marginLeft: '30px', width: '23px', height: '23px' }} />}
                        </div>
                      }
                      onChange={(event) => handleChange(event, 'isInsurance')}
                      // checked={formData.isItem ? formData.isInsurance : false}
                      checked={
                        selectVal && selectVal?.isInsurance
                          ? selectVal?.isInsurance
                          : formData.isInsurance
                      }
                    />
                  </div>
                </>}
            </div>

            <div className="box">
              <CFormLabel>Category Type B</CFormLabel>
              <div >
                <CFormCheck
                  inline
                  type="radio"
                  name="categoryB"
                  id="inlineCheckbox50"
                  value="Revenue"
                  // label="Revenue"
                  label={<><span>Revenue</span> <CImage src={revenue} style={{width:"17px", height:"17px"}} alt="In icon" /></>}
                  checked={formData.categoryB === 'Revenue'}
                  defaultChecked={
                    selectVal && selectVal?.categoryB === 'Revenue'
                      ? selectVal?.categoryB
                      : formData.categoryB === 'Revenue'
                  }
                  onChange={(event) => handleChange(event, 'categoryB')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="categoryB"
                  id="inlineCheckbox60"
                  value="Expense"
                  // label="Expense"
                  label={<><span>Expense</span> <CImage src= {expense} style={{width:"17px", height:"17px"}} alt="In icon" /></>}
                  checked={formData.categoryB === 'Expense'}
                  defaultChecked={
                    selectVal && selectVal?.categoryB === 'Expense'
                      ? selectVal?.categoryB
                      : formData.categoryB === 'Expense'
                  }
                  onChange={(event) => handleChange(event, 'categoryB')}
                />
              </div>
              <CFormLabel>Category Type C</CFormLabel>
              <div>
                <CFormCheck
                  inline
                  type="radio"
                  name="value"
                  id="inlineCheckbox70"
                  value="Debtor"
                  // label="Debitor"
                  label={<><span>Debtor</span> <CImage src= {debitor} style={{width:"17px", height:"17px"}} alt="In icon" /></>}
                  checked={formData.value === 'Debtor'}
                  defaultChecked={
                    selectVal && selectVal?.value === 'Debtor'
                      ? selectVal?.value
                      : formData.value === 'Debtor'
                  }
                  onChange={(event) => handleChange(event, 'value')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="value"
                  id="inlineCheckbox80"
                  value="Creditor"
                  // label="Creditor"
                  label={<><span>Creditor</span> <CImage src={creditor} style={{width:"17px", height:"17px"}} alt="In icon" /></>}
                  checked={formData.value === 'Creditor'}
                  defaultChecked={
                    selectVal && selectVal?.value === 'Creditor'
                      ? selectVal?.value
                      : formData.value === 'Creditor'
                  }
                  onChange={(event) => handleChange(event, 'value')}
                />
                {/* <CFormCheck
                inline
                type="radio"
                name="value"
                value="None"
                style={{ display: 'none' }}
                defaultChecked={
                  selectVal && selectVal?.value === 'None'
                    ? formData.value = selectVal?.value
                    : formData.value === 'None'
                }
                onChange={(event) => handleChange(event, 'value')}
              /> */}
              </div>
              <CFormLabel><><span>GST</span> <CImage src= {normalGst} style={{width:"17px", height:"17px"}} alt="In icon" /></></CFormLabel>
              <div>
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox90"
                  value="Normal"
                  label="Normal"
                  checked={formData.gst === 'Normal'}
                  defaultChecked={
                    selectVal && selectVal?.gst === 'Normal'
                      ? selectVal?.gst
                      : formData.gst === 'Normal'
                  }
                  onChange={(event) => handleChange(event, 'gst')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox100"
                  value="Exempted"
                  label="Exempted"
                  checked={formData.gst === 'Exempted'}
                  defaultChecked={
                    selectVal && selectVal?.gst === 'Exempted'
                      ? selectVal?.gst
                      : formData.gst === 'Exempted'
                  }
                  onChange={(event) => handleChange(event, 'gst')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox110"
                  value="Free"
                  label="Free"
                  checked={formData.gst === 'Free'}
                  defaultChecked={
                    selectVal && selectVal?.gst === 'Free'
                      ? selectVal?.gst
                      : formData.gst === 'Free'
                  }
                  onChange={(event) => handleChange(event, 'gst')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox120"
                  value="FreeExport"
                  label="Free Export"
                  checked={formData.gst === 'FreeExport'}
                  defaultChecked={
                    selectVal && selectVal?.gst === 'FreeExport'
                      ? selectVal?.gst
                      : formData.gst === 'FreeExport'
                  }
                  onChange={(event) => handleChange(event, 'gst')}
                />
                <CFormCheck
                  inline
                  type="radio"
                  name="Normal"
                  id="inlineCheckbox130"
                  value="InputTax"
                  label="Input Tax"
                  checked={formData.gst === 'InputTax'}
                  defaultChecked={
                    selectVal && selectVal?.gst === 'InputTax'
                      ? selectVal?.gst
                      : formData.gst === 'InputTax'
                  }
                  onChange={(event) => handleChange(event, 'gst')}
                />
              </div>
            </div>
            <div className='box'>
              <CFormLabel>Will these amounts involve the following?</CFormLabel>
              <CFormCheck
                className="mb-2"
                id="flexCheckDefault7"
                label="Fringe Benefit Tax"
                onChange={(event) => handleChange(event, 'fringeBenifitTax')}
                checked={formData.fringeBenifitTax}
              />
              <CFormCheck
                className="mb-2"
                id="flexCheckDefault8"
                label="Export Incentive Program"
                onChange={(event) => handleChange(event, 'exportIncentiveProgram')}
                checked={formData.exportIncentiveProgram}
              />
            </div>

            <div className="box" >
              <CRow>
                <CCol md={12}>
                  <CFormLabel>Group Listing <span style={{ color: "red" }}>*</span></CFormLabel>
                  <div className="d-flex flex-wrap menuiconHover">
                    {getCategory?.map((item) => (
                      <div
                        key={item.id}
                        // style={{ cursor: 'pointer', marginRight: '10px', textAlign: 'center' }}
                        // onClick={() => {
                        //   setErrors({ ...errors, categoryId: '' });
                        //   setFormData({ ...formData, categoryId: item.id });
                        // }}
                        onClick={() => handleItemClick(item.id)}
                      >
                        <CCard className={formData.categoryId === item.id ? "border border-success border-3" : ""} >
                          <CImage src={item.logo} width="72px" ></CImage>
                          <CCardTitle component="p" className="m-0" >
                            {item.categoryName}
                          </CCardTitle>
                        </CCard>
                      </div>
                    ))}
                  </div>
               
                </CCol>
              </CRow>
              {errors.categoryId && <div className="invalid-feedback">{errors.categoryId}</div>}
            </div>
            
          </CForm>

          <CRow className="mt-4 justify-content-end">
            <CCol xs="auto">
              <CButton color="light" onClick={() => {
                resetForm()
                props.handleClose()
              }}>
                Cancel
              </CButton>
            </CCol>
            <CCol xs="auto">
              <CButton color="primary" onClick={(e) => handleAdd(e)} style={{ textTransform: 'none' }}>
              {isLoading ? <CSpinner size="sm" /> : 'Save'}
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div >
  )
}

export default Add

// import React, { useState, useEffect } from 'react'
// import {
//   CRow,
//   CCol,
//   CButton,
//   CFormCheck,
//   CForm,
//   CFormInput,
//   CFormSelect,
//   CFormLabel,
// } from '@coreui/react'
// import PropTypes from 'prop-types'
// import { postApiWithHeader, UpdateUserwithHeader } from 'src/Api/Api'
// import { toast } from "react-toastify";
// import { edit } from 'src/components/Images/Images';
// // import { GetApiwithHeader } from 'src/Api/Api'



// const Add = (props) => {

//   const [selectVal, setSelectVal] = useState()
//   const [errors, setErrors] = useState({});
//   const accessToken = localStorage.getItem('token')
//   const headers = {
//     Authorization: `Bearer ${accessToken}`,
//   }

//   const [formData, setFormData] = useState({
//     codeName: '',
//     entryType: 'In',
//     categoryA: 'Capital',
//     categoryB: 'None',
//     copyParameter: '',
//     value: 'None',
//     gst: 'None',
//     isAsset: false,
//     categoryId: 0,
//     codeId: 0,
//   })

//   Add.propTypes = {
//     apiCall: PropTypes.func.isRequired,
//     handleClose: PropTypes.func.isRequired,
//     editValue: PropTypes.string,
//     items: PropTypes.string,
//     getCategory: PropTypes.string,
//     clearFormData: PropTypes.bool,
//   }

//   const getCategory = props.getCategory
//   const items = props.items
//   const editValue = props.editValue


//   useEffect(() => {
//     if (editValue) {
//       setFormData({
//         codeName: editValue.codeName,
//         entryType: editValue.entryType,
//         categoryA: editValue.categoryA,
//         copyParameter: editValue.copyParam,
//         isAsset: editValue.isAsset,
//         categoryId: editValue.categoryId,
//         categoryB: editValue.categoryB,
//         value: editValue.value,
//         gst: editValue.gst,
//       })
//       setSelectVal({
//         categoryB: editValue.categoryB,
//         value: editValue.value,
//         gst: editValue.gst,
//       })
//     } else {
//       setFormData({
//         ...formData,
//       })
//     }
//   }, [editValue])

//   useEffect(() => {
//     if (props.clearFormData) {
//       resetForm();
//       props.handleClose();
//     }
//   }, [props.clearFormData]);

//   const postData = {
//     codeName: formData.codeName,
//     entrytype: formData.entryType,
//     categoryA: formData.categoryA,
//     isAsset: formData.isAsset,
//     categoryB: formData.categoryB,
//     value: formData.value,
//     gst: formData.gst,
//     copyParam: formData ? formData.copyParameter : '',
//     description: 'indore vijay nagar',
//     categoryId: JSON.parse(formData.categoryId),
//     codeId: 0,
//   }

//   const handleChange = (event, fieldName) => {
//     let value
//     if (fieldName === 'entryType') {
//       value = event.target.value === 'In' ? 'In' : 'Out'
//     } else if (fieldName === 'categoryA') {
//       value = event.target.value === 'Capital' ? 'Capital' : 'NonCapital'
//     } else if (fieldName === 'isAsset') {
//       value = event.target.checked
//     } else if (fieldName === 'categoryB') {
//       value = event.target.value === 'Revenue' ? 'Revenue' : 'Expense'
//     } else if (fieldName === 'gst') {
//       value =
//         event.target.value === 'Normal'
//           ? 'Normal'
//           : event.target.value === 'Exempted'
//             ? 'Exempted'
//             : 'NonStandard'
//     } else if (fieldName === 'value') {
//       value = event.target.value === 'Debitor' ? 'Debitor' : 'Creditor'
//     } else {
//       value = event.target.value
//     }

//     setFormData({
//       ...formData,
//       [fieldName]: value,
//     })
//     setErrors({
//       ...errors,
//       [fieldName]: ''
//     });
//   }

//   const handleSelectChange = (e) => {
//     const selectedOption = items?.find((role) => role?.codeName === e.target.value)
//     setSelectVal(selectedOption)

//     setFormData({
//       ...formData,
//       copyParameter: selectedOption?.codeName,
//       categoryA: selectedOption.categoryA,
//       value: selectedOption.value,
//       gst: selectedOption.gst,
//       categoryB: selectedOption.categoryB,
//     })
//   }

//   const handleAdd = async (e) => {
//     e.preventDefault();

//     // const validationErrors = {};
//     // if (!formData.codeName.trim()) {
//     //   validationErrors.codeName = 'Code Name is required';
//     // }else if (/\s/.test(formData.codeName)) {
//     //   validationErrors.codeName = 'Code Name cannot contain spaces';
//     // }

//     const validationErrors = {};
//     if (!formData.codeName.trim()) {
//       validationErrors.codeName = 'Code name is required';
//     }

//     if (!formData.categoryId) {
//       validationErrors.categoryId = 'Category is required';
//     }
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
    
//     try {
//       const apiUrl = editValue ? `/api/code/update/${editValue.id}` : '/api/code/create'
//       const response = await (editValue
//         ? UpdateUserwithHeader(apiUrl, postData, headers)
//         : postApiWithHeader(apiUrl, postData, headers))

//       if (editValue ? response.message == "Success" : response.data.message == "Success") {
//         resetForm()
//         props.apiCall()
//         props.handleClose()
//         toast.success(`${editValue ? 'Updated' : 'Added'} successfully`, { autoClose: 1000 });

//       } else {
//         console.error('Unexpected response format:', response)
//       }
//     } catch {
//       console.log('error')
//     }
//   }

//   const resetForm = () => {
//     // console.log('editValue', editValue)
//     setFormData({
//       codeName: editValue ? editValue.codeName : '',
//       entryType: editValue ? editValue.entryType : 'In',
//       categoryA: editValue ? editValue.categoryA : 'Capital',
//       copyParameter: editValue ? editValue.copyParam : '',
//       isAsset: editValue ? editValue.isAsset : false,
//       categoryId: editValue ? editValue.categoryId : 0,
//       categoryB: editValue ? editValue.categoryB : 'None',
//       value: editValue ? editValue.value : 'None',
//       gst: editValue ? editValue.gst : 'None',
//     });
//     setErrors({})
//   }


//   return (
//     <div>
//       <CRow className="mb-2">
//         <CCol>
//           <CForm>
//             <CFormInput
//               type="text"
//               label={
//                 <>
//                    Code <span className="text-danger">*</span>
//                 </>
//               }
//               placeholder="Enter code"
//               aria-describedby="exampleFormControlInputHelpInline"
//               value={formData.codeName}
//               onChange={(event) => handleChange(event, 'codeName')}
//               className={`mb-2 ${errors.codeName ? 'is-invalid' : ''}`}

//             />
//             {errors.codeName && <div className="invalid-feedback">{errors.codeName}</div>}
//             <div className="mb-3">
//             <CFormSelect
//               value={formData.copyParameter}
//               // defaultValue={formData?.copyParameter ? formData?.copyParameter:''}
//               onChange={(e) => handleSelectChange(e)}
//               label="Copy parameter"
//             >
//               <option value="">Select Parameter</option>
//               {items?.map((role) => (
//                 <option key={role?.codeName} value={role?.codeName}>
//                   {role?.codeName}
//                 </option>
//               ))}
//             </CFormSelect>
//           </div>
          
//             <CRow>
//               <CCol md={12}>
//                 <CFormSelect
//                   label={
//                     <>
//                        Category <span className="text-danger">*</span>
//                     </>
//                   }
//                   placeholder="Select category"
//                   value={formData?.categoryId}
//                   onChange={(e) => {
//                     // Clear the error when a selection is made
//                     setErrors({ ...errors, categoryId: '' });
//                     setFormData({ ...formData, categoryId: e.target.value });
//                   }}
                  
//                 className={`mb-2 ${errors.categoryId ? 'is-invalid' : ''}`}
//                 >

//                 <option value="">Select Category</option>
//                 {getCategory?.map((item) => (
//                   <option key={item.id} value={item.id}>
//                     {item.categoryName}
//                   </option>
//                 ))}

//               </CFormSelect>
//               {errors.categoryId && <div className="invalid-feedback">{errors.categoryId}</div>}
//               {/* <CFormSelect
//                   label="Category"
//                   placeholder="Select Category"
//                   value={editValue ? editValue.categoryId : formData.categoryId}

//                   onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
//                 >
//                   {console.log('editValue', editValue)}
//                   <option value="">Select Category</option>
//                   {getCategory?.map((item) => (
//                     <option key={item.id} value={item.id}>
//                       {item.categoryName}
//                     </option>
//                   ))}
//                 </CFormSelect> */}
//             </CCol>
//           </CRow>
//           <CFormLabel>Entry Type </CFormLabel>
//           <div className="mb-2">
//             <CFormCheck
//               inline
//               type="radio"
//               name="inlineRadioOptions6"
//               id="inlineCheckbox11"
//               value="In"
//               label="In"
//               defaultChecked={formData.entryType === 'In'} // Use defaultChecked for initial state
//               onChange={
//                 (event) => handleChange(event, 'entryType')}
//             />
//             <CFormCheck
//               inline
//               type="radio"
//               name="inlineRadioOptions12"
//               id="inlineCheckbox2"
//               value="Out"
//               label="Out"
//               checked={formData.entryType === 'Out'}
//               onChange={(event) => handleChange(event, 'entryType')}
//             />
//           </div>
       

//           <CFormCheck
//             className="mb-3"
//             id="flexCheckDefault"
//             label="Is it an asset?"
//             onChange={(event) => handleChange(event, 'isAsset')}
//             checked={formData.isAsset}
//           />

//           <CFormLabel>Category Type A</CFormLabel>
//           <div className="mb-2">
//             <CFormCheck
//               inline
//               type="radio"
//               name="categoryA"
//               id="inlineCheckbox111"
//               value="Capital"
//               label="Capital"
//               checked={formData.categoryA === 'Capital'}
//               defaultChecked={
//                 selectVal && selectVal?.categoryA === 'Capital'
//                   ? selectVal?.categoryA
//                   : formData.categoryA === 'Capital'
//               }
//               onChange={(event) => handleChange(event, 'categoryA')}
//             />
//             <CFormCheck
//               inline
//               type="radio"
//               name="categoryA"
//               id="inlineCheckbox112"
//               value="NonCapital"
//               label="Non Capital"
//               checked={formData.categoryA === 'NonCapital'}
//               defaultChecked={
//                 selectVal && selectVal?.categoryA === 'NonCapital'
//                   ? selectVal?.categoryA
//                   : formData.categoryA === 'NonCapital'
//               }
//               onChange={(event) => handleChange(event, 'categoryA')}
//             />
//           </div>
//           <CFormLabel>Category Type B</CFormLabel>
//           <div className="mb-2">
//             <CFormCheck
//               inline
//               type="radio"
//               name="categoryB"
//               id="inlineCheckbox50"
//               value="Revenue"
//               label="Revenue"
//               checked={formData.categoryB === 'Revenue'}
//               defaultChecked={
//                 selectVal && selectVal?.categoryB === 'Revenue'
//                   ? selectVal?.categoryB
//                   : formData.categoryB === 'Revenue'
//               }
//               onChange={(event) => handleChange(event, 'categoryB')}
//             />
//             <CFormCheck
//               inline
//               type="radio"
//               name="categoryB"
//               id="inlineCheckbox60"
//               value="Expense"
//               label="Expense"
//               checked={formData.categoryB === 'Expense'}
//               defaultChecked={
//                 selectVal && selectVal?.categoryB === 'Expense'
//                   ? selectVal?.categoryB
//                   : formData.categoryB === 'Expense'
//               }
//               onChange={(event) => handleChange(event, 'categoryB')}
//             />
//           </div>
//           <CFormLabel>Value</CFormLabel>
//           <div>
//             <CFormCheck
//               inline
//               type="radio"
//               name="value"
//               id="inlineCheckbox70"
//               value="Debitor"
//               label="Debitor"
//               checked={formData.value === 'Debitor'}
//               defaultChecked={
//                 selectVal && selectVal?.value === 'Debitor'
//                   ? selectVal?.value
//                   : formData.value === 'Debitor'
//               }
//               onChange={(event) => handleChange(event, 'value')}
//             />
//             <CFormCheck
//               inline
//               type="radio"
//               name="value"
//               id="inlineCheckbox80"
//               value="Creditor"
//               label="Creditor"
//               checked={formData.value === 'Creditor'}
//               defaultChecked={
//                 selectVal && selectVal?.value === 'Creditor'
//                   ? selectVal?.value
//                   : formData.value === 'Creditor'
//               }
//               onChange={(event) => handleChange(event, 'value')}
//             />
//             {/* <CFormCheck
//                 inline
//                 type="radio"
//                 name="value"
//                 value="None"
//                 style={{ display: 'none' }}
//                 defaultChecked={
//                   selectVal && selectVal?.value === 'None'
//                     ? formData.value = selectVal?.value
//                     : formData.value === 'None'
//                 }
//                 onChange={(event) => handleChange(event, 'value')}
//               /> */}
//           </div>
//           <CFormLabel>GST</CFormLabel>
//           <div>
//             <CFormCheck
//               inline
//               type="radio"
//               name="Normal"
//               id="inlineCheckbox90"
//               value="Normal"
//               label="Normal"
//               checked={formData.gst === 'Normal'}
//               defaultChecked={
//                 selectVal && selectVal?.gst === 'Normal'
//                   ? selectVal?.gst
//                   : formData.gst === 'Normal'
//               }
//               onChange={(event) => handleChange(event, 'gst')}
//             />
//             <CFormCheck
//               inline
//               type="radio"
//               name="Normal"
//               id="inlineCheckbox100"
//               value="Exempted"
//               label="Exempted"
//               checked={formData.gst === 'Exempted'}
//               defaultChecked={
//                 selectVal && selectVal?.gst === 'Exempted'
//                   ? selectVal?.gst
//                   : formData.gst === 'Exempted'
//               }
//               onChange={(event) => handleChange(event, 'gst')}
//             />
//             <CFormCheck
//               inline
//               type="radio"
//               name="Normal"
//               id="inlineCheckbox110"
//               value="NonStandard"
//               label="NonStandard"
//               checked={formData.gst === 'NonStandard'}
//               defaultChecked={
//                 selectVal && selectVal?.gst === 'NonStandard'
//                   ? selectVal?.gst
//                   : formData.gst === 'NonStandard'
//               }
//               onChange={(event) => handleChange(event, 'gst')}
//             />
//           </div>
//         </CForm>

//         <CRow className="mt-4 justify-content-end">
//           <CCol xs="auto">
//             <CButton color="light" onClick={() => {
//               resetForm()
//               props.handleClose()
//             }}>
//               Cancel
//             </CButton>
//           </CCol>
//           <CCol xs="auto">
//             <CButton color="primary" onClick={(e) => handleAdd(e)} style={{ textTransform: 'none' }}>
//               Save
//             </CButton>
//           </CCol>
//         </CRow>
//       </CCol>
//     </CRow>
//     </div >
//   )
// }

// export default Add

