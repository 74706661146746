import React, { useMemo, useState,useEffect } from 'react'
import { CRow, CCol, CButton, CCardBody, CFormInput,CAlert,CFormLabel, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
import { UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api'
import { toast } from 'react-toastify'

const Add = (props) => {
  const accessToken = localStorage.getItem('token')
  const [formData, setFormData] = useState({ title: '' })
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken],
  )
  Add.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.string,
    clearFormData: PropTypes.bool,
  }
  const editVal = props.editValue
  const postData = {
    title: formData.title,
  }
 
  const resetForm = () => {
    setFormData({
      title: editVal ? editVal.title || '' : '',
    });
    setErrors({});
  };

  useEffect(() => {
    props.clearFormData ? resetForm() : (editVal && setFormData({ title:editVal.title || '' }));
    props.clearFormData && props.handleClose();
  }, [props.clearFormData, props.handleClose,editVal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Reset the corresponding error when the user starts typing
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const apiUrl = editVal ? `/api/industry/update/${editVal.id}` : '/api/industry/create'
    const method = editVal ? UpdateUserwithHeader : postApiWithHeader
    setIsLoading(true);
    const validationErrors = {};
    if (!formData.title.trim()) {
      validationErrors.title = 'Title is required';
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (!editVal) {
      try {
        const response = await method(apiUrl, postData, headers)
       
        if (response.data.statusCode === 100) {
          setIsLoading(false)
          props.apiCall()
          resetForm();
          props.handleClose()
          toast.success(`Added successfully`,{ autoClose: 1000 });
        }else if (response.data.statusCode === 102) {
          // Show toast for user already registered without closing the modal
          toast.error(response.data.message,{ autoClose: 1000 })
        }  else {
          console.error('Unexpected response format:', response)
        }
      }
       catch (error) {
        console.error('Error in POST request:', error)
      }
      finally {
        setIsLoading(false);
      }
    
    } else {
      try {
        const response = await UpdateUserwithHeader(
          `/api/industry/update/${editVal.id}`,
          postData,
          headers,
        )
     
        if (response.statusCode === 100) {
          setIsLoading(false)
          props.apiCall()
          resetForm();
          props.handleClose()
          toast.success(`Updated successfully`,{ autoClose: 1000 });
        }else if (response.statusCode === 102) {
          // Show toast for user already registered without closing the modal
          toast.error(response.message,{ autoClose: 1000 })
        } else {
          console.error('Unexpected response format:', response)
        }
      } catch (error) {
        console.error('Error in PUT request:', error)
      }finally {
        setIsLoading(false);
      }
    }
  }
// const handleUpdateClick = async (editIndex) => {
  //   try {
  //     const response = await UpdateUserwithHeader(`/api/industry/update/${editVal.id}`, postData, headers);
  //     if (response.data) {
  //       // const updatedIndustry = industry.map(entry =>
  //       //   entry.id === putData.id ? { ...entry, title: putData.title } : entry
  //       // );
  //       // setIndustry(updatedIndustry);
  //       // setFormData({ title: '' });
  //       // setIsModalVisible(false);
  //     } else {
  //       console.error("Unexpected response format:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error in PUT request:", error);
  //   }
  // };
 
  return (
    <CCardBody className="m-3">
      <CCol md={12}>
      <CFormLabel>Title<span className="text-danger">*</span></CFormLabel>
        <CFormInput
          type="text"
          name="title"
          placeholder='Enter title'
          value={formData.title}
          onChange={handleInputChange}
          className={errors.title ? 'is-invalid' : ''}
        />
          {errors.title && <div className="invalid-feedback">{errors.title}</div>}
      </CCol>
      <CRow className="mt-4 justify-content-end">
        <CCol xs="auto">
        <CButton color="light" onClick={() => {
            resetForm();
            props.handleClose();
          }}>
            Cancel
          </CButton>
        </CCol>
        <CCol xs="auto">
          <CButton color="primary" onClick={handleAdd} style={{ textTransform: 'none' }}>
          {isLoading ? <CSpinner size="sm" /> : 'Save'}
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  )
}

export default Add
 