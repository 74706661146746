import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableHead,
  CTableRow,
  CTooltip,
  CImage,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CCard,
  CCardBody,
} from '@coreui/react'
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css' // Import the styles
import { deleteIcon, edit } from 'src/components/Images/Images'
import OffCanvas from '../../../components/share/canvas'
import Add from './Add'
import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api'

const BroadCast = () => {
  const [editValue, setEditValue] = useState()
  const [items, setItems] = useState([])
  const [visible, setVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const accessToken = localStorage.getItem('token')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  }

  const fetchData = async () => {
    const apiUrl = '/api/code'
    try {
      const res = await GetApiwithHeader(apiUrl, headers)
      // console.log('res', res)
      setItems(res?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAddClick = () => {
    setEditValue()
    setVisible(true)
  }

  const handleCloseClick = () => {
    setVisible(false)
  }
  const handleEditClick = (i) => {
    setEditValue(i)
    setVisible(true)
  }

  const handleDelete = (i) => {
    setEditValue(i)
    setIsModalVisible(true)
  }

  const handleDeleteClick = async (id) => {
    const apiUrl = `/api/boardcast/delete/${id}`
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers)
      if (response.message === 'Success') {
        const updatedIndustry = items.filter((entry) => entry.id !== id)
        setItems(updatedIndustry)
      } else {
        console.error('Unexpected response format:', response)
      }
    } catch (error) {
      console.error('Delete API Request Error:', error)
    }
  }

  return (
    <div className="cardBody">
      {/* <h4>Broadcast Message </h4> */}
      <CCard>
        <CCardBody className="m-2">
        <div className="cardHeader">
          <h3>Broadcast</h3>
          <CButton style={{ marginLeft: '50px' }} onClick={() => handleAddClick()}>
            Add Boradcast
          </CButton>
          </div>
          <div className="cardTable">
          <CTable className="text-center">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">S.No</CTableHeaderCell>
                <CTableHeaderCell scope="col">Broadcast Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Recipients</CTableHeaderCell>
                <CTableHeaderCell scope="col">Occurance</CTableHeaderCell>
                <CTableHeaderCell scope="col">Date & Time</CTableHeaderCell>
                <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {items?.map((i, index) => (
                <CTableRow key={index}>
                  <CTableDataCell scope="row">
                    {i.id < 10 ? `0${i.id}` : i.id}
                  </CTableDataCell>
                  <CTableDataCell>{i.boardcast_name}</CTableDataCell>
                  <CTableDataCell>{i.recipeint}</CTableDataCell>
                  <CTableDataCell>{i.occurance}</CTableDataCell>
                  <CTableDataCell>{i.date_time}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    <CTooltip content="Edit">
                      <button className="editIcon">
                        <CImage
                          src={edit}
                          onClick={() => handleEditClick(i)}
                          width="20px"
                          height="20px"
                          style={{ marginRight: '10px' }}
                        />
                      </button>
                    </CTooltip>
                    <CTooltip content="Delete">
                      <button className="deleteIcon">
                        <CImage
                          src={deleteIcon}
                          onClick={() => handleDelete(i)}
                          width="20px"
                          height="20px"
                        />
                      </button>
                    </CTooltip>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
          </div>
        </CCardBody>
      </CCard>
      <OffCanvas
        Open={visible}
        title={editValue ? 'Edit Boradcast' : 'Add Boradcast'}
        handleCloseClick={handleCloseClick}
        component={
          <Add
            apiCall={fetchData}
            handleClose={handleCloseClick}
            editValue={editValue}
            items={items}
          />
        }
      />
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>Are you sure want to delete {editValue?.receiverName}...?</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
            Close
          </CButton>
          <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
            ok
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default BroadCast
