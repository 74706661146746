import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cilEnvelopeLetter,
  cilBookmark,
  cibGovUk,
  cilCode,
  cilBorderAll,
  cibGnuPrivacyGuard,
  cibLetsEncrypt,
  cilAudio,
  cilWc,
  cilLockLocked
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

export const admin = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Bussiness Owner',
    to: '/invitation',
    icon: <CIcon icon={cilEnvelopeLetter} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Promoters',
    to: '/promoters',
    icon: <CIcon icon={cilBookmark} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Code Management',
    to: '/codemanagement',
    icon: <CIcon icon={cilBorderAll} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Code Mapping',
    to: '/codingtemplate',
    icon: <CIcon icon={cilCode} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Broadcast Message',
  //   to: '/broadcast',
  //   icon: <CIcon icon={cilAudio} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Industry',
    to: '/industry',
    icon: <CIcon icon={cilAudio} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Privacy Policy',
    to: '/privacypolicy',
    icon: <CIcon icon={cibLetsEncrypt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Term',
    to: '/term',
    icon: <CIcon icon={cilWc} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Logout',
  //   to: '/',
  //   icon: <CIcon icon={cilLockLocked} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Logout',
  //   to: '/',
  //   icon: <CIcon icon={cilLockLocked} customClassName="nav-icon" />,
    
  // }
]

export const promoter = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Invitation',
    to: '/invitations',
    icon: <CIcon icon={cilEnvelopeLetter} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Branding',
    to: '/brandings',
    icon: <CIcon icon={cilAudio} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Privacy Policy',
    to: '/privacypolicy',
    icon: <CIcon icon={cibLetsEncrypt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Term',
    to: '/term',
    icon: <CIcon icon={cilWc} customClassName="nav-icon" />,
  }
]
