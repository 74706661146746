// import React, { useState, useMemo } from 'react';
// import { CForm, CFormLabel, CFormInput, CButton, CFormSelect, CRow, CCol } from '@coreui/react';
// import { UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
// import PropTypes from 'prop-types'
// import { toast } from 'react-toastify';

// const SetPassword = (props) => {
//   const accessToken = localStorage.getItem('token')
//   const [passwordsMatch, setPasswordsMatch] = useState(true);
//   const [errors, setErrors] = useState({})
//   const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken])
//   const [formData, setFormData] = useState({
//     currentPassword: '',
//     newPassword: '',
//     confirmNewPassword: '',
//   });

//   SetPassword.propTypes = {
//     passwordData: PropTypes.object,
//     onPasswordUpdate: PropTypes.func.isRequired,
//     // apiCall: PropTypes.func.isRequired,
//     handleClose: PropTypes.func.isRequired,
//     // clearFormData: PropTypes.bool,
//     // password:PropTypes.object,
//   }
//   const { passwordData, onPasswordUpdate, handleClose } = props

//   const PostData = {
//     email: passwordData.email,
//     currentPassword: formData.currentPassword,
//     newPassword: formData.newPassword
//   }


//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevPasswords) => ({ ...prevPasswords, [name]: value }));
//     setErrors({
//       ...errors,
//       [name]: ''
//     })
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = {};
//     if (!formData.currentPassword.trim()) {
//       validationErrors.currentPassword = 'Current password is required';
//     }
//     // if (formData.currentPassword !== passwordData.password) {
//     //   validationErrors.currentPassword = 'Incorrect current password';
//     // }
//     if (!formData.newPassword) {
//       validationErrors.newPassword = 'New Password is required';
//     }

//     if (!formData.confirmNewPassword.trim()) {
//       validationErrors.confirmNewPassword = 'Confirm password is required';
//     }

//     if (formData.newPassword !== formData.confirmNewPassword) {
//       validationErrors.confirmNewPassword = 'New password and Confirm new password do not match';
//     }

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return; // Do not proceed if there are validation errors
//     }

//     try {
//       const apiUrl = `/api/auth/resetpassword`
//       const response = await postApiWithHeader(apiUrl, PostData, headers)

//       // console.log('response', response)
//       if (response.data.statusCode == 100) {
//         onPasswordUpdate(formData.newPassword);
//         setPasswordsMatch(true);
//         handleClose();
//         toast.success(`Reset password successfully`, { autoClose: 1000 });
//       } else {
//         console.error('Unexpected response format:', response)
//       }
//     } catch {
//       console.log('error')
//     }
//   };
//   console.log('formDtaa', formData)

//   return (
//     <div>
//       <CForm onSubmit={handleSubmit}>
//         <div className="mb-3">
//           <CFormLabel>
//             Current password<span className="text-danger">*</span>
//           </CFormLabel>
//           <CFormInput
//             type="password"
//             name="currentPassword"
//             value={formData.currentPassword}
//             onChange={handleChange}
//             className={errors.currentPassword ? 'is-invalid' : ''}
//           />
//           {errors.currentPassword && <div className="invalid-feedback">{errors.currentPassword}</div>}
//         </div>

//         <div className="mb-3">
//           <CFormLabel>
//             New password<span className="text-danger">*</span>
//           </CFormLabel>
//           <CFormInput
//             type="password"
//             name="newPassword"
//             value={formData.newPassword}
//             onChange={handleChange}
//             className={errors.newPassword ? 'is-invalid' : ''}
//           />
//           {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
//         </div>

//         <div className="mb-3">
//           <CFormLabel>
//             Confirm new password<span className="text-danger">*</span>
//           </CFormLabel>
//           <CFormInput
//             type="password"
//             name="confirmNewPassword"
//             value={formData.confirmNewPassword}
//             onChange={handleChange}
//             className={errors.confirmNewPassword ? 'is-invalid' : ''}
//           />
//           {errors.confirmNewPassword && <div className="invalid-feedback">{errors.confirmNewPassword}</div>}
//         </div>

//         <CRow className="mt-4 justify-content-end">
//           <CCol xs="auto">
//             <CButton color="primary" type="submit">
//               Update password
//             </CButton>
//           </CCol>
//         </CRow>
//       </CForm>
//     </div>
//   );
// };

// export default SetPassword;

import React, { useState, useMemo } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CFormSelect, CRow, CCol } from '@coreui/react';
import { UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const SetPassword = (props) => {
  const accessToken = localStorage.getItem('token')
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errors, setErrors] = useState({})
  const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken])
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  SetPassword.propTypes = {
    passwordData: PropTypes.object,
    onPasswordUpdate: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  }
  const { passwordData, onPasswordUpdate, handleClose } = props

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New Password is required'),
    confirmNewPassword: Yup.string()
      .required('Confirm password is required')
  });

  const PostData = {
    email: passwordData.email,
    currentPassword: formData.currentPassword,
    newPassword: formData.newPassword
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevPasswords) => ({ ...prevPasswords, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  
    try {
      await validationSchema.validateAt(name, { [name]: value });
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      // Check if new password and confirm new password match
    if (formData.newPassword !== formData.confirmNewPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword: 'New password and Confirm new password do not match',
      }));
      return; 
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, confirmNewPassword: '' }));
    }

      const apiUrl = `/api/auth/resetpassword`;
      const response = await postApiWithHeader(apiUrl, PostData, headers);

      if (response.data.statusCode === 100) {
        onPasswordUpdate(formData.newPassword);
        setPasswordsMatch(true);
        handleClose();
        toast.success(`Reset password successfully`, { autoClose: 1000 });
      } else if (response.data.statusCode === 101) {
        // Old password is incorrect
        setErrors((prevErrors) => ({
          ...prevErrors,
          currentPassword: 'Please enter the correct old password.',
        }));
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Yup validation error occurred
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        // Other errors (e.g., API call error)
        console.error('Error:', error);
      }
    }

  };
  console.log('formDtaa', formData)

  return (
    <div>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel>
            Current password<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="password"
            name="currentPassword"
            placeholder='Enter current password'
            value={formData.currentPassword}
            onChange={handleChange}
            className={errors.currentPassword ? 'is-invalid' : ''}
          />
          {errors.currentPassword && <div className="invalid-feedback">{errors.currentPassword}</div>}
        </div>


        <div className="mb-3">
          <CFormLabel>
            New password<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="password"
            name="newPassword"
            placeholder='Enter new password'
            value={formData.newPassword}
            onChange={handleChange}
            className={errors.newPassword ? 'is-invalid' : ''}
          />
          {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
        </div>

        <div className="mb-3">
          <CFormLabel>
            Confirm new password<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="password"
            name="confirmNewPassword"
            placeholder='Enter confirm password'
            value={formData.confirmNewPassword}
            onChange={handleChange}
            className={errors.confirmNewPassword ? 'is-invalid' : ''}
          />
          {errors.confirmNewPassword && <div className="invalid-feedback">{errors.confirmNewPassword}</div>}
        </div>

        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton color="primary" type="submit">
              Update password
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </div>
  );
};

export default SetPassword;




