import React, { useMemo, useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CImage, CModal, CModalBody, CModalFooter, CModalHeader, CTooltip } from '@coreui/react';
import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api';
import OffCanvas from '../../../components/share/canvas';
import { deleteIcon, edit } from 'src/components/Images/Images';
import Add from './Add';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';

const Promoters = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  let companyId = 0;
  const [items, setItems] = useState([]);
  const [editValue, setEditValue] = useState();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const accessToken = localStorage.getItem('token');
  const [industry, setIndustry] = useState([]);
  const navigate = useNavigate();
  const [clearFormData, setClearFormData] = useState(false);
  const [sortModel, setSortModel] = useState([{ field: 'firstName', sort: 'asc' }]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Number of items per page
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingState, setLoadingState] = useState(true);

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken],
  );

  const filteredServices = useMemo(() => {
    return items.filter(
      (service) =>
        service.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.abnNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.userName.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [items, searchQuery]);

  const handleAddClick = () => {
    setEditValue();
    setVisible(true);
  };

  const handleCloseClick = () => {
    setEditValue();
    setClearFormData((prev) => !prev);
    setVisible(false);
  };

  const handleEditClick = (i) => {
    setEditValue(i);
    setVisible(true);
  };

  const handleDelete = (i) => {
    setEditValue(i);
    setIsModalVisible(true);
  };

  const handleBranding = (i) => {
    setEditValue(i);
    navigate('/brandings', { state: { editValue: i } });
  };

  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(`/api/auth/GetPromoter`, headers);
      setLoadingState(false);
      setItems(res?.data?.data);
    } catch (error) {
      setLoadingState(false);
      console.log(error);
    }
  };

  const fetchIndustry = async () => {
    const apiUrl = '/api/industry';
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      setIndustry(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchIndustry();
  }, [headers, companyId]);

  const handleDeleteClick = async (id) => {
    const apiUrl = `api/auth/DeletePromoter?id=${encodeURIComponent(editValue.id)}&companyId=${encodeURIComponent(
      companyId,
    )}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === 'Success') {
        setIsModalVisible(false);
        const updatedService = items.filter((entry) => entry.id !== id);
        setItems(updatedService);
        toast.success('Deleted Successfully', { autoClose: 1000 });
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Delete API Request Error:', error);
    }
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const columns = [
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      // align: "center",
      // headerAlign: "center",
      headerClassName: 'dataGridHeader'
    },
    { field: 'firstName', width: 150, headerName: 'Contact Name', headerClassName: 'dataGridHeader' },
    { field: 'phoneNumber', width: 150, headerName: 'Mobile', headerClassName: 'dataGridHeader' },
    { field: 'companyName', width: 200, headerName: 'Company Name', headerClassName: 'dataGridHeader' },
    { field: 'abnNumber', width: 150, headerName: 'ABN', headerClassName: 'dataGridHeader' },
    { field: 'userName', width: 250, headerName: 'Email ID', headerClassName: 'dataGridHeader' },
    {
      field: 'actions',
      headerName: 'Action',
      width: 180,
      headerClassName: 'dataGridHeader',
      renderCell: (params) => (
        <div className='rowIcon'>
          {/* <CTooltip title="Branding">
            <button onClick={() => handleBranding(params.row)}>Branding</button>
          </CTooltip> */}
          <CTooltip content="Branding">
            <CButton onClick={() => handleBranding(params.row)}>
              Branding
            </CButton>
          </CTooltip>
          <CTooltip content="Edit">
            <button className="icon-editIcon"  onClick={() => handleEditClick(params.row)}>
              {/* <CImage
                src={edit}
                onClick={() => handleEditClick(params.row)}
              /> */}
            </button>
          </CTooltip>
          {/* Uncomment if you have delete functionality */}
          {/* <Tooltip title="Delete">
            <Button onClick={() => handleDelete(params.row)}>
              <img src={deleteIcon} alt="Delete" className="deleteIcon" />
            </Button>
          </Tooltip> */}
        </div>
      ),
    },
  ];


  return (
    <>
      <div className="cardBody">
        <ToastContainer position="top-right" />
        <CCard>
          <CCardBody>
            <div className="cardHeader">
              <h3>Registered promoters list</h3>
              <div className="mr-1">
                <input
                  type="text"
                  className="mr-2"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CButton onClick={() => handleAddClick()}>Add Code</CButton>
              </div>
            </div>

            <div className="datatableGrid">
              <DataGridPro
                rows={filteredServices}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 15 },
                  },
                }}
                pagination
                pageSizeOptions={[5, 10]}

                density="compact"
                disableRowSelectionOnClick
              loading={loadingState}
              />
            </div>
          </CCardBody>
        </CCard>
        <OffCanvas
          Open={visible}
          title={editValue ? 'Edit Promoter' : 'Add Promoter'}
          handleCloseClick={handleCloseClick}
          component={<Add apiCall={fetchData} industry={industry} handleClose={handleCloseClick} editValue={editValue} clearFormData={clearFormData} />}
        />
        <CModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          aria-labelledby="ScrollingLongContentExampleLabel"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
          }}
        >
          <CModalHeader>
            <h3>Delete</h3>
          </CModalHeader>
          <CModalBody>Are you sure you want to delete {editValue?.receiverName}...?</CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
              Close
            </CButton>
            <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
              Ok
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    </>
  );
};

export default Promoters;


// import {
//   CTable,
//   CCardBody,
//   CImage,
//   CCard,
//   CTooltip,
//   CTableBody,
//   CModalHeader,
//   CModal,
//   CModalBody,
//   CButton,
//   CModalFooter,
//   CTableDataCell,
//   CTableHeaderCell,
//   CTableHead,
//   CTableRow,
// } from '@coreui/react'
// import React, { useMemo, useState, useEffect } from 'react'
// import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api'
// import OffCanvas from '../../../components/share/canvas'
// import { deleteIcon, edit } from 'src/components/Images/Images'
// import Add from './Add'
// import { useNavigate } from 'react-router-dom'
// import { ToastContainer, toast } from 'react-toastify'
// import "react-toastify/dist/ReactToastify.css";

// const Promoters = () => {
//   let companyId = 0
//   const [items, setItems] = useState([])
//   const [editValue, setEditValue] = useState()
//   const [visible, setVisible] = useState(false)
//   const [isModalVisible, setIsModalVisible] = useState(false)
//   const accessToken = localStorage.getItem('token')
//   const [industry, setIndustry] = useState([])
//   const navigate = useNavigate()
//   const [clearFormData, setClearFormData] = useState(false)
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(15); // Number of items per page
//   const [searchQuery, setSearchQuery] = useState('')

//   const headers = useMemo(
//     () => ({
//       Authorization: `Bearer ${accessToken}`,
//     }),
//     [accessToken],
//   )

//   const filteredServices = useMemo(() => {
//     return items.filter(
//       (service) =>
//         service.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//          service.abnNumber?.toLowerCase().includes(searchQuery.toLowerCase())||
//         service.userName.toLowerCase().includes(searchQuery.toLowerCase()),
//     )
//   }, [items, searchQuery])

//   const handleAddClick = () => {
//     setEditValue()
//     setVisible(true)
//   }

//   const handleCloseClick = () => {
//     setEditValue()
//     setClearFormData((prev) => !prev)
//     setVisible(false)

//   }

//   const handleEditClick = (i) => {
//     setEditValue(i)
//     setVisible(true)
//   }

//   const handleDelete = (i) => {
//     setEditValue(i)
//     setIsModalVisible(true)
//   }


//   const handleBranding = (i) => {
//     console.log('e', i)
//     setEditValue(i) 
//     navigate('/brandings', { state: { editValue: i } });
//   }

//   const fetchData = async () => {
//     try {
//       const res = await GetApiwithHeader(`/api/auth/GetPromoter`, headers)
//       // console.log('..  console.log,response)..', res?.data?.data)
//       setItems(res?.data?.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   const fetchIndustry = async () => {
//     const apiUrl = '/api/industry'
//     try {
//       const res = await GetApiwithHeader(apiUrl, headers)
//       // console.log('..  cfetchIndustry..', res?.data?.data)
//       setIndustry(res?.data?.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     fetchData()
//     fetchIndustry()
//   }, [headers, companyId])

//   const handleDeleteClick = async (id) => {
//     const apiUrl = `api/auth/DeletePromoter?id=${encodeURIComponent(editValue.id)}&companyId=${encodeURIComponent(companyId)}`;
//     try {
//       const response = await DeleteApiwithHeader(apiUrl, headers)
//       console.log('respnce',response)
//       if (response.message === 'Success') {
//         setIsModalVisible(false)
//         const updatedService = items.filter((entry) => entry.id !== id)
//         setItems(updatedService)
//         toast.success("Deleted Successfully",{ autoClose: 1000 })
//       } else {
//         console.error('Unexpected response format:', response)
//       }
//     } catch (error) {
//       console.error('Delete API Request Error:', error)
//     }
//   }
//   const handleSort = (key) => {
//     let direction = 'ascending';
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };



//   const sortedItems = () => {
//     const sortableItems = [...items];
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sortableItems;
//   };

//   const handlePrevPage = () => {
//     setCurrentPage(currentPage - 1);
//   };

//   // Function to handle next page
//   const handleNextPage = () => {
//     setCurrentPage(currentPage + 1);
//   };



//   const totalPages = Math.ceil(items?.length / itemsPerPage);
//   // Function to get the current page's data
//   // const getCurrentPageData = () => {
//   //   const startIndex = (currentPage - 1) * itemsPerPage;
//   //   const endIndex = startIndex + itemsPerPage;
//   //   return items ? sortedItems()?.slice(startIndex, endIndex) : "";
//   // };

//   const getCurrentPageData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     return filteredServices.slice(startIndex, endIndex) 
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   return (
//     <>
//     <div className="cardBody">
//     <ToastContainer position="top-right" />
//       <CCard>
//         <CCardBody>
//           <div className="cardHeader">
//              <h3>Registered promoters list</h3>
//              <div className="mr-1">
//                 <input
//                   type="text"
//                   placeholder="Search..."
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
//                   // Adding Bootstrap class for styling
//                 />
//             <CButton onClick={() => handleAddClick()}>
//               Add Promoter
//             </CButton>
//           </div>
//           </div>
//           <div className="cardTable">
//           <CTable>
//             <CTableHead>
//               <CTableRow>
//                 <CTableHeaderCell>
//                   S.No
//                 </CTableHeaderCell>
//                 <CTableHeaderCell 
//                 scope="col"
//                 onClick={() => handleSort('firstName')}
//                 >
//                   Contact Name
//                 </CTableHeaderCell>
//                 <CTableHeaderCell 
//                 scope="col"
//                 onClick={() => handleSort('phoneNumber')}
//                 >
//                   Mobile 
//                 </CTableHeaderCell>
//                 <CTableHeaderCell 
//                 scope="col"
//                 onClick={() => handleSort('companyName')}
//                 >
//                   Company Name
//                 </CTableHeaderCell>
//                 <CTableHeaderCell 
//                  scope="col"
//                  onClick={() => handleSort('abnNumber')}
//                  >
//                   ABN 
//                 </CTableHeaderCell>
//                 <CTableHeaderCell 
//                  scope="col"
//                  onClick={() => handleSort('userName')}
//                  >
//                   Email ID
//                 </CTableHeaderCell>
//                 <CTableHeaderCell scope="col">
//                   Action
//                 </CTableHeaderCell>
//               </CTableRow>
//             </CTableHead>
//             <CTableBody>
//               {getCurrentPageData()?.map((i, index) => (
//                 <CTableRow key={index}>
//                   {/* {console.log(i)} */}
//                 {/* <CTableHeaderCell className="col-gap" scope="row">
//                     {index + 1}
//               </CTableHeaderCell>  */}
//               <CTableDataCell>
//                     {(currentPage - 1) * itemsPerPage + index + 1}
//                   </CTableDataCell>
//                   <CTableDataCell>{i?.firstName} {i?.lastName}</CTableDataCell>
//                   <CTableDataCell>{i?.phoneNumber}</CTableDataCell>
//                   <CTableDataCell>{i?.companyName}</CTableDataCell>
//                   <CTableDataCell>{i?.abnNumber}</CTableDataCell>
//                   <CTableDataCell>{i?.userName}</CTableDataCell>
//                   <CTableDataCell>
//                     <CTooltip content="Branding">
//                       <CButton onClick={() => handleBranding(i)}>
//                         Branding
//                       </CButton>
//                     </CTooltip>
//                     <CTooltip content="Edit">
//                       <button className="editIcon">
//                         <CImage
//                           src={edit}
//                           onClick={() => handleEditClick(i)}
//                         />
//                       </button>
//                     </CTooltip>
//                     {/* <CTooltip content="Delete">
//                       <button className="deleteIcon">
//                         <CImage
//                           src={deleteIcon}
//                           onClick={() => handleDelete(i)}
//                         />
//                       </button>
//                     </CTooltip> */}
//                   </CTableDataCell>
//                 </CTableRow>
//               ))}
//             </CTableBody>
//           </CTable>
//           </div> 
//           {/* <CPagination className="justify-content-end" aria-label="Page navigation example">
//             <CPaginationItem aria-label="Previous">
//                 <span aria-hidden="true">&laquo;</span>
//             </CPaginationItem>
//             <CPaginationItem>1</CPaginationItem>
//             <CPaginationItem>2</CPaginationItem>
//             <CPaginationItem>3</CPaginationItem>
//             <CPaginationItem aria-label="Next">
//                 <span aria-hidden="true">&raquo;</span>
//             </CPaginationItem>
//         </CPagination> */}
//           <nav>
//                     <ul className="pagination">
//                       <li
//                         className={`page-item ${
//                           currentPage === 1 ? "disabled" : ""
//                         }`}
//                       >
//                         <button className="page-link" onClick={handlePrevPage}>
//                           Previous
//                         </button>
//                       </li>
//                       {Array.from({ length: totalPages }, (_, index) => (
//                         <li
//                           key={index}
//                           className={`page-item ${
//                             currentPage === index + 1 ? "active" : ""
//                           }`}
//                         >
//                           <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                             {index + 1}
//                           </button>
//                         </li>
//                       ))}
//                       <li
//                         className={`page-item ${
//                           currentPage === totalPages ? "disabled" : ""
//                         }`}
//                       >
//                         <button className="page-link" onClick={handleNextPage}>
//                           Next
//                         </button>
//                       </li>
//                     </ul>
//                   </nav>
//         </CCardBody>
//       </CCard>
//       <OffCanvas
//         Open={visible}
//         title={editValue ? 'Edit Promoter' : 'Add Promoter'}
//         handleCloseClick={handleCloseClick}
//         component={
//           <Add
//             apiCall={fetchData}
//             industry={industry}
//             handleClose={handleCloseClick}
//             editValue={editValue}
//             clearFormData={clearFormData}
//           />
//         }
//       />
//       <CModal
//         visible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//         aria-labelledby="ScrollingLongContentExampleLabel"
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           overflow: 'auto',
//         }}
//       >
//         <CModalHeader>
//           <h3>Delete</h3>
//         </CModalHeader>
//         <CModalBody>Are you sure want to delete {editValue?.receiverName}...?</CModalBody>
//         <CModalFooter>
//           <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
//             Close
//           </CButton>
//           <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
//             Ok
//           </CButton>
//         </CModalFooter>
//       </CModal>
//       </div>
//     </>
//   )
// }

// export default Promoters

