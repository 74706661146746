import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CButton, CCardBody, CCard, CImage, CModalBody, CModalHeader, CModalFooter, CModal, CTooltip } from '@coreui/react';
import { DeleteApiwithHeader, GetApiwithHeader } from 'src/Api/Api';
import { deleteIcon, edit, download } from 'src/components/Images/Images';
import OffCanvas from '../../../components/share/canvas';
import Add from './AddInvitation';
import FileUpload from '../../../components/share/import';
import { postApiWithHeader } from 'src/Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../fonts/fonts.css";
import { LicenseInfo } from '@mui/x-license';

const Invitation = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [services, setServices] = useState([]);
  const [editValue, setEditValue] = useState();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadModel, setIsUploadModel] = useState(false);
  const [csvData, setCsvData] = useState();
  const [importFile, setFileImport] = useState();
  const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const accessToken = localStorage.getItem('token');
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingState, setLoadingState] = useState(true);
  let companyId = 0;

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken],
  );

  const headersFile = useMemo(
    () => ({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    }),
    [accessToken],
  );

  const filteredServices = useMemo(() => {
    return services.filter(
      (service) =>
        service.receiverName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.receiverMobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
        service.status.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [services, searchQuery]);

  const fetchData = async () => {
    try {
      const res = await GetApiwithHeader(`/api/auth/getuserbyrole?role=BusinessOwner`, headers);
      console.log('res', res.data.data || []);
      setLoadingState(false);
      setServices(res?.data?.data);
    } catch (error) {
      console.log(error);
      setLoadingState(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [headers, companyId]);

  const handleAddClick = () => {
    setEditValue();
    setVisible(true);
  };

  const callBack = (data) => {
    setCsvData(data);
  };

  const callBackFile = (data) => {
    setFileImport(data);
  };

  const handleCloseClick = () => {
    setVisible(false);
    setClearFormDataFlag((prev) => !prev);
    setEditValue();
  };

  const handleEditClick = (i) => {
    setEditValue(i);
    setVisible(true);
  };

  const handleDelete = (i) => {
    setEditValue(i);
    setIsModalVisible(true);
  };

  const handleDeleteClick = async (id) => {
    const apiUrl = `/api/invitation/delete/${id},${companyId}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === 'Success') {
        setIsModalVisible(false);
        fetchData();
        toast.success('Deleted Successfully', { autoClose: 1000 });
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Delete API Request Error:', error);
    }
  };

  const handleSampleDownload = async (item) => {
    try {
      const res = await GetApiwithHeader(`/api/BankTransactions/GetCsv/0,Invitee_List.csv`, headers);
      const fileUrl = res?.data?.data?.url;
      console.log('res?.data?.data?.url', res?.data?.data?.url);
      if (fileUrl) {
        window.open(fileUrl, '_blank');
      } else {
        console.log('File URL not found');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploads = async () => {
    const formData = new FormData();
    formData.append('file', importFile);
    try {
      const response = await postApiWithHeader(`/api/invitation/invitation?deviceType=Web&role=BusinessOwner`, formData, headersFile);
      console.log('response', response);
      if (response.data.statusCode === 100) {
        fetchData();
        setIsUploadModel(false);
        toast.success('File Uploaded Successfully', { autoClose: 1000 });
      }
    } catch (error) {
      console.error('Error creating file:', error);
      throw error;
    }
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const columns = [
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      headerClassName: 'dataGridHeader'
    },
    { field: 'receiverName', headerName: 'User Name', width: 300 ,  headerClassName: 'dataGridHeader'},
    { field: 'receiverMobile', headerName: 'Mobile Number', width: 300 ,  headerClassName: 'dataGridHeader'},
    { field: 'status', headerName: 'Status', width: 300 ,  headerClassName: 'dataGridHeader'},
    {
      field: 'actions',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <>
        <div className='rowIcon'>
            <CTooltip content="Edit">
              <button onClick={() => handleEditClick(params.row)}  className="editIcon icon-editIcon">
              </button>
            </CTooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="cardBody">
        <ToastContainer position="top-right" />
        <CCard>
          <CCardBody>
            <div className="cardHeader">
              <h3>Business owner invitation log</h3>
              <div className="mr-1">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <CButton className="" onClick={handleAddClick}>
                  Add Invitation
                </CButton>
                <CButton className='ms-2' onClick={() => setIsUploadModel(!isUploadModel)}>Import</CButton>
                <CTooltip content="Sample Download">
                  <button className='ms-2 btn download-btn'>
                    <span className="icon-downarrow" onClick={handleSampleDownload}></span>
                  </button>
                </CTooltip>
              </div>
            </div>
            <div className="datatableGrid">
              <DataGrid
                rows={filteredServices}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 15 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                density="compact"
                disableRowSelectionOnClick
                loading={loadingState}
              />
            </div>
          </CCardBody>
        </CCard>
        <OffCanvas
          Open={visible}
          title={editValue ? 'Edit Invitation' : 'Add Invitation'}
          handleCloseClick={handleCloseClick}
          component={
            <Add
              apiCall={fetchData}
              handleClose={handleCloseClick}
              editValue={editValue}
              clearFormData={clearFormDataFlag}
            />
          }
        />
        <CModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          aria-labelledby="ScrollingLongContentExampleLabel"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
          }}
        >
          <CModalHeader>
            <h3>Delete</h3>
          </CModalHeader>
          <CModalBody>Are you sure want to delete {editValue?.receiverName}...?</CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
              No
            </CButton>
            <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
              Yes
            </CButton>
          </CModalFooter>
        </CModal>
        <CModal
          alignment="center"
          visible={isUploadModel}
          onClose={() => setIsUploadModel(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader></CModalHeader>
          <CModalBody>
            <FileUpload
              handleCloseClick={handleCloseClick}
              callBackFile={callBackFile}
              callBack={callBack}
              handleUpload={handleUploads}
            />
          </CModalBody>
        </CModal>
      </div>
    </>
  );
};

export default Invitation;

//   CTable,
//   CTableBody,
//   CTableDataCell,
//   CTableHeaderCell,
//   CTableHead,
//   CTableRow,
//   CButton,
//   CCardBody,
//   CCard,
//   CImage,
//   CModalBody,
//   CModalHeader,
//   CModalFooter,
//   CModal,
//   CTooltip,
// } from '@coreui/react'
// import React, { useEffect, useState, useMemo } from 'react'
// import { DeleteApiwithHeader, GetApiwithHeader } from 'src/Api/Api'
// import { deleteIcon, edit, download } from 'src/components/Images/Images'
// import OffCanvas from '../../../components/share/canvas'
// import Add from './AddInvitation'
// import FileUpload from '../../../components/share/import'
// import { postApiWithHeader } from 'src/Api/Api'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// const Invitation = () => {
//   const [services, setServices] = useState([])
//   const [editValue, setEditValue] = useState()
//   const [visible, setVisible] = useState(false)
//   const [isModalVisible, setIsModalVisible] = useState(false)
//   const [isUploadModel, setIsUploadModel] = useState(false)
//   const [csvData, setCsvData] = useState()
//   const [importFile, setFileImport] = useState()
//   const [clearFormDataFlag, setClearFormDataFlag] = useState(false)
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })
//   const [currentPage, setCurrentPage] = useState(1)
//   const [itemsPerPage] = useState(15)
//   const accessToken = localStorage.getItem('token')
//   const [searchQuery, setSearchQuery] = useState('')
//   let companyId = 0

//   const headers = useMemo(
//     () => ({
//       Authorization: `Bearer ${accessToken}`,
//     }),
//     [accessToken],
//   )

//   const headersFile = useMemo(
//     () => ({
//       Authorization: `Bearer ${accessToken}`,
//       'Content-Type': 'multipart/form-data',
//     }),
//     [accessToken],
//   )

//   const filteredServices = useMemo(() => {
//     return services.filter(
//       (service) =>
//         service.receiverName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.receiverMobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.status.toLowerCase().includes(searchQuery.toLowerCase()),
//     )
//   }, [services, searchQuery])

//   const fetchData = async () => {
//     try {
//       const res = await GetApiwithHeader(`/api/auth/getuserbyrole?role=BusinessOwner`, headers)
//       console.log('res', res.data.data)
//       setServices(res?.data?.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     fetchData()
//   }, [headers, companyId])

//   const handleAddClick = () => {
//     setEditValue()
//     setVisible(true)
//   }

//   const callBack = (data) => {
//     setCsvData(data)
//   }

//   const callBackFile = (data) => {
//     setFileImport(data)
//   }

//   // const handleUpload = () => {
//   //   console.log('ee')
//   //   setIsUploadModel(true)
//   // }

//   const handleCloseClick = () => {
//     setVisible(false)
//     setClearFormDataFlag((prev) => !prev)
//     setEditValue()
//   }

//   const handleEditClick = (i) => {
//     setEditValue(i)
//     setVisible(true)
//   }

//   const handleDelete = (i) => {
//     setEditValue(i)
//     setIsModalVisible(true)
//   }

//   const handleDeleteClick = async (id) => {
//     const apiUrl = `/api/invitation/delete/${id},${companyId}`
//     try {
//       const response = await DeleteApiwithHeader(apiUrl, headers)
//       if (response.message === 'Success') {
//         setIsModalVisible(false)
//         // Fetch data again after deletion
//         fetchData()
//         toast.success('Deleted Successfully', { autoClose: 1000 })
//       } else {
//         console.error('Unexpected response format:', response)
//       }
//     } catch (error) {
//       console.error('Delete API Request Error:', error)
//     }
//   }

//   const handleSampleDownload = async (item) => {
//     try {
//       const res = await GetApiwithHeader(
//         `/api/BankTransactions/GetCsv/0,Invitee_List.csv`,
//         headers,
//       )
//       const fileUrl = res?.data?.data?.url
//       console.log('res?.data?.data?.url', res?.data?.data?.url)
//       if (fileUrl) {
//         window.open(fileUrl, '_blank')
//       } else {
//         console.log('File URL not found')
//       }
//       // console.log("view", res?.data?.data?.url);
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   const handleUploads = async () => {
//     const formData = new FormData()
//     formData.append('file', importFile)
//     try {
//       const response = await postApiWithHeader(`/api/invitation/invitation?deviceType=Web&role=BusinessOwner`, formData, headersFile)
//       console.log('response', response)
//       if (response.data.statusCode === 100) {
//         fetchData()
//         setIsUploadModel(false)
//         toast.success('File Uploaded Sucessfully', { autoClose: 1000 })
//       }
//       // return response;
//     } catch (error) {
//       console.error('Error creating file:', error)
//       throw error // Re-throwing the error to propagate it further if necessary
//     }
//   }

//   const handleSort = (key) => {
//     let direction = 'ascending'
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending'
//     }
//     setSortConfig({ key, direction })
//   }

//   const sortedItems = () => {
//     const sortableItems = [...services]
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1
//         }
//         return 0
//       })
//     }
//     return sortableItems
//   }

//   const handlePrevPage = (e) => {
//     setCurrentPage(currentPage - 1)
//   }

//   // Function to handle next page
//   const handleNextPage = (e) => {
//     setCurrentPage(currentPage + 1)
//   }

//   const totalPages = Math.ceil(services?.length / itemsPerPage)
//   // Function to get the current page's data
//   // const getCurrentPageData = () => {
//   //   const startIndex = (currentPage - 1) * itemsPerPage;
//   //   const endIndex = startIndex + itemsPerPage;
//   //   return services ? sortedItems()?.slice(startIndex, endIndex) : "";
//   // };
//   const getCurrentPageData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage
//     const endIndex = startIndex + itemsPerPage
//     return filteredServices.slice(startIndex, endIndex)
//   }

//   const handlePageChange = (page) => {
//     setCurrentPage(page)
//   }

//   console.log('isUploadModel', isUploadModel)
//   console.log('services', services)

//   return (
//     <>
//       <div className="cardBody">
//         <ToastContainer position="top-right" />
//         <CCard>
//           <CCardBody>
//             <div className="cardHeader">
//               <h3>Business owner invitation log</h3>
//               <div className="mr-1">
//                 <input
//                   type="text"
//                   placeholder="Search..."
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query

//                 />
//                 <CButton className="mr-1" onClick={() => handleAddClick()}>
//                   Add Invitation
//                 </CButton>
//                 <CButton onClick={() => setIsUploadModel(!isUploadModel)}>Import</CButton>
//                 <CTooltip content="Sample Download">
//                   <button className='editIcon'>
//                     <CImage
//                       src={download}
//                       onClick={handleSampleDownload}
//                     />
//                   </button>
//                 </CTooltip>
//               </div>
//             </div>
//             <div className="cardTable">
//               <CTable>
//                 <CTableHead>
//                   <CTableRow>
//                     <CTableHeaderCell scope="col">S.No</CTableHeaderCell>
//                     <CTableHeaderCell scope="col" onClick={() => handleSort('receiverName')}>
//                       User Name
//                     </CTableHeaderCell>
//                     <CTableHeaderCell scope="col" onClick={() => handleSort('receiverMobile')}>
//                       Mobile Number
//                     </CTableHeaderCell>
//                     <CTableHeaderCell scope="col" onClick={() => handleSort('status')}>
//                       Status
//                     </CTableHeaderCell>
//                     <CTableHeaderCell scope="col">Action</CTableHeaderCell>
//                   </CTableRow>
//                 </CTableHead>
//                 <CTableBody>
//                   {getCurrentPageData()?.map((i, index) => (
//                     <CTableRow key={index}>
//                       <CTableDataCell>
//                         {' '}
//                         {(currentPage - 1) * itemsPerPage + index + 1}
//                       </CTableDataCell>
//                       <CTableDataCell style={{ alignItems: 'center' }}>
//                         {i.receiverName}
//                       </CTableDataCell>
//                       <CTableDataCell>{i.receiverMobile}</CTableDataCell>
//                       <CTableDataCell>{i.status}</CTableDataCell>
//                       <CTableDataCell>
//                         <CTooltip content="Edit">
//                           <button className="editIcon">
//                             <CImage src={edit} onClick={() => handleEditClick(i)} />
//                           </button>
//                         </CTooltip>
//                         {/* <CTooltip content="Delete">
//                           <button className="deleteIcon">
//                             <CImage
//                               src={deleteIcon}
//                               onClick={() => handleDelete(i)}
//                               style={{ marginLeft: '5px', width: '20px' }}
//                             />
//                           </button>
//                         </CTooltip> */}
//                       </CTableDataCell>
//                     </CTableRow>
//                   ))}
//                 </CTableBody>
//               </CTable>
//             </div>
//             <nav>
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button className="page-link" onClick={handlePrevPage}>
//                     Previous
//                   </button>
//                 </li>
//                 {Array.from({ length: totalPages }, (_, index) => (
//                   <li
//                     key={index}
//                     className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
//                   >
//                     <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                       {index + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                   <button className="page-link" onClick={handleNextPage}>
//                     Next
//                   </button>
//                 </li>
//               </ul>
//             </nav>
//           </CCardBody>
//         </CCard>
//         <OffCanvas
//           Open={visible}
//           title={editValue ? 'Edit Invitation' : 'Add Invitation'}
//           handleCloseClick={handleCloseClick}
//           component={
//             <Add
//               apiCall={fetchData}
//               handleClose={handleCloseClick}
//               editValue={editValue}
//               clearFormData={clearFormDataFlag}
//             />
//           }
//         />
//         <CModal
//           visible={isModalVisible}
//           onClose={() => setIsModalVisible(false)}
//           aria-labelledby="ScrollingLongContentExampleLabel"
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             overflow: 'auto',
//           }}
//         >
//           <CModalHeader>
//             <h3>Delete</h3>
//           </CModalHeader>
//           <CModalBody>Are you sure want to delete {editValue?.receiverName}...?</CModalBody>
//           <CModalFooter>
//             <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
//               No
//             </CButton>
//             <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
//               Yes
//             </CButton>
//           </CModalFooter>
//         </CModal>
//         <CModal
//           alignment="center"
//           visible={isUploadModel}
//           onClose={() => setIsUploadModel(false)}
//           aria-labelledby="VerticallyCenteredExample"
//         >
//           <CModalHeader></CModalHeader>
//           <CModalBody>
//             <FileUpload
//               handleCloseClick={handleCloseClick}
//               callBackFile={callBackFile}
//               callBack={callBack}
//               handleUpload={handleUploads}
//             />
//           </CModalBody>
//         </CModal>
//       </div>
//     </>
//   )
// }

// export default Invitation


