import React, { useState, useEffect, useMemo } from 'react'
import { CForm, CFormLabel, CFormInput, CTooltip, CButton, CFormSelect, CRow, CCol, CSpinner } from '@coreui/react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PropTypes from 'prop-types'
// import { postApiWithHeader, UpdateUserwithHeader } from 'src/Api/Api';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GetApi, UpdateUserwithHeader, postApi, postApiWithHeader } from 'src/Api/Api'
import SetPassword from './SetPassword'
import * as Yup from 'yup';
import { cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const AddInvitation = (props) => {
  const accessToken = localStorage.getItem('token')
  const [errors, setErrors] = useState({})
  const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken])
  const [isLoading, setIsLoading] = useState(false);
  const [abnValue, setAbnValue] = useState();
  const [iconStatus, setIconStatus] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState("");
  const [abnValid, setAbnValid] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const userInfoString = localStorage.getItem('userInfo');

  let companyID;
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    companyID = userInfo.companyID;
  }

  AddInvitation.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.object,
    industry: PropTypes.array.isRequired,
    clearFormData: PropTypes.bool,
  }

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    addressLine1: '',
    abnNumber: '',
    companyName: '',
    industryType: null,
    addressLine2: '',
    password: '',
    isBusiness: false,
    isPromotor: true,
    userId: ''
  })
 
  const { editValue, industry, apiCall, handleClose } = props

  const handleIndustryTypeChange = (e) => {
    const value = parseInt(e.target.value, 10) || null;
    setFormData({
      ...formData,
      industryType: value,
    });
    setErrors({
      ...errors,
      industryType: '',
    });

  };

  const createValidationSchema = (editValue) => {
    return Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      mobileNumber: Yup.string().required('Mobile number is required'),
      // companyName: Yup.string().required('Company Name is required'),
      // abnNumber: Yup.lazy((value) => {
      //   if (!value || value.length === 0) {
      //     return Yup.string().required('ABN number is required');
      //   } else {
      //     return Yup.string().matches(/^\d{11}$/, 'ABN number must be 11 digits');
      //   }
      // }),
      abnNumber: Yup.string().required("ABN number is required").matches(/^\d{11}$/, "ABN number must be exactly 11 digits"),
      addressLine1: Yup.string().required('Address line 1 is required'),
      // addressLine2: Yup.string().required('Address Line 2 is required'),
      industryType: Yup.number()
        .required('Industry type is required')
        .test('is-valid-industry', 'Select a valid Industry Type', (value) => !editValue || (editValue && value !== 0)),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: editValue ? Yup.string() : Yup.string().required('Password is required')
    });
  };

  const validationSchema = createValidationSchema(props.editValue);

  useEffect(() => {
    if (editValue) {
      setFormData({
        email: editValue.userName || '',
        firstName: editValue.firstName || '',
        lastName: editValue.lastName || '',
        mobileNumber: editValue.phoneNumber || '',
        addressLine1: editValue.address || '',
        abnNumber: editValue.abnNumber || '',
        companyName: editValue.companyName || '',
        industryType: editValue.industryType || 0,
        addressLine2: editValue.addressLine2 || '',
        password: editValue.password || '',
        isBusiness: editValue.isBusiness || false,
        isPromotor: editValue.isPromotor || true,
        userId: editValue.id || '',
      })
      setAbnValue(editValue.abnNumber);
      setIconStatus(true)
    }
  }, [editValue])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const handleOnChange = (value, country) => {

    const sanitizedPhoneNumber = value.replace(/^0+/, '');

    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;

    setFormData({
      ...formData,
      mobileNumber: formattedPhoneNumber,
    })
    setErrors({
      ...errors,
      mobileNumber: ''
    })
  }

  useEffect(() => {
    if (props.clearFormData) {
      resetForm();
      props.handleClose();
    }
  }, [props.clearFormData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const apiUrl = editValue ? `/api/auth/updatePromoterDetails` : '/api/auth/registerPromoter'
      const response = await (editValue
        ? UpdateUserwithHeader(apiUrl, formData, headers)
        : postApiWithHeader(apiUrl, formData, headers))
      console.log('response', response)
      if (editValue ? response.message === "Success" : response.data.message === "Success") {
        setIsLoading(true);
        resetForm()
        apiCall()
        handleClose()
        toast.success(`${editValue ? 'Updated' : 'Added'} successfully`, { autoClose: 1000 });
      } else if (editValue ? response.statusCode === 102 : response.data.statusCode === 102) {
        toast.error(editValue ? response.message : response.data.message, { autoClose: 1000 })
      } else {
        console.error('Unexpected response format:', response)
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    } finally {
      setIsLoading(false);
     {!editValue && setIconStatus(false)}
    }
  }

  const resetForm = () => {
    setFormData({
      email: editValue ? editValue.userName : '',
      firstName: editValue ? editValue.firstName : '',
      lastName: editValue ? editValue.lastName : '',
      mobileNumber: editValue ? editValue.phoneNumber : '',
      addressLine1: editValue ? editValue.address : '',
      abnNumber: editValue ? editValue.abnNumber : '',
      companyName: editValue ? editValue.companyName : '',
      industryType: editValue ? editValue.industryType : 0,
      addressLine2: editValue ? editValue.addressLine2 : '',
      password: editValue ? editValue.password : '',
      isBusiness: editValue ? editValue.isBusiness : false,
      isPromotor: editValue ? editValue.isPromotor : true,
      userId: editValue ? editValue.id : '',
    });
    setAbnValue("");
    {!editValue && setIconStatus(false)}
    setErrors({})
  }
  const handlePasswordUpdate = (newPassword) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: newPassword,
    }));
  };

  const handleVerify = async (abnValue) => {
    try {

      const res = await GetApi(
        `api/abn/ABN-Details?companyId=${companyID}&abn=${abnValue}`
      );
      console.log('res', res)
      if (res.statusCode === 101) {
        setVerifyMsg("Please enter correct ABN number");
        setIconStatus(false);
      } else {
        setIconStatus(true);
        setVerifyMsg("ABN Verified.");
      }
      setTimeout(() => {
        setVerifyMsg("");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChangeAbn = (e) => {
  //   const value = e.target.value;
  //   // setFormData(prevData => ({ ...prevData, taxAccountantNumber: value }));
  //   // setErrors(prevErrors => ({ ...prevErrors, taxAccountantNumber: '' }));
  //   setAbnValid(true); // Reset the ABN validation state

  //   setAbnValue(value);
  //   setFormData(prevData => ({ ...prevData, taxAccountantNumber: value }));
  //   if (value.length < 11) {
  //     setverifyMsg("");
  //   }
  //   setAbnValid(true);
  // };

  const handleChangeAbn = (e) => {
    const value = e.target.value;
    setAbnValue(value);
    setFormData({
      ...formData,
      abnNumber: value,
    });
    setAbnValid(true);
    setIconStatus(false);

    if (value.length < 11) {
      setVerifyMsg("");
    } else {
      if (iconStatus === false) {
        setVerifyMsg("Please verify ABN");
        setTimeout(() => {
          setVerifyMsg("");
        }, 3000);
      } else {
        setVerifyMsg("");   
      }
    }
    setErrors({
      ...errors,
      abnNumber: '',
    });
  };

  return (
    <div>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel>
            First Name<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="text"
            name="firstName"
            placeholder='Enter first name'
            value={formData.firstName}
            onChange={handleInputChange}
            className={errors.firstName ? 'is-invalid' : ''}
          />
          {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel>
            Last Name<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="text"
            name="lastName"
            placeholder='Enter last name'
            value={formData.lastName}
            onChange={handleInputChange}
            className={errors.lastName ? 'is-invalid' : ''}
          />
          {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel>
            Mobile Number <span className="text-danger">*</span>
          </CFormLabel>
          <PhoneInput
            name="mobileNumber"
            placeholder='Enter mobile number'
            onChange={(value, country) => handleOnChange(value, country)}
            value={formData.mobileNumber}
            inputStyle={{ width: '100%' }} // Adjust the width as needed
            className={errors.mobileNumber ? 'is-invalid' : ''}
            country={'au'}
          />
          {errors.mobileNumber && <div className="invalid-feedback">{errors.mobileNumber}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel>
            Company Name
          </CFormLabel>
          <CFormInput
            type="text"
            name='companyName'
            placeholder='Company name'
            defaultValue={formData.companyName}
            onChange={handleInputChange}
          // className={errors.companyName ? 'is-invalid' : ''}
          />
        </div>
        <div className="mb-3 CheckBoxAbn">
          <CFormLabel>
            ABN Number<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="text"
            name="abnNumber"
            placeholder='ABN number'
            value={formData.abnNumber}
            onChange={handleChangeAbn}
            className={errors.abnNumber ? 'is-invalid' : ''}
          />
          {/* <div className="CheckIcon">
            <CTooltip
              content={
                iconStatus ? "Verified" : "Send for verification!"
              }
            >
              <CIcon icon={cilCheckCircle} width="20px" height="20px"
                style={{ color: iconStatus ? "green" : "red" }} 
                onClick={() => handleVerify(abnValue)}
              />
            </CTooltip>
          </div> */}
           {abnValue?.length === 11 && (
            <div className="CheckIcon">
              <CTooltip
                content={iconStatus ? "Verified" : "Send for verification!"}
              >
                <CIcon
                  icon={cilCheckCircle}
                  width="20px"
                  height="20px"
                  style={{
                    color: iconStatus ? "green" : "red",
                    cursor: "pointer",
                  }}
                  onClick={() => handleVerify(abnValue)}
                />
              </CTooltip>
            </div>
          )}
          {errors.abnNumber  && (
            <div className="invalid-feedback">{errors.abnNumber}</div>
          )}
          {verifyMsg === "ABN Verified." ? (
            <span className="mt-10" style={{ color: "green" }}>
              {verifyMsg}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {verifyMsg}
              {/* Empty the error message when verifyMsg is displayed */}
              {verifyMsg && <span className="invalid-feedback"></span>}
            </span>
          )}

        </div>
        <div className="mb-3">
          <CFormLabel>
            Address Line 1<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="text"
            name="addressLine1"
            placeholder='Enter address'
            value={formData.addressLine1}
            onChange={handleInputChange}
            className={errors.addressLine1 ? 'is-invalid' : ''}
          />
          {errors.addressLine1 && <div className="invalid-feedback">{errors.addressLine1}</div>}
        </div>
        <div className="mb-3">
          <CFormLabel>
            Address Line 2
          </CFormLabel>
          <CFormInput
            type="text"
            name="addressLine2"
            placeholder='Enter address line'
            value={formData.addressLine2}
            onChange={handleInputChange}
          // className={errors.addressLine2 ? 'is-invalid' : ''}
          />
          {/* {errors.addressLine2 && <div className="invalid-feedback">{errors.addressLine2}</div>} */}
        </div>
        <div className="mb-3">
          <CFormLabel>Industry Type</CFormLabel>
          <CFormSelect
            placeholder="Select Industry Type"
            value={formData.industryType}
            onChange={handleIndustryTypeChange}
            className={errors.industryType ? 'is-invalid' : ''}
          >
            <option value="">Select Industry Type</option>
            {industry?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </CFormSelect>
          {errors.industryType && <div className="invalid-feedback">{errors.industryType}</div>}
        </div>

        <div className="mb-3">
          <CFormLabel>
            Email Address<span className="text-danger">*</span>
          </CFormLabel>
          <CFormInput
            type="email"
            name="email"
            placeholder='Enter email'
            value={formData.email}
            onChange={handleInputChange}
            className={errors.email ? 'is-invalid' : ''}
            disabled={editValue ? true : false}
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        {editValue ? ' ' :
          <div className="mb-3">
            <CFormLabel>
              Password<span className="text-danger">*</span>
            </CFormLabel>
            <CFormInput
              type="password"
              name="password"
              placeholder='Enter password'
              value={formData.password}
              onChange={handleInputChange}
              className={errors.password ? 'is-invalid' : ''}
            />
            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
          </div>}

        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton color="light" onClick={() => props.handleClose()}>
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            <CButton type="submit" color="primary" className='mb-2' style={{ textTransform: 'none' }}>
              {isLoading ? <CSpinner size="sm" /> : 'Save'}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
      {editValue && <SetPassword passwordData={formData} onPasswordUpdate={handlePasswordUpdate}
        handleClose={props.handleClose}
        clearFormData={props.clearFormData}
      />}
    </div>
  )
}

export default AddInvitation




