import React, { useState, useEffect } from 'react'
import {
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  COffcanvasBody,
  CCloseButton,
} from '@coreui/react'
import PropTypes from 'prop-types'

function OffCanvas(props) {
  const [visible, setVisible] = useState(props?.Open)

  useEffect(() => {
    setVisible(props?.Open)
  }, [props?.Open])

  OffCanvas.propTypes = {
    handleCloseClick: PropTypes.func, // Assuming 'handleCloseClick' is a function prop
    Open: PropTypes.bool, // Assuming 'Open' is a boolean prop
    component: PropTypes.element, // Assuming 'component' is an element prop
    title : PropTypes.element,
    canvasWidth : PropTypes.element
  }

  const handleClose = () => {
    if (props.handleCloseClick) {
      props.handleCloseClick();
    }
  };

  return (
    <>
      <COffcanvas className='OffCanvasCtm' placement="end" visible={visible}  onHide={handleClose}  style={{ width: props.canvasWidth ? props.canvasWidth :'500px' }}>
        <COffcanvasHeader>
          <COffcanvasTitle>{props?.title}</COffcanvasTitle>
          <CCloseButton className="text-reset" onClick={props?.handleCloseClick} />
        </COffcanvasHeader>
        <COffcanvasBody>
         {props?.component}
        </COffcanvasBody>
      </COffcanvas>
    </>
  )
}

export default OffCanvas
