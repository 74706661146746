
import React, { useState, useMemo, useEffect } from 'react'
import {
    CButton,
    CCardGroup,
    CCol,
    CForm,
    CFormInput,
    CRow, CCard, CCardBody
} from '@coreui/react'
import { GetApiwithHeader, UpdateUserwithHeader, postApiWithHeader } from 'src/Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import { async } from 'regenerator-runtime';

const Term = () => {

    const accessToken = localStorage.getItem('token');
    const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken]);
    const [errors, setErrors] = useState({})
    const [term, setTerm] = useState([])
    const [editValue, setEditValue] = useState()

    const [formData, setFormData] = useState({
        title: '',
        introduction: '',
        termsConditions: '',
        privacyConsiderations: '',
        optOutPolicySecurityMeasures: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const fetchData = async () => {
        try {
            const res = await GetApiwithHeader(`/api/TermsAndConditions`, headers)
            setTerm(res?.data)
            // setEditValue(res?.data?.data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [headers])
    console.log('edit', editValue)

    useEffect(() => {
        if (term && term.data && term.data.length > 0) {
            const info = term.data[0];
            setFormData({
                title: info.title,
                introduction: info.introduction,
                termsConditions: info.termsConditions,
                privacyConsiderations: info.privacyConsiderations,
                optOutPolicySecurityMeasures: info.optOutPolicySecurityMeasures,
            });
        }
    }, [term]);


    // const handleSave = () => {
    //     // Perform actions like saving or broadcasting the data
    //     console.log('Form submitted:', formData);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        console.log('Form submitted:', formData)
        e.preventDefault();

        const validationErrors = {};
        if (!formData.title.trim()) {
            validationErrors.title = 'Title is required';
        }
        if (!formData.introduction.trim()) {
            validationErrors.introduction = 'Introduction is required';
        }
        if (!formData.termsConditions.trim()) {
            validationErrors.termsConditions = 'Term & Condition is required';
        }
        if (!formData.privacyConsiderations.trim()) {
            validationErrors.privacyConsiderations = 'privacy considerations is required';
        }
        if (!formData.optOutPolicySecurityMeasures.trim()) {
            validationErrors.optOutPolicySecurityMeasures = 'Optout policy is required';
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const apiUrl = editValue ? `/api/privacy-policy/update/${editValue.id}` : 'api/TermsAndConditions/create-update'
            const response = await (editValue
                ? UpdateUserwithHeader(apiUrl, formData, headers)
                : postApiWithHeader(apiUrl, formData, headers))

            if (editValue ? response.message == "Success" : response.data.message == "Success") {
                // resetForm()
                toast.success(`${editValue ? 'Updated' : 'Added'} successfully`, { autoClose: 1000 });
            } else {
                console.error('Unexpected response format:', response)
            }
        } catch {
            console.log('error')
        }
    }

    const resetForm = () => {
        setFormData({
            title: '',
            introduction: '',
            termsConditions: '',
            privacyConsiderations: '',
            optOutPolicySecurityMeasures: '',
        });
        setErrors({})
    }
    return (<>
        <ToastContainer position="top-right" />
        <CCard
            style={{
                width: '-webkit-fill-available',
                border: '1px solid #D9D9D9',
                borderRadius: '25px',
                opacity: 1,
            }}
        >
            <CCardBody className="m-3">
                <div>
                    <h4>Term and Conditions</h4>
                    <CCol md={12} >
                        <CCardGroup>
                            <CForm onSubmit={handleSubmit}>
                                <CFormInput
                                    type="text"
                                    name="title"
                                    label={
                                        <>
                                            Title <span className="text-danger">*</span>
                                        </>
                                    }
                                    aria-describedby="exampleFormControlInputHelpInline"
                                    value={formData.title}
                                    onChange={handleChange}
                                    placeholder='Enter title'
                                    // className="mb-2"
                                    className={`mb-2 ${errors.title ? 'is-invalid' : ''}`}
                                />
                                {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                                <CFormInput
                                    type="text"
                                    name="introduction"
                                    placeholder='Enter introduction'
                                    label=
                                    {
                                        <>
                                            Introduction <span className="text-danger">*</span>
                                        </>
                                    }
                                    aria-describedby="exampleFormControlInputHelpInline"
                                    value={formData.introduction}
                                    onChange={handleChange}
                                    className={`mb-2 ${errors.introduction ? 'is-invalid' : ''}`}
                                />
                                {errors.introduction && <div className="invalid-feedback">{errors.introduction}</div>}
                                <CFormInput
                                    type="text"
                                    name="termsConditions"
                                    placeholder='Enter terms & Conditions'
                                    label=
                                    {
                                        <>
                                            Terms and Conditions <span className="text-danger">*</span>
                                        </>
                                    }
                                    aria-describedby="exampleFormControlInputHelpInline"
                                    value={formData.termsConditions}
                                    onChange={handleChange}
                                    className={`mb-2 ${errors.termsConditions ? 'is-invalid' : ''}`}
                                />
                                {errors.termsConditions && <div className="invalid-feedback">{errors.termsConditions}</div>}
                                <CFormInput
                                    type="text"
                                    name="privacyConsiderations"
                                    placeholder='Enter privacy considerations'
                                    label=
                                    {
                                        <>
                                            Privacy Considerations <span className="text-danger">*</span>
                                        </>
                                    }
                                    aria-describedby="exampleFormControlInputHelpInline"
                                    value={formData.privacyConsiderations}
                                    onChange={handleChange}
                                    className={`mb-2 ${errors.privacyConsiderations ? 'is-invalid' : ''}`}
                                />
                                {errors.privacyConsiderations && <div className="invalid-feedback">{errors.privacyConsiderations}</div>}
                                <CFormInput
                                    type="text"
                                    name="optOutPolicySecurityMeasures"
                                    placeholder='Enter opt-out policy'
                                    label=
                                    {
                                        <>
                                            Opt-Out Policy Security Measures <span className="text-danger">*</span>
                                        </>
                                    }
                                    aria-describedby="exampleFormControlInputHelpInline"
                                    value={formData.optOutPolicySecurityMeasures}
                                    onChange={handleChange}
                                    className={`mb-2 ${errors.optOutPolicySecurityMeasures ? 'is-invalid' : ''}`}
                                />
                                {errors.optOutPolicySecurityMeasures && <div className="invalid-feedback">{errors.optOutPolicySecurityMeasures}</div>}

                            </CForm>
                        </CCardGroup>
                        <CRow className="d-flex justify-content-between ">
                            <CCol className="text-end">
                                <CButton color='primary' className='px-4'
                                    onClick={handleSubmit}
                                >
                                    Save
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCol>
                </div>
            </CCardBody>
        </CCard>
    </>)
}

export default Term