import React, {useState} from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import Dashboard from 'src/views/admin/dashboard/Dashboard'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types';

const DefaultLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
 
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const contentStyle = {
    marginLeft: isSidebarOpen ? '256px' : '0',
    transition: 'margin-left 0.3s ease',
    width: isSidebarOpen ? 'calc(100% - 256px)' : '100%',
    overflowX: 'hidden', // Prevent horizontal overflow
  };

  return (
     <div
        className={`wrapper d-flex flex-column min-vh-100 ${
          isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'
        }`}
        style={contentStyle}
      >
        <AppHeader toggleSidebar={toggleSidebar}/>
        <AppSidebar />
        <div className="body flex-grow-1 px-3" >
          <Outlet />
        </div>
        <AppFooter />
      </div>
  )
}
DefaultLayout.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default DefaultLayout
