import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CRow,
  CFormCheck
} from '@coreui/react'
import logo from 'src/assets/brand/logo.png'
import { postApi } from 'src/Api/Api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserManagement from 'src/views/admin/userDetails/UserManagement'
import { show } from 'src/components/Images/Images'

const Login = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',

  };
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({})
  const [showPassword, setShowPassword] = useState(false);

  // const [role, setRole] = useState('SuperAdmin')


  const validateForm = () => {
    const newErrors = {};

    // Basic email validation
    if (!values.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
      newErrors.email = 'Invalid email';
    }

    // Basic password validation
    if (!values.password.trim()) {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      return;
    }

    // Prepare the data to be sent to the backend
    const formData = {
      email: values?.email,
      password: values?.password,
      loginType: 'Password'
    };

    try {
      const response = await postApi('api/auth/login', formData)

      if (!response?.data) {
        toast.error('Failed to submit form data');
      } else {

        if (response.data.statusCode === 103) {
          setErrors({ email: 'Invalid Credentials', password: 'Invalid Credentials' });
        } else {
          const userId = response?.data?.data?.id;
          localStorage.setItem('userId', userId);
          localStorage.setItem("token", response.data.data.token);

          var data = response.data.data;
          console.log('data', data)
          let role = 'SuperAdmin'
          data && data.roleName?.map(async (item) => {
            if (item === 'Promoter')
              role = 'Promoter'
          })
          var userInfo = {
            firstname: data.firstName,
            lastname: data.lastName,
            email: data.email,
            mobile_number: data.phoneNumber,
            role: role, 
            companyID : data.companyId
          };

          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center" style={{ backgroundColor: '#F3F2F7', opacity: 1 }}>
      <CContainer>
        <CRow className='justify-content-center'>
          <CCol md={6}>
            <div className='loginSection'>
              <CCard className="p-4">
                <CCardBody>
                  <CForm >
                    <div className="clearfix">
                      <CImage align="center" rounded src={logo} className='mb-3' />
                    </div>

                    <h3>Login to access your account </h3>
                    <CFormInput
                      type="email"
                      name="email"
                      label="Email address"
                      placeholder="Enter user name"
                      // text="Must be 8-20 characters long."
                      aria-describedby="exampleFormControlInputHelpInline"
                      value={values.email}
                      onChange={handleChange}
                      className='mb-2'
                    />
                    {errors.email && <div className="text-danger ">{errors.email}</div>}

                    {/* <div className='mb-3'> */}
                    <div style={{ position: 'relative' }}>

                      <CFormInput
                        type={showPassword ? "text" : "password"}
                        name="password"
                        label="Password"
                        placeholder="Enter password"
                        // text="Must be 8-20 characters long."
                        aria-describedby="exampleFormControlInputHelpInline"
                        value={values.password}
                        onChange={handleChange}
                        className='mb-2'

                      />
                      <li
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ position: 'absolute', right: '10px', top: '74%', transform: 'translateY(-50%)' }}
                      >
                        {showPassword ? "Hide" :<CImage src={show} width="21px" height="15px" />}

                      </li>
                    </div>
                    {errors.password && <div className="text-danger">{errors.password}</div>}
                    <CFormCheck id="defaultCheck1" label="Remember me" className='mb-2' />
                    <div className='mt-4'>
                      <CButton
                        type="submit"
                        color="primary"
                        style={{
                          width: '-webkit-fill-available', borderRadius: "4px",
                          opacity: 1
                        }}
                        className='mb-2'
                        onClick={handleLogin}
                      >
                        Login
                      </CButton>

                    </div>
                    <div className='text-center forgotPassword'>
                      <CButton color='link' >
                        Forgot password?
                      </CButton>
                    </div>

                  </CForm>
                </CCardBody>
              </CCard>
            </div>

          </CCol>

        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
