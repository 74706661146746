import {
 CTable, CTableBody,CTableDataCell, CTableHeaderCell, CTableHead,CTableRow, CRow, CCol,CCardBody, CCard, CImage,
} from '@coreui/react'
import React from 'react'
import { deleteIcon, edit } from 'src/components/Images/Images'

const UserManagement = () => {

    const user = localStorage.getItem('userInfo');
    // Parse the JSON string back into an object
    var userDetail = JSON.parse(user);
     console.log('user', user)

    return <>
        {/* <h4>Invitation Log</h4> */}
        <div className='mb-2'>
            <CCard style={{
                width: "-webkit-fill-available",
                border: "1px solid #D9D9D9",
                borderRadius: "25px",
                opacity: 1
            }}>
                <CCardBody className='m-3'>
                    <CRow className="mb-3">
                        <CCol>
                            <h5 style={{
                                color: "#353540",
                                opacity: 1
                            }}>User Details</h5>
                        </CCol>
                    </CRow>
                    <CTable >
                        <CTableHead >
                            <CTableRow >
                                {/* <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">Sno</CTableHeaderCell> */}
                                <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">first Name</CTableHeaderCell>
                                <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">Last Name</CTableHeaderCell>
                                <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">Email Id</CTableHeaderCell>
                                <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">Mobile Number</CTableHeaderCell>
                                <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">Action</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {
                                //    user?.map((i, index) => (
                                <CTableRow >
                                    <CTableDataCell>{userDetail.firstname}</CTableDataCell>
                                    <CTableDataCell>{userDetail.lastname}</CTableDataCell>
                                    <CTableDataCell>{userDetail.email}</CTableDataCell>
                                    <CTableDataCell>{userDetail.mobile_number}</CTableDataCell>
                                    <CTableDataCell>
                                        <CImage src={edit}
                                            //    onClick={() => handleEditClick(i.id)}
                                            width="20px" height="20px" style={{ marginRight: '10px' }} />
                                        <CImage src={deleteIcon}
                                            // onClick={() => handleDelete(i.id)}
                                            width="20px" height="20px" />
                                    </CTableDataCell>
                                </CTableRow>
                                //   ))
                            }
                        </CTableBody>
                    </CTable>
                </CCardBody>
            </CCard>
        </div>
    </>
}

export default UserManagement