import React from 'react'
import { createBrowserRouter, useLocation, Navigate } from "react-router-dom";
import Login from './views/pages/login/Login';
import Dashboard from './views/admin/dashboard/Dashboard';
import DefaultLayout from './layout/DefaultLayout';
import Invitation from './views/admin/invitation/Invitation';
import Promoters from './views/admin/promoter/Promoters';
import CodeManagement from './views/admin/codemanagement/CodeManagement';
import Brading from './views/admin/branding/Branding';
import CodeTemplete from './views/admin/codingTemplate/CodeTemplate';
import BroadCast from './views/admin/broadcast/BroadCast';
import CodeTemplateMap from './views/admin/templateMap/CodeTemplateMap';
import NewBroadcast from './views/admin/broadcast/NewBroadcast';
import PrivacyPolicy from './views/admin/privacypolicy/PrivacyPolicy';
import Term from './views/admin/term/Term';
import AddCodeTemplate from './views/admin/addCodeTemplate/AddCodeTemplate'
import AddCodeButton from './views/admin/codemanagement/AddCode'
import IndustryManagement from './views/admin/industry';
import UserManagement from './views/admin/userDetails/UserManagement';
import Branding from './views/admin/promoter/Branding'
import Finance from './views/admin/financial/List'

// Base
// const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
// const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
// const Cards = React.lazy(() => import('./views/base/cards/Cards'))
// const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
// const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
// const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
// const Navs = React.lazy(() => import('./views/base/navs/Navs'))
// const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
// const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
// const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
// const Progress = React.lazy(() => import('./views/base/progress/Progress'))
// const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
// const Tables = React.lazy(() => import('./views/base/tables/Tables'))
// const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))
// Buttons
// const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
// const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
// const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
// const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
// const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
// const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
// const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
// const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
// const Range = React.lazy(() => import('./views/forms/range/Range'))
// const Select = React.lazy(() => import('./views/forms/select/Select'))
// const Validation = React.lazy(() => import('./views/forms/validation/Validation'))
// const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

// const routes = [
//   { path: '/', exact: true, name: 'Home' },
//   { path: '/dashboard', name: 'Dashboard', element: Dashboard },
//   { path: '/invitation', name: 'Invitation', element: Invitation },
//   { path: '/promoters', name: 'Promoters', element: Promoters },
//   { path: '/codemanagement', name: 'CodeManagement', element: CodeManagement },
//   { path: '/codemanagement/addcode', name: 'AddCodeButton', element: AddCodeButton },
//   { path: '/codingtemplate', name: 'CodeTemplate', element: CodeTemplate },
//   { path: '/codingtemplate/addcodeTemplate', name: 'AddCodeTemplate', element: AddCodeTemplate },
//   { path: '/codingtemplateMap', name: 'CodeTemplateMap', element: CodeTemplateMap },
//   { path: '/broadcast', name: 'BroadCast', element: BroadCast },
//   { path: '/broadcast/newbroadcast', name: 'NewBroadCast', element: NewBroadCast },
//   { path: '/branding', name: 'Branding', element: Branding },
//   { path: '/term', name: 'Term', element: Term },
//   { path: '/privacypolicy', name: 'PrivacyPolicy', element: PrivacyPolicy },
//   { path: '/signup', name: 'Signup', element: Signup },
//   { path: '/verifyOtp', name: 'VerifyOtp', element: VerifyOtp },
//   { path: '/thankyou', name: 'Thankyou', element: Thankyou },
//   { path: '/theme', name: 'Theme', element: Colors, exact: true },
//   { path: '/theme/colors', name: 'Colors', element: Colors },
//   { path: '/theme/typography', name: 'Typography', element: Typography },
//   { path: '/base', name: 'Base', element: Cards, exact: true },
//   { path: '/base/accordion', name: 'Accordion', element: Accordion },
//   { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
//   { path: '/base/cards', name: 'Cards', element: Cards },
//   { path: '/base/carousels', name: 'Carousel', element: Carousels },
//   { path: '/base/collapses', name: 'Collapse', element: Collapses },
//   { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
//   { path: '/base/navs', name: 'Navs', element: Navs },
//   { path: '/base/paginations', name: 'Paginations', element: Paginations },
//   { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
//   { path: '/base/popovers', name: 'Popovers', element: Popovers },
//   { path: '/base/progress', name: 'Progress', element: Progress },
//   { path: '/base/spinners', name: 'Spinners', element: Spinners },
//   { path: '/base/tables', name: 'Tables', element: Tables },
//   { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
//   { path: '/buttons', name: 'Buttons', element: Buttons, exact: true },
//   { path: '/buttons/buttons', name: 'Buttons', element: Buttons },
//   { path: '/buttons/dropdowns', name: 'Dropdowns', element: Dropdowns },
//   { path: '/buttons/button-groups', name: 'Button Groups', element: ButtonGroups },
//   { path: '/charts', name: 'Charts', element: Charts },
//   { path: '/forms', name: 'Forms', element: FormControl, exact: true },
//   { path: '/forms/form-control', name: 'Form Control', element: FormControl },
//   { path: '/forms/select', name: 'Select', element: Select },
//   { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
//   { path: '/forms/range', name: 'Range', element: Range },
//   { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
//   { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
//   { path: '/forms/layout', name: 'Layout', element: Layout },
//   { path: '/forms/validation', name: 'Validation', element: Validation },
//   { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
//   { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
//   { path: '/icons/flags', name: 'Flags', element: Flags },
//   { path: '/icons/brands', name: 'Brands', element: Brands },
//   { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
//   { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
//   { path: '/notifications/badges', name: 'Badges', element: Badges },
//   { path: '/notifications/modals', name: 'Modals', element: Modals },
//   { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
//   { path: '/widgets', name: 'Widgets', element: Widgets },
// ]

// export default routes

const ProtectedRoute = (children) => {
  const token = localStorage.getItem('token');
  let location = useLocation();

  if(token) {
    return children?.children
  }
  return <Navigate to="/" state={{ from: location}} replace />
};

const UnProtectedRoute = (children) => {
  const token = localStorage.getItem('token');
  let location = useLocation();

  if(token) {
    return <Navigate to="/dashboard" state={{ from: location}} replace />
  }
  return children?.children
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <UnProtectedRoute><Login /></UnProtectedRoute>
  },
  {
    path: "/",
    element: <ProtectedRoute><DefaultLayout /></ProtectedRoute>,
    children: [
      {
        path: "dashboard",
        element: <ProtectedRoute><Dashboard /></ProtectedRoute>,
      },
      {
        path: "brandings",
        element: <ProtectedRoute><Branding /></ProtectedRoute>,
      },
      {
        path: "user",
        element: <ProtectedRoute><UserManagement /></ProtectedRoute>,
      },
      {
        path: "invitation",
        element: <ProtectedRoute><Invitation /></ProtectedRoute>,
      },
      {
        path:"Promoters",
        element: <ProtectedRoute><Promoters /></ProtectedRoute>,
      },
      {
        path:"branding",
        element: <ProtectedRoute><Brading /></ProtectedRoute>,
      },
      {
        path:"codemanagement",
        element: <ProtectedRoute><CodeManagement /></ProtectedRoute>,
      },
      {
        path:"codingtemplate",
        element: <ProtectedRoute><CodeTemplete /></ProtectedRoute>,
      },
      {
        path:"codingtemplateMap",
        element: <ProtectedRoute><CodeTemplateMap /></ProtectedRoute>,
      },
      {
        path:"broadcast",
        element: <ProtectedRoute><BroadCast /></ProtectedRoute>,
      },
      {
        path:"industry",
        element: <ProtectedRoute><IndustryManagement /></ProtectedRoute>,
      },
      {
        path:"privacypolicy",
        element: <ProtectedRoute><PrivacyPolicy /></ProtectedRoute>,
      },
      {
        path:"term",
        element: <ProtectedRoute><Term /></ProtectedRoute>,
      },
      {
        path: "addcode",
        element: <ProtectedRoute>< AddCodeButton/></ProtectedRoute>,
      },
      {
        path: "NewBroadcast",
        element: <ProtectedRoute><NewBroadcast/></ProtectedRoute>,
      },
      {
        path:'newbroadcast',
        element:<ProtectedRoute> <NewBroadcast/></ProtectedRoute>
      },
      {
        path:'invitations',
        element:<ProtectedRoute><Finance/></ProtectedRoute> 
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]);

export default router;
