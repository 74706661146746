import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CImage,
  CCol,
  CRow,
  CAvatar,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'
import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import logo from 'src/assets/brand/logo.png'
import { help, notification } from './Images/Images';

const AppHeader = ({toggleSidebar}) => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CHeader position="fixed" className="mb-4" style={{backgroundColor:'#FFFFFF'}}>
      <CContainer fluid>
        <CImage src={logo} className='logotop' />
        <CHeaderToggler
          className="ps-1 ms-4"
          onClick={() => {
            toggleSidebar();
            dispatch({ type: 'set', sidebarShow: !sidebarShow });
          }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler >
        <CHeaderBrand className="mx-auto d-md-none" to="/" >
          {/* <CImage src={logo} /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
           {/* <CNavItem>
            <CNavLink to="/dashboard" component={NavLink} >
              Dashboard
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>  */}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              {/* <CIcon icon={cilBell} size="lg" /> */}
              <CImage src={help} width="26px" height="26px"/>
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              {/* <CIcon icon={cilBell} size="lg" /> */}
              <CImage src={notification} width="21px" height="26px"/>
            </CNavLink>
          </CNavItem>
        {/*   <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
          {/* <CRow >
        <CCol xs="auto">
          <CImage src={notification} width="34px" height="34px" />
        </CCol>
        <CCol>
          <div className="font-weight-bold" style={{color:'#333333'}}>Jone cooper</div>
          <div className="text-muted" style={{ fontSize: '0.85em' }}>Developer</div>
        </CCol>

      </CRow> */}
        <CHeaderNav >
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
     */}
    </CHeader>
  )
}
AppHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default AppHeader
