import React from 'react'
import {
  CButton,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
} from '@coreui/react'

const Brading = () => {
  return (
    <>
      <div>
        <CContainer>
          <CRow>
            <CCol md={6}>
              <CCardGroup>
                <CForm>
                  <h4 className="mb-3">Over Zero Branding</h4>
                  <CFormInput
                    type="title"
                    name="title"
                    label="Branding Color"
                    aria-describedby="exampleFormControlInputHelpInline"
                    //  value={title}
                    //  onChange={handleChange}
                    className="mb-3"
                  />
                  <CFormInput
                    type="title"
                    name="title"
                    label="Navigation Color"
                    aria-describedby="exampleFormControlInputHelpInline"
                    //  value={title}
                    //  onChange={handleChange}
                    className="mb-3"
                  />
                  <CFormInput
                    type="title"
                    name="title"
                    label="Header & Footer Color"
                    aria-describedby="exampleFormControlInputHelpInline"
                    //  value={title}
                    //  onChange={handleChange}
                    className="mb-3"

                  />
                  <CRow >
                    <CCol   >
                      <CButton color="primary" className="px-4" 
                      // onClick={handleLogin} 
                      >
                        Save
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardGroup>
            </CCol>
            <CCol md={6}>
               <div className='mt-3 mb-2'>
                  <CFormInput
                    type="file"
                    id="formFile"
                    // label="Default file input example"
                    // onChange={handleFileChange}
                  />
                </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Brading