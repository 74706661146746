import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormLabel,
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CFormGroup, CLabel, CSelect, CFormSelect, CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import calendar from '../../../assets/images/calendar.png'


const CodeTemplateMap = () => {

  // Initialize state for the title
  const [title, setTitle] = useState('');

  // Handler function to update the title state when the input changes
  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (

    <div className='mb-2'>
      <CCard style={{
        width: "-webkit-fill-available",
        height: " 466px", border: "1px solid #D9D9D9",
        borderRadius: "25px",
        opacity: 1
      }}>
        <CCardBody className='m-3'>
          <CContainer>
            
                <CCardGroup>
                  <CForm>
                    <h5 >Coding Template Map</h5>
                    {/* <h6 className="mt-4">Name of the Template</h6>
                <CDropdown style={{ width: '150px' }}>
                  <CDropdownToggle placeholder='Enter Template'>{selectedItem || 'Select Template'}</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => handleItemClick('Transport')}>Transport</CDropdownItem>
                    <CDropdownItem onClick={() => handleItemClick('Garage')}>Garage</CDropdownItem>
                    <CDropdownItem onClick={() => handleItemClick('Salon')}>Salon</CDropdownItem>
                  </CDropdownMenu>
                  </CDropdown> */}
                    <CRow className='mb-2'>
                      <CCol md={4}>
                        <CFormSelect
                          label="Name of the Template"
                        // value={broadcastData.recipients}
                        // onChange={(e) => setBroadcastData((prevData) => ({ ...prevData, recipients: e.target.value }))}
                        >
                          <option value="">Enter account name</option>
                          <option value="option1">Option 1</option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option>
                        </CFormSelect>
                      </CCol>
                      <CCol md={4}>
                      <label className='mb-2' htmlFor="fromDate">From date</label>
                        <DatePicker
                          // selected={broadcastData.dateTime || new Date()}
                          // onChange={(date) => setBroadcastData((prevData) => ({ ...prevData, dateTime: date }))}
                          showTimeSelect
                          dateFormat="yyyy/MM/dd hh:mm aa"
                          // label="From date"
                          // customInput={<CustomDatePickerInput />}
                        />
                      </CCol>
                      <CCol md={4}>
                      <label className='mb-2' htmlFor="fromDate">To date</label>
                        <DatePicker
                          // selected={broadcastData.dateTime || new Date()}
                          // onChange={(date) => setBroadcastData((prevData) => ({ ...prevData, dateTime: date }))}
                          showTimeSelect
                          dateFormat="yyyy/MM/dd hh:mm aa"
                         
                          // customInput={<CustomDatePickerInput />}
                        />
                      </CCol>
                    </CRow>

                    <CRow className='mt-3'>
                      <CCol md="4" style={{ marginBottom: '15px' }}>
                        <CFormInput
                          type="text"
                          name="code"
                          label="code"
                          placeholder="Fuel"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                      <CCol md="4" style={{ marginBottom: '15px', }}>
                        <CFormInput
                          type="text"
                          name="catagory"
                          label="catagory"
                          placeholder="P & L"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                      <CCol md="4" style={{ marginBottom: '15px' }}>
                        <CFormInput
                          type="text"
                          // name="code"
                          label="Journal Code"
                          placeholder="Enter code"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                    </CRow>

                    <CRow className='mt-3'>
                      <CCol md="4" style={{ marginBottom: '15px', }}>
                        <CFormInput
                          type="text"
                          name="code"
                          label="code"
                          placeholder="Fuel"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                      <CCol md="4" style={{ marginBottom: '15px' }}>
                        <CFormInput
                          type="text"
                          name="catagory"
                          label="catagory"
                          placeholder="P & L"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                      <CCol md="4" style={{ marginBottom: '15px', }}>
                        <CFormInput
                          type="text"
                          // name="code"
                          label="Journal Code"
                          placeholder="Enter code"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                    </CRow>

                    <CRow className='mt-3'>
                      <CCol md="4" style={{ marginBottom: '15px', }}>
                        <CFormInput
                          type="text"
                          name="code"
                          label="code"
                          placeholder="Fuel"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                      <CCol md="4" style={{ marginBottom: '15px', }}>
                        <CFormInput
                          type="text"
                          name="catagory"
                          label="catagory"
                          placeholder="P & L"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                      <CCol md="4" style={{ marginBottom: '15px', }}>
                        <CFormInput
                          type="text"
                          // name="code"
                          label="Journal Code"
                          placeholder="Enter code"
                          // text="Must be 8-20 characters long."
                          aria-describedby="exampleFormControlInputHelpInline"
                        // value={title}
                        // onChange={handleChange}
                        />
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardGroup>
          </CContainer>
        </CCardBody>
      </CCard>
    </div>
  )
}

export default CodeTemplateMap

// const CustomDatePickerInput = ({ value, onClick }) => (
//   <div className="custom-datepicker-input"
//    onClick={onClick}>
//     {/* {value} */}5346564
  
//     <CImage src={calendar} alt="Cylinder Icon" className="cylinder-icon" />
//   </div>
// );
