import React, { useState, useEffect, useMemo } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCol, CRow, CSpinner } from '@coreui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import { postApiWithHeader, UpdateUserwithHeader } from 'src/Api/Api';
import { toast } from 'react-toastify';

const AddInvitation = (props) => {
  const accessToken = localStorage.getItem('token');
  const companyData = localStorage.getItem('userInfo')
  const editValue = props.editValue
  const headers = useMemo(() => ({ Authorization: `Bearer ${accessToken}` }), [accessToken]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    receiverName: '',
    receiverMobile: '',
    role: 'FinancialBroker',
    DeviceType: 'web',
    companyID: JSON.parse(companyData).companyID
  });


  AddInvitation.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.object,
    clearFormData: PropTypes.bool,
  };


  // useEffect(() => {
  //   // if (editValue) {
  //     setFormData({
  //       receiverName: props.editValue.receiverName || '',
  //       receiverMobile: props.editValue.receiverMobile || '',
  //       role: 'FinancialBroker',
  //       DeviceType: 'web'
  //     });
  //   }
  // }, [editValue]);


  useEffect(() => {
    if (props.clearFormData) {
      resetForm();
      props.handleClose();
    }
  }, [props.clearFormData]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handleOnChange = (value, country) => {
    const sanitizedPhoneNumber = value.replace(/^0+/, '');
    const formattedPhoneNumber = `+${sanitizedPhoneNumber}`;
    setFormData({
      ...formData,
      receiverMobile: formattedPhoneNumber
    });
    setErrors({
      ...errors,
      receiverMobile: ''
    });
  };


  const resetForm = () => {
    setFormData({
      receiverName: editValue ? editValue.receiverName : '',
      receiverMobile: editValue ? editValue.receiverMobile : '',
      role: 'FinancialBroker',
      DeviceType: 'web',
      companyID: JSON.parse(companyData).companyID
    });
    setErrors({})
  }


  const handleAdd = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const validationErrors = {};
    if (!formData.receiverName.trim()) {
      validationErrors.receiverName = 'User name is required';
    }
    if (!formData.receiverMobile.trim()) {
      validationErrors.receiverMobile = 'Mobile number is required';
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      // if (!editValue) {
      const response = await postApiWithHeader('/api/invitation/create', formData, headers);
      console.log('response',response)
      if (response.data && response.data.data) {
        setIsLoading(false)
        props.apiCall();
        props.handleClose();
        toast.success('Added Successfully',{autoClose:1000})
      } else {
        console.error('Unexpected response format:', response);
      }
      // }
      // else {
      //   response = await UpdateUserwithHeader(`/api/invitation/update/${editValue.id}`, formData, headers);
      //   if (response.message === 'Success') {
      //     props.apiCall();
      //     props.handleClose();
      //     toast.success('Updated Successfully')
      //   } else {
      //     console.error('Unexpected response format:', response);
      //   }
      // }
    } catch (error) {
      console.error('Error in POST request:', error);
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <CForm onSubmit={handleAdd}>
      <div className="mb-3">
        <CFormLabel>User Name<span className="text-danger">*</span></CFormLabel>
        <CFormInput
          type="text"
          name="receiverName"
          value={formData.receiverName}
          onChange={handleInputChange}
          className={errors.receiverName ? 'is-invalid' : ''}
        />
        {errors.receiverName && <div className="invalid-feedback">{errors.receiverName}</div>}
      </div>
      <div className='mb-3'>
        <CFormLabel>
          Mobile Number <span className="text-danger">*</span>
        </CFormLabel>
        <PhoneInput
          name="receiverMobile"
          country={"au"}
          onChange={(value, country) => handleOnChange(value, country)}
          value={formData.receiverMobile}
          inputStyle={{ width: '100%' }}
          className={errors.receiverMobile ? 'is-invalid' : ''}
        />
        {errors.receiverMobile && <div className="invalid-feedback">{errors.receiverMobile}</div>}
      </div>
      <CRow className="mt-4 justify-content-end">
        <CCol xs="auto">
          <CButton color="light" onClick={() => {
            resetForm()
            props.handleClose()
          }}
          >
            Cancel
          </CButton>
        </CCol>
        <CCol xs="auto">
          <CButton type="submit" color="primary" className='mb-2'>
            {isLoading ? <CSpinner/> : 'Save'}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
}

export default AddInvitation;
