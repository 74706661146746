import React, { useCallback, useState } from 'react';
import { CButton, CCol, CRow } from '@coreui/react';
import { useDropzone } from 'react-dropzone';
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Import(props) {
  const [fileName, setfileName] = useState('')
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    props?.callBackFile(file)
  };

  console.log('fileName',fileName)

  const parseCSVData = useCallback((csvData) => {
    const rows = csvData.split('\n');
    const headers = rows[0].split(',').map((header) => header);
    props.callBack(headers);
  }, [props.callBack]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file || file.type !== 'text/csv') {
      toast.error('Please upload a .CSV file.');
      return;
    }

    const reader = new FileReader();
    setfileName(file?.path)
    props?.callBackFile(file)

    if (typeof props.handleCloseClick === 'function') {
      props.handleCloseClick();
    }
    reader.onload = (event) => {
      const fileData = event.target.result;
      parseCSVData(fileData);
    };
    reader.readAsText(file);
  }, [props.handleCloseClick, parseCSVData]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv', // Only accept .csv files
  });

  return (
    <div className="page">
      <CRow className="mb-2">
        <CCol md={12} className="d-flex flex-column align-items-center">
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? 'active' : ''}`}
            style={{ padding: '20px', border: 'dashed' }}
          >
            <input {...getInputProps()} onChange={handleFileChange} />
            <CIcon icon="cilCloudUpload" size="4xl" className="mb-2" />
            <CButton color="primary">Select a CSV file to import</CButton>
            <p className="mt-2 text-center">or drag and drop here</p>
            <h5>{fileName}</h5>
          </div>
        </CCol>
      </CRow>
      <div className="mt-auto d-flex justify-content-end">
        {
          props.handleUpload ?
            <CButton onClick={props.handleUpload}>Upload</CButton>
            : ''
        }
      </div>
    </div>
  )
}

Import.propTypes = {
  callBack: PropTypes.func.isRequired,
  callBackFile: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired
};

export default Import;







