import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHeaderCell,
    CTableHead,
    CTableRow,
    CButton,
    CCardBody,
    CCard,
    CImage,
    CModalBody,
    CModalHeader,
    CModalFooter,
    CModal,
    CTooltip,
  } from '@coreui/react'
  import { CilArrowBottom } from '@coreui/icons-react'
  import CIcon from '@coreui/icons-react'
  import React, { useEffect, useState, useMemo } from 'react'
  import { DeleteApiwithHeader, GetApiwithHeader } from 'src/Api/Api'
  import { deleteIcon, edit, download } from 'src/components/Images/Images'
  import OffCanvas from '../../../components/share/canvas'
  import Add from './Add'
  import FileUpload from '../../../components/share/import'
  import { postApiWithHeader } from 'src/Api/Api';
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  
  
  const Invitation = () => {
    const [services, setServices] = useState([])
    const [editValue, setEditValue] = useState()
    const [visible, setVisible] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isUploadModel, setIsUploadModel] = useState(false)
    const [csvData, setCsvData] = useState()
    const [importFile, setFileImport] = useState()
    const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15)
    const accessToken = localStorage.getItem('token')
    const companyData = localStorage.getItem('userInfo')
    let companyId =  JSON.parse(companyData).companyID
  
    const headers = useMemo(
      () => ({
        Authorization: `Bearer ${accessToken}`,
      }),
      [accessToken],
    )
  
    const headersFile = useMemo(
      () => ({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      }),
      [accessToken],
    )
  
  
    const fetchData = async () => {
      try {
        const res = await GetApiwithHeader(`/api/auth/getuserbyrole?role=FinancialBroker&companyId=${companyId}`, headers)
        setServices(res?.data?.data || [])
      } catch (error) {
        console.log(error)
      }
    }
  
    useEffect(() => {
      fetchData()
    }, [headers, companyId])
  
  
    const handleAddClick = () => {
      setEditValue()
      setVisible(true)
    }
  
  
    const callBack = (data) => {
      setCsvData(data)
    }
  
  
    const callBackFile = (data) => {
      setFileImport(data)
    }
  
    // const handleUpload = () => {
    //   console.log('ee')
    //   setIsUploadModel(true)
    // }
  
    const handleCloseClick = () => {
      setVisible(false)
      setClearFormDataFlag((prev) => !prev);
      setEditValue()
  
    }
  
    const handleEditClick = (i) => {
      setEditValue(i)
      setVisible(true)
    }
  
   
    const handleDelete = (i) => {
      setEditValue(i)
      setIsModalVisible(true)
  
    }
  
    const handleDeleteClick = async (id) => {
      const apiUrl = `/api/invitation/delete/${id},${companyId}`
      try {
        const response = await DeleteApiwithHeader(apiUrl, headers)
        if (response.message === 'Success') {
          setIsModalVisible(false)
          // Fetch data again after deletion
          fetchData()
          toast.success("Deleted Successfully",{autoClose:1000});
        } else {
          console.error('Unexpected response format:', response)
        }
      } catch (error) {
        console.error('Delete API Request Error:', error)
      }
    }
  
    const handleSampleDownload = async (item) => {
      try {
        const res = await GetApiwithHeader(
          `/api/BankTransactions/GetCsv/0,OverZero_Invitee_List.csv`,
          headers,
        )
        const fileUrl = res?.data?.data?.url
        console.log('res?.data?.data?.url', res?.data?.data?.url)
        if (fileUrl) {
          window.open(fileUrl, '_blank')
        } else {
          console.log('File URL not found')
        }
        // console.log("view", res?.data?.data?.url);
      } catch (error) {
        console.log(error)
      }
    }
  
    // const handleUploads = () => {
    //   const formData = new FormData();
    //   formData.append("file", importFile);
    //   // console.log('importFile', importFile)
    // }
  
    const handleUploads = async () => {
      const formData = new FormData();
      formData.append("file", importFile);
      try {
        const response = await postApiWithHeader(
          `/api/invitation/invitation`,
          formData,
          headersFile
        );
        console.log('response', response)
        if (response) {
          fetchData()
          setIsUploadModel(false)
          // toast.success('File Uploaded Sucessfully')
        }
        // return response;
      } catch (error) {
        console.error('Error creating file:', error)
        throw error // Re-throwing the error to propagate it further if necessary
      }
    }
  
    const handleSort = (key) => {
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    const sortedItems = () => {
      const sortableItems = [...services];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    };
  
    const handlePrevPage = (e) => {
      setCurrentPage(currentPage - 1);
    };
  
    // Function to handle next page
    const handleNextPage = (e) => {
      setCurrentPage(currentPage + 1);
    };
  
    const totalPages = Math.ceil(services?.length / itemsPerPage);
    // console.log('sortedItems', sortedItems())
    // console.log('services', services)
    // Function to get the current page's data
    const getCurrentPageData = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return services ? sortedItems()?.slice(startIndex, endIndex) : "";
    };
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    return (
      <>
        <div className="cardBody">
          <ToastContainer position="top-right" />
          <CCard >
            <CCardBody className="m-3">
              <div className="cardHeader">
                <h3>Financial broker invitation log</h3>
                <div className="mb-6">
                  <CButton className='mr-1' onClick={() => handleAddClick()}>Add Invitation</CButton>
                  {/* <CButton onClick={() => setIsUploadModel(!isUploadModel)} >Import</CButton>
                  <CTooltip content="Sample Download">
                    <button className="editIcon">
                      <CImage
                        src={download}
                        onClick={handleSampleDownload}
                      />
                    </button>
                  </CTooltip> */}
                </div>
              </div>
              <div className="cardTable">
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell 
                      scope="col"
                      className="col-gap"
                      >
                        S.No
                        </CTableHeaderCell>
                      <CTableHeaderCell 
                      scope="col"
                      onClick={() => handleSort('receiverName')}
                      >
                        User Name
                        </CTableHeaderCell>
                      <CTableHeaderCell 
                      scope="col"
                      onClick={() => handleSort('receiverMobile')}
                      >
                        Mobile Number
                      </CTableHeaderCell>
                      <CTableHeaderCell 
                      scope="col"
                      onClick={() => handleSort('status')}
                      >
                        Status
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {getCurrentPageData()?.map((i, index) => (
                     <CTableRow key={index}>
                   {/*     <CTableDataCell className="col-gap" scope="row">
                          {index + 1}
                    </CTableDataCell>*/}
                      <CTableDataCell>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </CTableDataCell>
                        <CTableDataCell style={{ alignItems: 'center' }}>
                          {i.receiverName}
                        </CTableDataCell>
                        <CTableDataCell>{i.receiverMobile}</CTableDataCell>
                        <CTableDataCell>{i.status}</CTableDataCell>
                        <CTableDataCell>
                          {/* <CTooltip content="Edit">
                            <button className="editIcon">
                              <CImage
                                src={edit}
                                onClick={() => handleEditClick(i)}
                              />
                            </button>
                          </CTooltip> */}
                          <CTooltip content="Delete">
                            <button className="deleteIcon">
                              <CImage
                                src={deleteIcon}
                                onClick={() => handleDelete(i)}
                              />
                            </button>
                          </CTooltip>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                     
                  </CTableBody>
                </CTable>
              </div>
            </CCardBody>
            <nav>
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <button className="page-link" onClick={handlePrevPage}>
                            Previous
                          </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                          >
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                        >
                          <button className="page-link" onClick={handleNextPage}>
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
          </CCard>
          <OffCanvas
            Open={visible}
            title={editValue ? 'Edit Invitation' : 'Add Invitation'}
            handleCloseClick={handleCloseClick}
            component={
              <Add
                apiCall={fetchData}
                handleClose={handleCloseClick}
                editValue={editValue}
                clearFormData={clearFormDataFlag}
              />
            }
          />
          <CModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            aria-labelledby="ScrollingLongContentExampleLabel"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'auto',
            }}
          >
            <CModalHeader>
              <h3>Delete</h3>
            </CModalHeader>
            <CModalBody>Are you sure want to delete {editValue?.receiverName}...?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
                No
              </CButton>
              <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
                Yes
              </CButton>
            </CModalFooter>
          </CModal>
          <CModal
            alignment="center"
            visible={isUploadModel}
            onClose={() => setIsUploadModel(false)}
            aria-labelledby="VerticallyCenteredExample"
          >
            <CModalHeader></CModalHeader>
            <CModalBody>
              <FileUpload
                handleCloseClick={handleCloseClick}
                callBackFile={callBackFile}
                callBack={callBack}
                handleUpload={handleUploads}
              />
            </CModalBody>
          </CModal>
        </div>
      </>
    )
  }
  
  export default Invitation
  