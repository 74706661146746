import React, { useCallback, useState } from 'react'
import {
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CForm,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import CIcon from '@coreui/icons-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { cilCloudUpload } from '@coreui/icons'

const Add = (props) => {
  Add.propTypes = {
    apiCall: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    editValue: PropTypes.string,
    items: PropTypes.string,
  }

  const accessToken = localStorage.getItem('token')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  }
  const [selectedDate, setSelectedDate] = useState(null)

  const [broadcastData, setBroadcastData] = useState({
    broadcastName: '',
    messageFormat: 'Plain Text',
    subject: '',
    fromAddress: '',
    recipients: '',
    occurrence: 'Once',
    dateTime: '',
    description: '',
    selectedFile: null,
  })
  const onDrop = useCallback((acceptedFiles) => {
    setBroadcastData((prevData) => ({ ...prevData, selectedFile: acceptedFiles[0] }))
    console.log('Accepted Files:', acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf',
  })

  const handleAdd = () => {

  }

  return (
    <CRow className="mb-2">
      <CCol>
        <CForm>
            <CCol md={12}>
              <CFormInput
                type="text"
                name="broadcastName"
                label="Broadcast name"
                value={broadcastData.broadcastName}
                onChange={(e) =>
                  setBroadcastData((prevData) => ({ ...prevData, broadcastName: e.target.value }))
                }
              />
            </CCol>
          <CCol md={6}>
            <CFormLabel>Message Format</CFormLabel>
            <CFormCheck
              inline
              type="radio"
              name="messageFormat"
              id="inlineCheckbox1"
              label="Plain Text"
              checked={broadcastData.messageFormat === 'Plain Text'}
              onChange={() =>
                setBroadcastData((prevData) => ({ ...prevData, messageFormat: 'Plain Text' }))
              }
            />
            <CFormCheck
              inline
              type="radio"
              name="messageFormat"
              id="inlineCheckbox2"
              label="HTML"
              checked={broadcastData.messageFormat === 'HTML'}
              onChange={() =>
                setBroadcastData((prevData) => ({ ...prevData, messageFormat: 'HTML' }))
              }
            />
          </CCol>
          <CCol md={12}>
            <CFormInput
              type="text"
              name="subject"
              label="Subject"
              placeholder="Enter subject "
              value={broadcastData.subject}
              onChange={(e) =>
                setBroadcastData((prevData) => ({ ...prevData, subject: e.target.value }))
              }
            />{' '}
          </CCol>
          <CCol md={12}>
            <CFormInput
              type="text"
              name="fromAddress"
              label="Email address"
              placeholder="Enter email address"
              value={broadcastData.fromAddress}
              onChange={(e) =>
                setBroadcastData((prevData) => ({ ...prevData, fromAddress: e.target.value }))
              }
            />
          </CCol>
          <CCol md={12}>
            <CFormSelect
              label="Recipients"
              value={broadcastData.recipients}
              onChange={(e) =>
                setBroadcastData((prevData) => ({ ...prevData, recipients: e.target.value }))
              }
            >
              <option value="">Enter account name</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </CFormSelect>
          </CCol>
          <CCol md={12}>
            <CFormLabel>Occurance</CFormLabel>
            <div>
              <CFormCheck
                inline
                type="radio"
                name="occurrence"
                id="inlineCheckbox1"
                value="Once"
                label="Once"
                checked={broadcastData.occurrence === 'Once'}
                onChange={() =>
                  setBroadcastData((prevData) => ({ ...prevData, occurrence: 'Once' }))
                }
              />
              <CFormCheck
                inline
                type="radio"
                name="occurrence"
                id="inlineCheckbox2"
                value="Daily"
                label="Daily"
                checked={broadcastData.occurrence === 'Daily'}
                onChange={() =>
                  setBroadcastData((prevData) => ({ ...prevData, occurrence: 'Daily' }))
                }
              />
              <CFormCheck
                inline
                type="radio"
                name="occurrence"
                id="inlineCheckbox3"
                value="Week"
                label="Week"
                checked={broadcastData.occurrence === 'Week'}
                onChange={() =>
                  setBroadcastData((prevData) => ({ ...prevData, occurrence: 'Week' }))
                }
              />
              <CFormCheck
                inline
                type="radio"
                name="occurrence"
                id="inlineCheckbox4"
                value="Monthly"
                label="Monthly"
                checked={broadcastData.occurrence === 'Monthly'}
                onChange={() =>
                  setBroadcastData((prevData) => ({ ...prevData, occurrence: 'Monthly' }))
                }
              />
            </div>
          </CCol>
          <CCol md={12}>
            <CFormLabel>Select Date</CFormLabel>
            <DatePicker
              id="datePicker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MM/dd/yyyy" 
              locale="en-US"
            />
            {/* <CImage src={calendar} /> */}
          </CCol>
          <CFormTextarea
            id="exampleFormControlTextarea1"
            label="Description"
            rows={3}
            text="Must be 8-20 words long."
            value={broadcastData.description}
            onChange={(e) =>
              setBroadcastData((prevData) => ({ ...prevData, description: e.target.value }))
            }
          ></CFormTextarea>

          <CCol md={12} className="d-flex flex-column align-items-center">
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'active' : ''}`}
              style={{ padding: '20px', border: 'dashed' }}
            >
              <input {...getInputProps()} />
              <CIcon icon={cilCloudUpload} size="4xl" className="mb-2" />
              <CButton color="primary">Select a CSV file to import</CButton>
              <p className="mt-2 text-center">or drag and drop here</p>
            </div>
          </CCol>
        </CForm>
        <CRow className="mt-4 justify-content-end">
          <CCol xs="auto">
            <CButton color="light" onClick={() => props.handleClose()}>
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            <CButton color="primary" onClick={handleAdd} style={{ textTransform: 'none' }}>
              Save
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  )
}

export default Add
