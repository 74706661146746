import React, { Component, Suspense } from 'react'
import './scss/style.scss'
import {
  RouterProvider,
} from "react-router-dom";
import router from './routes';
import DefaultLayout from './layout/DefaultLayout';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  render() {
    return (<>    
      <div className="wrapper" >
      <RouterProvider router={router} />
    </div>
    </>

    )
  }
}

export default App
