import React, { useEffect, useMemo, useState } from 'react';
import {

  CButton,
  CModal,
  CModalBody,
  CCardBody,
  CModalFooter,
  CCard,
  CModalHeader,
} from '@coreui/react'
import { DataGrid } from '@mui/x-data-grid';
import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api';
import OffCanvas from '../../../components/share/canvas';
import Add from './Add';
import { ToastContainer, toast } from 'react-toastify';

const IndustryManagement = () => {
  const [editValue, setEditValue] = useState();
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15); // Number of items per page
  const [sortModel, setSortModel] = useState([]);
  const [loadingState, setLoadingState] = useState(true);

  const accessToken = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const filteredItems = useMemo(() => {
    return industry.filter(
      (item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.createdAt.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [industry, searchQuery]);

  const fetchData = async () => {
    const apiUrl = '/api/industry';
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      setLoadingState(false);
      setIndustry(res?.data?.data || []);
    } catch (error) {
      setLoadingState(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClick = async (id) => {
    const apiUrl = `/api/industry/delete/${id}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === 'Success') {
        setIsModalVisible(false);
        const updatedIndustry = industry.filter((entry) => entry.id !== id);
        setIndustry(updatedIndustry);
        toast.success('Deleted Successfully', { autoClose: 1000 });
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Delete API Request Error:', error);
    }
  };

  const handleAddClick = () => {
    setEditValue();
    setVisible(true);
  };

  const handleCloseClick = () => {
    setVisible(false);
    setClearFormDataFlag((prev) => !prev);
    setEditValue();
  };

  const handleEditClick = (item) => {
    setEditValue(item);
    setVisible(true);
  };

  const handleDelete = (item) => {
    setEditValue(item);
    setIsModalVisible(true);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };

  const columns = [
    // { field: 'id', headerName: 'S.No', width: 120 },
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      headerClassName: 'dataGridHeader'
    },
    { field: 'title', headerName: 'Title', flex: 1, sortable: true ,headerClassName: 'dataGridHeader'},
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      sortable: true,
      headerClassName: 'dataGridHeader',
      valueFormatter: (params) =>  {
       return new Date(params).toLocaleDateString("en-GB")
      }
    },
    {
      field: 'actions',
      headerName: 'Action',
      width: 200,
      headerClassName: 'dataGridHeader',
      renderCell: (params) => (
        <>
          <div className="rowIcon">
            <button className="icon-editIcon" onClick={() => handleEditClick(params.row)}>
            </button>
            <button className="icon-deleteIcon" onClick={() => handleDelete(params.row)}>
            </button>
          </div>
        </>
      ),
    },
  ];

  const rows = filteredItems.map((item, index) => ({
    id: (currentPage - 1) * pageSize + index + 1,
    ...item,
  }));

  return (
    <>
      <div className="cardBody">
        <ToastContainer position="top-right" />
        <CCard>
          <CCardBody className="m-2">
            <div className="cardHeader">
              <h3>Industry</h3>
              <div className="mr-1">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
                  // Adding Bootstrap class for styling
                />
              <CButton style={{ marginLeft: '50px' }} onClick={() => handleAddClick()}>
                Add Industry
              </CButton>
              </div>
            </div>
        <div  className="datatableGrid">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[5, 10]}
            density="compact"
            disableRowSelectionOnClick
            loading={loadingState}
          />
        </div>
      </CCardBody>
      </CCard>
      <OffCanvas
        Open={visible}
        title={editValue ? 'Edit Industry' : 'Add Industry'}
        handleCloseClick={handleCloseClick}
        component={
          <Add
            apiCall={fetchData}
            handleClose={handleCloseClick}
            editValue={editValue}
            clearFormData={clearFormDataFlag}
          />
        }
      />
      <CModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <CModalHeader>
          <h3>Delete</h3>
        </CModalHeader>
        <CModalBody>Are you sure want to delete {editValue?.title}...?</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
            Yes
          </CButton>
        </CModalFooter>
      </CModal>
      </div>
    </>
  );
};

export default IndustryManagement;

// import {
//   CTable,
//   CTableBody,
//   CTableDataCell,
//   CTableHeaderCell,
//   CTableHead,
//   CTableRow,
//   CButton,
//   CModal,
//   CModalBody,
//   CCardBody,
//   CModalFooter,
//   CCard,
//   CImage,
//   CModalHeader,
//   CTooltip,
// } from '@coreui/react'
// import React, { useEffect,useMemo, useState } from 'react'
// import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api'
// import { deleteIcon, edit } from 'src/components/Images/Images'
// import OffCanvas from '../../../components/share/canvas'
// import Add from './Add'
// import { ToastContainer, toast } from 'react-toastify'

// const IndustryManagement = () => {
//   const [editValue, setEditValue] = useState()
//   const [visible, setVisible] = useState(false)
//   const [isModalVisible, setIsModalVisible] = useState(false)
//   const [industry, setIndustry] = useState([])
//   const [clearFormDataFlag, setClearFormDataFlag] = useState(false)
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })
//   const [currentPage, setCurrentPage] = useState(1)
//   const [itemsPerPage] = useState(15) // Number of items per page
//   const [searchQuery, setSearchQuery] = useState('')
//   const accessToken = localStorage.getItem('token')
//   const headers = {
//     Authorization: `Bearer ${accessToken}`,
//   }

//   const filteredServices = useMemo(() => {
//     return industry.filter(
//       (service) =>
//         service.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         service.createdAt.toLowerCase().includes(searchQuery.toLowerCase()),
//     )
//   }, [industry, searchQuery])

//   const fetchData = async () => {
//     const apiUrl = '/api/industry'
//     try {
//       const res = await GetApiwithHeader(apiUrl, headers)
//       setIndustry(res?.data?.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     fetchData()
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   const handleDeleteClick = async (id) => {
//     const apiUrl = `/api/industry/delete/${id}`
//     try {
//       const response = await DeleteApiwithHeader(apiUrl, headers)
//       if (response.message === 'Success') {
//         setIsModalVisible(false)
//         const updatedIndustry = industry.filter((entry) => entry.id !== id)
//         setIndustry(updatedIndustry)
//         toast.success('Deleted Successfully', { autoClose: 1000 })
//       } else {
//         console.error('Unexpected response format:', response)
//       }
//     } catch (error) {
//       console.error('Delete API Request Error:', error)
//     }
//   }

//   const handleAddClick = () => {
//     setEditValue()
//     setVisible(true)
//   }
//   const handleCloseClick = () => {
//     setVisible(false)
//     setClearFormDataFlag((prev) => !prev)
//     setEditValue()
//   }
//   const handleEditClick = (i) => {
//     setEditValue(i)
//     setVisible(true)
//   }

//   const handleDelete = (i) => {
//     setEditValue(i)
//     setIsModalVisible(true)
//   }

//   const handleSort = (key) => {
//     let direction = 'ascending'
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending'
//     }
//     setSortConfig({ key, direction })
//   }

//   const sortedItems = () => {
//     const sortableItems = [...industry]
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1
//         }
//         return 0
//       })
//     }
//     return sortableItems
//   }

//   const handlePrevPage = (e) => {
//     setCurrentPage(currentPage - 1)
//   }

//   // Function to handle next page
//   const handleNextPage = (e) => {
//     setCurrentPage(currentPage + 1)
//   }

//   const totalPages = Math.ceil(industry?.length / itemsPerPage)
//   // Function to get the current page's data
//   const getCurrentPageData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage
//     const endIndex = startIndex + itemsPerPage
//     return filteredServices.slice(startIndex, endIndex)
//   }

//   const handlePageChange = (page) => {
//     setCurrentPage(page)
//   }

//   return (
//     <>
//       <div className="cardBody">
//         <ToastContainer position="top-right" />
        // <CCard>
        //   <CCardBody className="m-2">
        //     <div className="cardHeader">
        //       <h3>Industry</h3>
        //       <div className="mr-1">
        //         <input
        //           type="text"
        //           placeholder="Search..."
        //           value={searchQuery}
        //           onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
        //           // Adding Bootstrap class for styling
        //         />
        //       <CButton style={{ marginLeft: '50px' }} onClick={() => handleAddClick()}>
        //         Add Industry
        //       </CButton>
        //       </div>
        //     </div>
//             <div className="cardTable">
//               <CTable>
//                 <CTableHead>
//                   <CTableRow>
//                     <CTableHeaderCell scope="col">S.No</CTableHeaderCell>
//                     <CTableHeaderCell scope="col" onClick={() => handleSort('title')}>
//                       Title
//                     </CTableHeaderCell>
//                     <CTableHeaderCell scope="col" onClick={() => handleSort('createdAt')}>
//                       Created At
//                     </CTableHeaderCell>
//                     <CTableHeaderCell scope="col">Action</CTableHeaderCell>
//                   </CTableRow>
//                 </CTableHead>
//                 <CTableBody>
//                   {getCurrentPageData()?.map((i, index) => (
//                     <CTableRow key={index}>
//                       <CTableDataCell>
//                         {' '}
//                         {(currentPage - 1) * itemsPerPage + index + 1}
//                       </CTableDataCell>
//                       <CTableDataCell>{i.title}</CTableDataCell>
//                       <CTableDataCell>
//                         {new Date(i.createdAt).toLocaleDateString('en-US')}
//                       </CTableDataCell>
//                       <CTableDataCell>
//                         <CTooltip content="Edit">
//                           <button className="editIcon">
//                             <CImage src={edit} onClick={() => handleEditClick(i)} />
//                           </button>
//                         </CTooltip>
//                         <CTooltip content="Delete">
//                           <button className="deleteIcon">
//                             <CImage src={deleteIcon} onClick={() => handleDelete(i)} />
//                           </button>
//                         </CTooltip>
//                       </CTableDataCell>
//                     </CTableRow>
//                   ))}
//                 </CTableBody>
//               </CTable>
//             </div>
//             <nav>
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button className="page-link" onClick={handlePrevPage}>
//                     Previous
//                   </button>
//                 </li>
//                 {Array.from({ length: totalPages }, (_, index) => (
//                   <li
//                     key={index}
//                     className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
//                   >
//                     <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                       {index + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                   <button className="page-link" onClick={handleNextPage}>
//                     Next
//                   </button>
//                 </li>
//               </ul>
//             </nav>
//           </CCardBody>
//         </CCard>
//         <OffCanvas
//           Open={visible}
//           title={editValue ? 'Edit Industry' : 'Add Industry'}
//           handleCloseClick={handleCloseClick}
//           component={
//             <Add
//               apiCall={fetchData}
//               handleClose={handleCloseClick}
//               editValue={editValue}
//               clearFormData={clearFormDataFlag}
//             />
//           }
//         />
//         <CModal
//           visible={isModalVisible}
//           onClose={() => setIsModalVisible(false)}
//           aria-labelledby="ScrollingLongContentExampleLabel"
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             overflow: 'auto',
//           }}
//         >
//           <CModalHeader>
//             <h3>Delete</h3>
//           </CModalHeader>
//           <CModalBody>Are you sure want to delete {editValue?.title}...?</CModalBody>
//           <CModalFooter>
//             <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
//               No
//             </CButton>
//             <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
//               Yes
//             </CButton>
//           </CModalFooter>
//         </CModal>
//       </div>
//     </>
//   )
// }

// export default IndustryManagement


