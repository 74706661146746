import React, { useEffect, useMemo, useState } from 'react';
import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api';
import { deleteIcon, edit } from 'src/components/Images/Images';
import Add from './AddCode';
import OffCanvas from '../../../components/share/canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license';
import { CImage, CModal, CModalHeader, CModalBody, CModalFooter, CButton, CCard, CCardBody, CTooltip } from '@coreui/react';

const CodeTemplate = () => {
  LicenseInfo.setLicenseKey('248a71a5e79c492f4b13769843fcf6dcTz04OTIzMCxFPTE3NDU3NTY1ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editValue, setEditValue] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clearFormDataFlag, setClearFormDataFlag] = useState(false);
  const [sortConfig, setSortConfig] = useState({ field: 'industry', direction: 'asc' });  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(500);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingState, setLoadingState] = useState(true); // Set loading state based on your implementation

  const accessToken = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const apiUrl = 'api/CodingTemplate';
    try {
      const res = await GetApiwithHeader(apiUrl, headers);
      setLoadingState(false)
      console.log('res?.data?.data ', res?.data?.data )
      setTableData(res?.data?.data || []);
    } catch (error) {
      setLoadingState(false)
      console.log('Fetch Data Error:', error);
    }
  };

  const handleAddClick = () => {
    setEditValue(null);
    setVisible(true);
  };

  const handleCloseClick = () => {
    setVisible(false);
    setClearFormDataFlag((prev) => !prev);
    setEditValue(null);
  };

  const handleEditClick = (row) => {
    setEditValue(row);
    setVisible(true);
  };

  const handleDelete = (row) => {
    setEditValue(row);
    setIsModalVisible(true);
  };

  const handleDeleteClick = async () => {
    const apiUrl = `api/CodingTemplate/delete/${editValue.id}`;
    try {
      const response = await DeleteApiwithHeader(apiUrl, headers);
      if (response.message === 'Success') {
        const updatedTableData = tableData?.filter((entry) => entry.id !== editValue.id);
        setTableData(updatedTableData);
        setIsModalVisible(false);
        toast.success('Deleted Successfully', { autoClose: 1000 });
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Delete API Request Error:', error);
    }
  };

  const handleSort = (field, direction) => {
    setSortConfig({ field, direction });
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const filteredServices = useMemo(() => {
    return tableData?.filter((service) =>
      service.industry?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
  }, [tableData, searchQuery]);
  const totalPages = Math.ceil(filteredServices?.length / itemsPerPage);

  const columns = [
    // { field: 'id', headerName: 'S.No', width: 100 },
    {
      field: " ",
      headerName: "",
      width: 5,
      resizable: false,
      sortable: false,
      // align: "center",
      // headerAlign: "center",
      headerClassName: 'dataGridHeader'
    },
    { field: 'industry', headerName: 'Industry', width: 700, headerClassName: 'dataGridHeader' },
    {
      field: 'actions',
      headerName: 'Action',
      width: 200,
      headerClassName: 'dataGridHeader',
      renderCell: (params) => (
        <>
          <div className='cardTable rowIcon' >
          <CTooltip content="Edit">
            <button className="icon-editIcon" onClick={() => handleEditClick(params.row)} >
              {/* <CImage src={edit} /> */}
            </button>
          </CTooltip>
          <CTooltip content="Delete">
            <button className="icon-deleteIcon"  onClick={() => handleDelete(params.row)}>
              {/* <CImage src={deleteIcon} /> */}
            </button>
          </CTooltip>
          </div>
        </>
      ),
    },
  ];
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredServices.slice(startIndex, endIndex);
  };
  console.log('getCurrentPageData', getCurrentPageData())
  const rows = getCurrentPageData();

  return (
    <div className="cardBody cardOffCan">
      <ToastContainer position="top-right" />
      <CCard>
        <CCardBody>
          <div className="cardHeader">
            <h3>Industry Code Mapping</h3>
            <div className="mr-1">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
              // Adding Bootstrap class for styling
              />
              <CButton onClick={handleAddClick}>Add Industry Code</CButton>
            </div>
          </div>
          <div className="datatableGrid">
            <DataGridPro
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 }
                }
              }}
              // onRowClick={(row) => handleView(row.row)}
              density="compact"
              disableRowSelectionOnClick
              loading={loadingState}
            />
          </div>
        </CCardBody>
      </CCard>
      <OffCanvas
            Open={visible}
            canvasWidth={'700px'}
            title={editValue ? 'Edit Industry Code' : 'Add Industry Code'}
            handleCloseClick={handleCloseClick}
            component={
              <Add
                apiCall={fetchData}
                handleClose={handleCloseClick}
                editValue={editValue}
                clearFormData={clearFormDataFlag}
              />
            }
          />
           <CModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            aria-labelledby="ScrollingLongContentExampleLabel"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'auto',
            }}
          >
            <CModalHeader>
              <h3>Delete</h3>
            </CModalHeader>
            <CModalBody>Are you sure you want to delete {editValue?.industry}...?</CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
                No
              </CButton>
              <CButton color="primary" onClick={handleDeleteClick}>
                Yes
              </CButton>
            </CModalFooter>
          </CModal>
    </div>
  );
};

export default CodeTemplate;


// import {
//   CTable,
//   CTableBody,
//   CTableDataCell,
//   CTableHeaderCell,
//   CTableHead,
//   CTableRow,
//   CButton,
//   CImage,
//   CCardBody,
//   CCard,
//   CModalBody,
//   CModalHeader,
//   CModalFooter,
//   CModal,
//   CTooltip,
// } from '@coreui/react'
// import React, { useEffect, useMemo,useState } from 'react'
// import { GetApiwithHeader, DeleteApiwithHeader } from 'src/Api/Api'
// import { deleteIcon, edit } from 'src/components/Images/Images'
// import Add from './AddCode'
// import OffCanvas from '../../../components/share/canvas'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// const CodeTemplate = () => {
//   const [visible, setVisible] = useState(false)
//   const [tableData, setTableData] = useState([])
//   const [editValue, setEditValue] = useState()
//   const [isModalVisible, setIsModalVisible] = useState(false)
//   const [clearFormDataFlag, setClearFormDataFlag] = useState(false)
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })
//   const [currentPage, setCurrentPage] = useState(1)
//   const [itemsPerPage] = useState(15)
//   const [searchQuery, setSearchQuery] = useState('')

//   const accessToken = localStorage.getItem('token')
//   const headers = {
//     Authorization: `Bearer ${accessToken}`,
//   }

//   const filteredServices = useMemo(() => {
//     return tableData.filter(
//       (service) =>
//         service.industry?.toLowerCase().includes(searchQuery.toLowerCase()),
//     )
//   }, [tableData, searchQuery])

//   const fetchData = async () => {
//     const apiUrl = 'api/CodingTemplate'
//     try {
//       const res = await GetApiwithHeader(apiUrl, headers)
//       setTableData(res?.data?.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   useEffect(() => {
//     fetchData()
//   }, [])

//   const handleAddClick = () => {
//     setEditValue()
//     setVisible(true)
//   }

//   const handleCloseClick = () => {
//     setVisible(false)
//     setClearFormDataFlag((prev) => !prev)
//     setEditValue()
//   }

//   const handleEditClick = (i) => {
//     setEditValue(i)
//     setVisible(true)
//   }

//   const handleDelete = (i) => {
//     setEditValue(i)
//     setIsModalVisible(true)
//   }

//   const handleDeleteClick = async (id) => {
//     const apiUrl = `api/CodingTemplate/delete/${id}`
//     try {
//       const response = await DeleteApiwithHeader(apiUrl, headers)
//       if (response.message === 'Success') {
//         const updatedService = tableData.filter((entry) => entry.id !== id)
//         setTableData(updatedService)
//         setIsModalVisible(false)
//         toast.success('Deleted Successfully', { autoClose: 1000 })
//       } else {
//         console.error('Unexpected response format:', response)
//       }
//     } catch (error) {
//       console.error('Delete API Request Error:', error)
//     }
//   }

//   const handleSort = (key) => {
//     let direction = 'ascending'
//     if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending'
//     }
//     setSortConfig({ key, direction })
//   }

//   const sortedItems = () => {
//     const sortableItems = [...tableData]
//     if (sortConfig !== null) {
//       sortableItems.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1
//         }
//         return 0
//       })
//     }
//     return sortableItems
//   }

//   const handlePrevPage = (e) => {
//     setCurrentPage(currentPage - 1)
//   }

//   // Function to handle next page
//   const handleNextPage = (e) => {
//     setCurrentPage(currentPage + 1)
//   }

//   const totalPages = Math.ceil(tableData?.length / itemsPerPage)
//   // Function to get the current page's data
//   const getCurrentPageData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage
//     const endIndex = startIndex + itemsPerPage
//     return filteredServices.slice(startIndex, endIndex) 
//   }

//   const handlePageChange = (page) => {
//     setCurrentPage(page)
//   }

//   return (
//     <div className="cardBody">
//       <ToastContainer position="top-right" />
// <CCard>
//   <CCardBody>
//     <div className="cardHeader">
//       <h3>Industry Code Mapping</h3>
//       <div className="mr-1">
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)} // Step 2: Add input field for search query
//             // Adding Bootstrap class for styling
//           />
//       <CButton onClick={handleAddClick}>Add Industry Code</CButton>
//     </div>
//     </div>
//           <div className="cardTable">
//           <CTable>
//             <CTableHead>
//               <CTableRow>
//                 <CTableHeaderCell scope="col">
//                   S.No
//                 </CTableHeaderCell>
//                 <CTableHeaderCell
//                   scope="col"
//                   onClick={() => handleSort('industry')}
//                 >
//                   Industry
//                 </CTableHeaderCell>
//                 <CTableHeaderCell style={{ backgroundColor: '#EAF0F2' }} scope="col">
//                   Action
//                 </CTableHeaderCell>
//               </CTableRow>
//             </CTableHead>
//             <CTableBody>
//               {getCurrentPageData()?.map((i, index) => (
//                 <CTableRow key={index}>
//                   <CTableDataCell>
//                     {(currentPage - 1) * itemsPerPage + index + 1}
//                   </CTableDataCell>
//                   <CTableDataCell>{i.industry}</CTableDataCell>
//                   <CTableDataCell>
//                     <CTooltip content="Edit">
//                       <button className="editIcon">
//                         <CImage
//                           src={edit}
//                           onClick={() => handleEditClick(i)}
//                         />
//                       </button>
//                     </CTooltip>
//                     {/* <CTooltip content="Delete">
//                       <button className="deleteIcon">
//                         <CImage
//                           src={deleteIcon}
//                           onClick={() => handleDelete(i)}
//                         />
//                       </button>
//                     </CTooltip> */}
//                   </CTableDataCell>
//                 </CTableRow>
//               ))}
//             </CTableBody>
//           </CTable>
//           </div>
//           <nav>
//             <ul className="pagination">
//               <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                 <button className="page-link" onClick={handlePrevPage}>
//                   Previous
//                 </button>
//               </li>
//               {Array.from({ length: totalPages }, (_, index) => (
//                 <li
//                   key={index}
//                   className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
//                 >
//                   <button className="page-link" onClick={() => handlePageChange(index + 1)}>
//                     {index + 1}
//                   </button>
//                 </li>
//               ))}
//               <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                 <button className="page-link" onClick={handleNextPage}>
//                   Next
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </CCardBody>
//       </CCard>
//       <OffCanvas
//         Open={visible}
//         canvasWidth={'700px'}
//         title={editValue ? 'Edit Industry Code' : 'Add Industry Code'}
//         handleCloseClick={handleCloseClick}
//         component={
//           <Add
//             apiCall={fetchData}
//             handleClose={handleCloseClick}
//             editValue={editValue}
//             clearFormData={clearFormDataFlag}
//           />
//         }
//       />
//       <CModal
//         visible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//         aria-labelledby="ScrollingLongContentExampleLabel"
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           overflow: 'auto',
//         }}
//       >
//         <CModalHeader>
//           <h3>Delete</h3>
//         </CModalHeader>
//         <CModalBody>Are you sure want to delete {editValue?.industry}...?</CModalBody>
//         <CModalFooter>
//           <CButton color="secondary" onClick={() => setIsModalVisible(false)}>
//             No
//           </CButton>
//           <CButton color="primary" onClick={() => handleDeleteClick(editValue?.id)}>
//             Yes
//           </CButton>
//         </CModalFooter>
//       </CModal>
//     </div>
//   )
// }

// export default CodeTemplate

